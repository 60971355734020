import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Digital Banking and Transaction: Pioneering Financial Evolution",
      description:
        "In an era of digital transformation, 'Digital Banking and Payments' have emerged as critical elements in reshaping the financial landscape. Delve into the significant impact of digitalization on banking services and payment systems. We will explore the rapid evolution of online banking platforms, mobile payment solutions, and the growing influence of cryptocurrencies.",
      image: <S2 />,
    },
    {
      title: "Collaboration for Success:  Fintech-Bank Partnerships Driving Innovation",
      description:
        " Illuminates the transformative potential of collaborative endeavours between fintech and banking institutions. In an era defined by rapid technological advancement and evolving customer expectations, this session explores how strategic partnerships can catalyze innovation and drive meaningful change within the financial services industry.",
      image: <S3 />,
    },
    {
      title: "Cyber Resilience: Safeguarding Digital Trust in an Era of Threats",
      description:
        "In an interconnected world driven by digital innovation, the banking sector faces unprecedented cybersecurity challenges. delve into the evolving landscape of cybersecurity and explore strategies for banks to fortify their defenses, mitigate risks, and safeguard digital trust.",
      image: <S8 />,
    },
    {
      title: "Data Frameworks and Digital Compliance Leadership",
      description:
        "Data and regulators often play a crucial role by creating an enabling regulatory environment that encourages financial institutions to reach underserved populations. Explores the vital role of regulatory adherence and compliance leadership in the digital banking landscape. ",
      image: <S1 />,
    },
  ];
  const content1 = [
    {
      title: "Insights",
      description:
        "The future is an unfolding tapestry woven from the pressing needs and collective aspirations of our time. Nowhere is this more apparent than in the ever-evolving realm of digital banking and financial services. As we stand on the cusp of innovation, our visionary leaders, armed with an acute understanding of public desires and expectations, are poised to chart the course ahead. At the upcoming conference, anticipate a vibrant array of dynamic panels, enlightening fireside chats, thought-provoking discussions, and captivating demonstrations. Each session is carefully curated to explore a diverse range of topics that resonate with the current trends, ensuring an enriching experience for all attendees.",
    },
    {
      title: "Networking",
      description:
        "To foster innovation, ideas need to be given room to breathe, openly discussed, carefully refined through rigorous debates, and ultimately embraced by a wider audience. Finnovex stands out as an unparalleled platform facilitating this crucial process. By connecting with influential thought leaders, seasoned industry veterans, pioneering thinkers, and creative innovators, participants have the unique opportunity to engage in meaningful discussions and exchange insights. Through these interactions, attendees can glean the latest and most effective strategies propelling the profound transformation within the financial industry and beyond.",
    },
    {
      title: "Spotlight",
      description:
        "Connect with decision-makers in the digital banking industry through Finnovex, the premier platform for industry leadersEmbrace your organization's visibility across multiple channels including print, online, email, and social media, reaching a diverse and extensive audience. Prepare for an immersive experience featuring personalized networking opportunities, essential sessions, exclusive sponsor demonstrations, enlightening talks, captivating presentations, and engaging fireside chats with top innovators in the field.",
    },
    {
      title: "Roundtables",
      description:
        "Enhance your networking at our exclusive roundtable breakout sessions. These intimate gatherings, limited to about 10 participants, spark dynamic idea exchanges. Led by experienced moderators, these sessions explore compelling topics from multiple perspectives, creating a seamless fusion of networking and knowledge sharing, enriching your conference experience.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={12}>
                {" "}
                <span className="text-white"></span>
                <h1
                  className=" text-700 pb-0 text-white mt-0 text-uppercase"
                  style={{ fontSize: size ? "2rem" : "1.5rem" }}
                >
                  <b>Empowering the Financial Renaissance:</b>
                </h1>
                <h1
                  className=" text-400 pb-0 text-white mt-0 text-uppercase"
                  style={{ fontSize: size ? "2rem" : "1.5rem" }}
                >
                  Leading the Digital Revolution, Embracing Inclusivity, Pioneering Sustainable
                  Finance, and Driving Economic Digitalization to Victory.
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  In the heart of the digital revolution in Southern Africa, the financial industry
                  stands at a crossroads, poised for profound transformation. Southern Africa is
                  embracing the "Financial Symphony" by showcasing the power of collaboration
                  between traditional financial institutions, fintech pioneers, government bodies,
                  and international organisations. Witness how these partnerships accelerate
                  progress toward financial inclusion, sustainability goals and economic
                  digitalization, with the transformative power of blockchain and cryptocurrencies.
                  The emergence of cutting-edge technologies, the relentless pursuit of financial
                  inclusion, and the imperative of sustainable finance are driving this change. The
                  highly anticipated Finnovex South Africa 2024 is a conference that will not only
                  acknowledge these shifts but also pave the way for financial institutions to
                  navigate and thrive in the digital era and beyond.
                  <br />
                  <br />
                  <b style={{ fontSize: 25, color: "#770c2c" }}>
                    Transforming the Digital Renaissance Together{" "}
                  </b>
                  <br />
                  <br />
                  The "Digital Renaissance" signifies a rebirth—a moment when finance is being
                  reborn in the digital realm. It's not just about adopting technology; it's about
                  embracing a new way of thinking and operating. The Digital Renaissance is
                  characterised by artificial intelligence, blockchain, open banking, and a
                  customer-centric approach that transcends traditional boundaries. The 26th Edition
                  of Finnovex Southern Africa is back to recognize and embrace these transformative
                  shifts, while also serving as a guiding light for financial institutions seeking
                  to not only adapt but flourish in this dynamic digital era. Join us as we chart a
                  course towards a future where finance is reshaped and redefined to meet the needs
                  of the modern world.
                  <br />
                  <br />
                  Mark your calendars, as Finnovex Southern Africa is back as 26th Edition sets the
                  stage for a transformative innovation of the financial landscape. Join us in
                  Johannesburg for Finnovex South Africa 2024 as we embark on this transformative
                  journey with 200+ quality attendees, by championing inclusivity, spearheading
                  sustainable finance, and embracing innovation, the event seeks to pave the way for
                  a resilient and digitally empowered banking ecosystem in South Africa and beyond.
                </h5>
                <br />
                <br />
              </Col>
              <Col lg={6} className="mt-5 pr-0">
                {content.map((s, index) => (
                  <ScrollAnimation
                    animateIn={"fadeInUp"}
                    animateOnce={true}
                    duration={0.5 + index / 8}
                  >
                    <div className="py-4 px-2 stat-div mb-2" style={{ minHeight: 200 }}>
                      <div className="container-fluid row pr-0">
                        <Col className="align-self-center" xs={3}>
                          {s.image}
                        </Col>
                        <Col>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "18px" : "14px",
                            }}
                            className="text-700 mt-0"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "16px" : "12px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </Col>
                      </div>
                    </div>
                  </ScrollAnimation>
                ))}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "36px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  Revolutionising and Forging Financial Renaissance: Trailblazing Towards Economic
                  Digitalization
                </h3>
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 my-5">
                  <Col lg={6} className="align-self-center">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                      }}
                      className="text-400 mt-0"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-0 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6} className="align-self-center">
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/images/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "JWB_0449.jpg",
  "JWB_0525.jpg",
  "JWB_0632.jpg",
  "JWB_0644.jpg",
  "JWB_0691.jpg",
  "JWB_0700.jpg",
  "JWB_0743.jpg",
  "JWB_0763.jpg",
  "JWB_0773.jpg",
  "JWB_0794.jpg",
  "JWB_0801.jpg",
  "JWB_0858.jpg",
  "JWB_0871.jpg",
  "JWB_0888.jpg",
  "JWB_0907.jpg",
  "JWB_0941.jpg",
  "JWB_1046.jpg",
  "JWB_1065.jpg",
  "JWB_1252.jpg",
];
