import React, { useEffect } from "react";
import anime from "animejs";

function AnimeBackground() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  function animation() {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.9, .1, .2, .3)",
      duration: 1000,
      delay: function (el, i) {
        return i * 150;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        marginTop: "3rem",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: -100,
        marginLeft: "auto",
        // marginRight: "auto",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1308 692"
        style={{
          fill: "none",
          stroke: "#770c2ccc",
          strokeWidth: 1,
          strokeMiterlimit: 10,
        }}
      >
        <path
          id="SVGID_3_"
          strokeWidth={5}
          d="M1188.6,293.3c-0.4,0.3-0.7,0.6-1.1,0.9c-2.5,2-5.5,3.8-8.6,3.3c-1.5-0.3-2.8-1-4.1-1.8
	c-1-0.6-1.9-1.1-2.8-1.7c-1.1-0.6-2.2-1.3-3.2-1.9c-0.1-0.1-0.2-0.1-0.3-0.2c-1-0.6-2.1-1.2-3.3-1.2c-2.4,0.1-3.7,3.1-3.4,5.5
	c0.1,0.8,0.3,1.6,0.6,2.4c0.4,1.2,1,2.3,1.4,3.5c0.1,0.4,0.2,0.7,0.3,1.1c0.6,2.4,0,5.4-2.3,6.3c-1.9,0.7-3.7-0.4-5.6-1.6
	c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.5-0.8-2.3-1c-2-0.5-4.1,0.2-6.1,0.6c-6.5,1.3-14.4-1.2-16.9-7.3v0c-0.1-0.2-0.1-0.4-0.2-0.6
	c-0.2-0.5-0.3-1.1-0.4-1.6c-0.3-1.4-0.5-2.8-0.9-4.2c-1.3-4-4.8-8.1-2.8-11.8c1.1-2,3.4-2.9,5.3-4.3c4.4-3.3,5.5-10.2,2.4-14.8
	c1.1-0.6,2.7-0.4,3.6,0.5c0.6-0.8,1.1-1.6,1.7-2.3c0.3-0.3,0.5-0.7,0.8-1c0.5-0.2,1-0.4,1.5-0.6c0,0,0,0,0,0
	c2.1-0.6,4.5-0.5,6.5,0.5c5.3,2.4,9.1,9.8,14.8,9.3c0.3-1.7,0.5-3.4,0.7-5.1c0.2-1.7,0.3-3.3,0.3-5v0c0.2-4.5,0.1-9,0-13.5l-0.1-5.8
	c0-1.5-0.1-3-0.1-4.6c-0.1-2.1-0.1-4.2-0.3-6.3c-0.1-1.6-0.2-3.2-0.4-4.7c-0.2-1.6-0.4-3.1-0.6-4.6c-0.4-2.7-1-5.4-1.8-8
	c-0.3-1.1-0.7-2.1-1.1-3.1c-2.1,1-4.6-0.1-6.1-2c-0.6-0.7-1-1.5-1.3-2.4c-0.2-0.8-0.3-1.5-0.3-2.3c0-1.8,0.4-3.7,0.9-5.5
	c0.2-0.6,0.4-1.2,0.6-1.8c-4.3-0.4-5.4-5.4-5.7-10c-0.1-0.9-0.1-1.7-0.1-2.5c0-0.2,0-0.4,0-0.7c-0.1-3.1-0.6-6.1-1.3-9.1
	c-0.4-1.5-0.9-3-1.4-4.5c-0.5-1.3-1.1-2.6-1.7-3.8c-0.8-1.5-1.7-3-3-4c-1.6-1.1-3.3-1.4-5.2-1.1c-1.5,0.2-3.1,0.7-4.6,1.2
	c-2.3,0.8-4.6,1.7-6.9,2.2c-0.5,0.1-1.1,0.2-1.6,0.2c-1.1,0.1-2.1,0-3.2-0.1c-2.4-0.3-4.7-1.2-7-2.2c-0.9-0.4-1.8-0.8-2.7-1.2
	c-2.7-1.2-5.4-2.6-8.1-3.5c-5.5-1.8-11.3-2-17.1-1.8c-1.2,0-2.4,0.1-3.5,0.1c-0.4,0-0.8,0-1.2,0.1c-0.6,0-1.2,0.1-1.8,0.3
	c-0.8,0.2-1.6,0.6-2.1,1.3c-1.1,1.2-1,3-1.5,4.6c-0.9,2.7-2.8,4.3-5.4,5.3h0c-6.5,2.5-16.6,1.4-20.8,7.4c-1.2,1.7-1.7,3.8-2.6,5.7
	c-3.3,6.9-11.4,9.7-18.6,12.4c-0.3,0.1-0.7,0.2-1,0.4c-1.5,0.6-3,1.2-4.5,2c-5.3,2.7-9.9,6.5-10.9,12.1c-0.3,1.7-0.2,3.6-0.3,5.3
	c0,1.3-0.1,2.6-0.4,3.8c0,0.1,0,0.1-0.1,0.2v0c-0.6,2.2-2,4-3.4,5.7c-3,3.5-6.9,6.5-11.1,8.2c-1.3,0.5-2.6,0.9-4,1.2
	c-3.2,0.6-6.4,0.5-9.5-0.7c-3-1.2-6.9-3.2-9.1-0.7c-0.7,0.8-1,2-1.1,3c-0.1,0.9-0.2,1.8-0.2,2.8c-0.3,8.1,2.1,17.1-3.3,22.9
	c-0.6,0.7-1.4,1.3-2.2,1.8c-4.1,2.8-9.9,3-15,3.1c-2.5,0-5,0-7.5-0.2c-0.9-0.1-1.8-0.2-2.8-0.3c-1.5-0.2-3-0.5-4.4-1
	c-0.9-0.3-1.7-0.6-2.5-1c-1.2-0.5-2.3-1.1-3.4-1.8c-2.3-1.4-4.4-3-6.5-4.7c-0.9-0.8-1.8-1.5-2.7-2.3c-3.1-2.7-6.2-5.4-9.5-7.6
	c-1.1-0.8-2.3-1.4-3.5-2c-1.2-0.6-2.5-1.1-3.9-1.5c-0.5-0.2-1.1-0.3-1.6-0.4c-0.6-0.1-1.2-0.2-1.8-0.2c-0.6,0-1.2,0.1-1.7,0.3
	c0,0-0.1,0-0.1,0.1c-1.5,0.7-2.3,2.3-2.8,3.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.6-1.2,3.2-1.7,4.7c-0.4,1.1-0.8,2.3-1.3,3.4
	c-0.8,2.1-1.5,4.2-2.3,6.2v0c-0.1,0.3-0.2,0.5-0.3,0.8c-0.9,2.4-1.8,4.9-2.9,7.3v0.1c-1,2.3-2.2,4.4-3.6,6.4
	c-0.8,1.2-1.7,2.3-2.7,3.3c-1.9,2-3.9,3.7-6.2,5.3c-1.6,1.1-3.2,2.2-4.9,3.2c-0.4,0.2-0.7,0.4-1.1,0.6c-1.1,0.6-2.1,1.2-3.2,1.8
	c-1.3,0.7-2.6,1.3-3.9,1.9c-1.1,0.5-2.3,1-3.4,1.5c-1.7,0.7-3.5,1.4-5.3,2c-1.3,0.4-2.5,0.9-3.8,1.2c-1.7,0.5-3.4,1-5.1,1.4
	c-4.1,1-8.5,1.7-12.5,0.4c-0.2-0.1-0.4-0.1-0.6-0.2c-1-0.4-2-1-2.8-1.6c-2.3-1.9-3.9-4.7-3.5-7.6c0.5-3.6,3.6-6.1,5.8-8.9
	c4.6-5.7,5.6-13.7,3.5-20.6c-0.9-3.1-2.7-7.4-5.3-9.6c-3-2.5-6.8-1.7-10.4-3c-1.3-0.5-2.5-1.1-3.5-2c-1.6-1.3-2.9-3-3.4-5.1
	c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.6-0.2-1.2-0.5-1.7c-0.8-1.7-2.8-2.5-4.7-3c-0.5-0.2-1.1-0.3-1.6-0.1c-0.5,0.2-0.7,0.8-0.8,1.3
	c-0.7,2.7-0.4,5.5,0.2,8.2c0.2,1.1,0.5,2.2,0.8,3.3c1.2,4.3,2.5,8.7,1,12.8c-0.8,2.2-2.3,4.1-3.1,6.3c-0.9,2.6-0.8,5.4-0.7,8.2
	c0.3,6.2,0.5,12.3,0.8,18.5c0,0.5,0,1,0.1,1.6v0c0.1,2.1,0.2,4.3,0.3,6.4c0.2,2.8,0.4,5.7,0.7,8.5c0.4,3.4,0.8,6.8,1.3,10.2
	c0.2,1.6,0.4,3.2,0.6,4.8c0.5,4.7,0.9,9.3,0.6,14c-0.2,2.8-1.1,6.1-3.7,6.8c-1.9,0.5-4.1-0.6-5.9,0.3c-2.2,1.1-2.1,4.4-3.7,6.2
	c-1.4,1.6-3.9,1.8-5.3,3.3c-1.4,1.5-1.5,3.8-1.3,5.9c0.2,2.1,0.5,4.3-0.3,6.2c-0.3,0.6-0.7,1.1-1.3,1.5h0c0,0,0,0,0,0
	c-0.9,0.6-1.9,1-2.8,0.7h0c-0.4-0.1-0.7-0.2-1-0.5c-0.1-2.4-0.3-4.9-1.4-6.9c-0.2-0.4-0.5-0.9-0.8-1.2c-0.6-0.8-1.4-1.4-2.3-1.9
	c-3.2-1.7-7.5-1.3-11.3-0.6c-4.6,0.8-9.2,1.6-13.9,2.3c-0.6,0.1-1.1,0.2-1.7,0.2c-1,0.1-2.1,0.1-3-0.3c-0.2-0.1-0.3-0.1-0.5-0.2
	c-1.3-0.8-2.1-2.3-3.3-3.1c-1.5-1-3.5-1-5.3-1.1c-1.6-0.1-3.2-0.3-4.1-1.3c-0.2-0.2-0.4-0.5-0.5-0.8c-0.5-0.9-0.4-2-0.4-3.1
	c0-1.1-0.2-2.3-0.6-3.4c-0.3-0.9-0.6-1.8-1-2.7c-1-2.3-2.4-4.4-3.8-6.5c-1-1.6-2.2-3.2-4-3.8c-2.6-0.8-5.3,1.1-7,3.2
	c-1.7,2.2-3,4.7-5.4,6.2c-1.2,0.7-2.6,1.1-3.7,1.9c-2.3,1.7-2.7,5-2,7.7c0.3,1.1,0.8,2.1,1.4,3.1c0.6,1,1.2,1.9,1.9,2.9
	c0.3,0.4,0.6,0.9,1,1.3c0.6,0.9,1.2,1.7,1.8,2.6c0.6,0.9,1.2,1.9,1.8,2.8c1.4,2.3,2.7,4.6,3.9,6.9c0.5,1,0.9,2,1.4,3
	c1,2.2,1.8,4.5,2.5,6.8c0.9,2.9,1.5,6,2.8,8.8c0.2,0.4,0.4,0.7,0.6,1.1c0,0,0,0,0,0v0c0.3,0.5,0.6,1,1,1.5c1.2,1.6,2.4,2.9,3.4,4.1
	c0.9,1.1,1.6,2.1,2.2,3.4c0.5,1.2,0.9,2.5,1.1,4.1c0,0.3,0.1,0.5,0.1,0.8c0.1,0.8,0.1,1.5,0.2,2.3v0c0.3,4.2,0.4,8.4,2.6,11.9
	c0.8,1.3,1.9,2.4,2.9,3.6c0.5,0.6,1.1,1.2,1.5,1.8c0.4,0.6,0.8,1.2,1.2,1.9c0.4,0.7,0.7,1.5,1,2.3c0.4,1.1,0.6,2.3,0.8,3.5
	c0.2,1.5,0.1,3.1-0.2,4.6c3.5-1.1,5.1,2.7,6.5,6.6c0.4,1.2,0.8,2.4,1.2,3.5c0.1,0.2,0.2,0.4,0.2,0.6l0,0c1.5,3.6,4.4,6.4,6.1,9.9
	c1.8,3.7,1.5,9.2-2.3,10.8c-1.2,0.5-2.6,0.5-3.9,1c-1.9,0.6-3.1,2.2-3.8,4c-0.4,1.2-0.6,2.6-0.5,4c0.1,1.5,0.5,3,1.2,4.3
	c0.8,1.6,1.9,2.9,3.2,4.2c0.6,0.6,1.2,1.1,1.8,1.7c1.6,1.5,3.3,2.9,4.9,4.5c1,1,1.9,2.1,2.7,3.3c0.2,0.4,0.5,0.7,0.7,1.1
	c1.5,2.6,2.2,5.6,1.2,8.4c-0.8,2.1-2.4,3.8-3.1,5.8c-1.3,3.4-0.2,7.2,0.9,10.7c1.6,4.9,6,11,10.4,8.3c-0.3-7-0.6-14.1-0.8-21.1
	c2.8,0.9,5.2,2.9,6.8,5.4c0.1,0.1,0.1,0.2,0.2,0.4c0.7,1.2,1.2,2.5,1.4,3.8c2.9,2.3,5.8,4.7,8.7,7c2.2,1.8,4.5,3.6,7,5.2
	c2.9,1.9,5.9,3.4,9.1,4.1c1.8,0.4,3.6,0.6,5.4,0.4c0.2,0,0.3,0,0.5-0.1c1.4-0.2,2.7-0.5,4-1c2.4-0.9,4.6-2.4,6.5-4.2
	c1.4-1.2,2.7-2.6,3.9-4.1c1.7-2,3.2-4.2,4.5-6.3c1.5,0.3,3,0.5,4.5,0.8c0.9,0.1,1.8,0.3,2.7,0.4c2.5,0.4,5.1,0.7,7.6,1
	c0.9,0.1,1.9,0.2,2.8,0.3c1.7,0.2,3.4,0.3,5.1,0.5c2.2,0.2,4.5,0.3,6.7,0.5c1.6,0.1,3.3,0.2,4.9,0.2c1.2,0,2.3,0.1,3.5,0.1
	c1.5,0,3.1,0,4.6,0c0-1.8,0.1-3.6,0.1-5.5c0-0.4,0-0.8,0-1.2c1-1,2.2-2,3.4-2.8c3.9-2.6,8.5-4.1,13.2-4c7.9,0,15.7,4.1,23.5,2.4
	c3.3-0.7,6.4-2.4,9.8-2.6c3-0.2,6,0.9,8.8,1.9c3.5,1.3,7,2.5,10.5,3.8c1.2,0.4,2.5,0.9,3.7,1.4c1.8,0.7,3.6,1.3,5.4,2
	c3.4,1.2,6.8,2.5,10.2,3.7c-1-4.8,1.4-10,5.7-12.4c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2-0.1c2.8-1.5,6.2-1.7,9.2-0.7
	c0.8,0.2,1.5,0.6,2.2,0.9c0.6,0.3,1.2,0.8,1.7,1.2c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1,0-0.1,0-0.2c0.4-1.2,0.6-2.5,0.6-3.8
	c0-1.4-0.2-2.8-0.7-4.2c0.9-0.1,1.8-0.2,2.8-0.2c4.1-0.3,8.1-0.7,12.1-1c11.9-1,24.2-2.2,34.6-7.9c1.6-0.9,3.2-1.9,4.7-3
	c1.4-1,2.8-2.1,4.1-3.2c1.3-1.1,2.5-2.2,3.7-3.3c0.6-0.6,1.2-1.1,1.7-1.7c1-0.9,1.9-1.9,2.8-2.8c0.9-0.9,1.7-1.7,2.6-2.6
	c1.4-1.4,2.7-2.8,4.1-4.2c0.8-0.8,1.6-1.6,2.5-2.4c2.5-2.5,5.2-4.8,7.9-6.9c2-1.5,4.2-2.9,6.4-4.1c1.4-0.7,2.8-1.4,4.2-1.9
	c2.8-1,5.7-1.8,8.6-2.7c0.9-0.3,1.9-0.6,2.8-1c1.4-0.6,2.7-1.2,3.9-2c1.2-0.8,2.3-1.7,3.2-2.8c0.2-0.2,0.4-0.4,0.5-0.7
	c1.6-2.2,2.5-4.8,3.9-7.1c0.8-1.3,1.7-2.4,2.7-3.5c1.7-1.8,3.8-3.4,5.9-4.9c1.4-1,2.8-1.9,4.2-2.9c0.7-0.5,1.5-1,2.2-1.5
	c0,0,0.1,0,0.1-0.1c3.3-2.4,6.4-5.6,8.1-9.3c0.6-1.4,1-2.8,1.2-4.3c0.1-0.9,0.1-1.9-0.1-2.8c1.9,1,3.9,1,5.1-0.9
	c0.5-0.7,0.8-1.5,1.1-2.3c0.5-1.2,0.8-2.5,1.2-3.8c0,0,0,0,0-0.1c1.5-4,4.4-7.2,6.8-10.7c1-1.4,1.8-2.9,2.5-4.5c0-0.1,0-0.1,0.1-0.2
	c0.9-2.3,1.3-4.8,2.2-7.2c0.1-0.4,0.3-0.8,0.5-1.2c0.5-1.1,1.2-2.2,1.9-3.2c2.5-3.3,6.1-5.8,9.6-8.2c1-0.7,1.9-1.3,2.9-2
	c1.8-1.2,3.5-2.4,5.3-3.6c3-2.1,6.1-4.1,9.1-6.2c2.4-1.6,4.7-3.2,6.7-5.2c0.6-0.5,1.1-1.1,1.6-1.7c2.2-2.7,3.5-5.8,4.4-9.2
	c1.6-5.9,1.8-12.5,3.2-18.6c0.2-0.9,0.4-1.7,0.7-2.5c0.6-2.1,1.5-4.2,2.4-6.3c1.2-2.9,2.6-5.7,3.5-8.6
	C1188.6,300.1,1189.2,296.8,1188.6,293.3z M1082.9,374.8c-3.2,6,1.2,13.5-8.1,13.5c-1.8,0-5.1-0.1-8.5,0.1c-1.1,0.1-2.3,0.2-3.4,0.4
	h0c-4.3,0.7-7.9,2.3-7.6,6.1c0,0.3,0,0.6,0,0.9c0,1.3-0.2,2.6-0.9,3.7c0,0-0.1,0.1-0.1,0.1c-0.9,1.4-2.7,2.3-4.3,1.7
	c0.2,2.1,0.2,4.4-0.9,6.3c0,0.1-0.1,0.1-0.1,0.2c-0.9,1.5-2.8,2.6-4.6,2.3c-0.4-0.1-0.8-0.2-1.2-0.4c-2.2-1.2-2.4-4.4-3.6-6.6
	c-0.4-0.7-0.8-1.3-1.5-1.7c-0.3-0.2-0.6-0.4-1-0.5c-1.4-0.5-3-0.3-4.5-0.5c-1.5-0.3-2.8-1-4-1.9c-0.9-0.8-1.7-1.7-2.5-2.7
	c-0.7-1-1.4-2.1-1.9-3.2c-1.1-2.1-2-4.4-3.2-6.4c-0.6-1-1.3-1.9-2-2.8c-0.3-0.3-0.6-0.6-1-0.9c-1.2-1-2.6-1.8-3.6-3
	c-1.1-1.2-1.7-2.9-1-4.3c6.6-3.3,13.5-7,17.3-13.4c1.5-2.6,2.4-5.4,3.3-8.2c0.2-0.7,0.4-1.4,0.9-1.9c1.4-1.8,4.2-1.4,6.5-1.3
	c2,0.1,4.1-0.2,6-0.7c1-0.2,2-0.5,3-0.9c1.3-0.4,2.5-0.9,3.8-1.4c1.1-0.4,2.3-0.9,3.1-1.8c1.1-1.2,1.6-3.2,3.2-3.6
	c0.4-0.1,0.8-0.1,1.2,0.1h0c0.8,0.3,1.6,1,2.3,1.6c0.9,0.9,1.8,1.8,2.7,2.7c2.1,2.2,4.3,4.3,6.4,6.4c0.7,0.7,1.4,1.4,2.2,2.2
	c1,1,2,2,3,3c1.5,1.5,3.1,3,5.2,3.3c1.2,0.2,2.6,0,3.7,0.1c1.1,0.1,2.1,0.4,2.6,1.4c0.1,0.2,0.1,0.4,0.2,0.6
	c0.2,1.3-0.5,2.7,0.2,3.8c0.3,0.5,0.8,0.8,1.2,1.2c1.6,1.7,0.4,4.8-1.8,5.8C1087.6,375.5,1085.2,374.9,1082.9,374.8z"
        />
        <path
          d="M2151,703c12.3-16.6,24.5-33.1,36.8-49.7c3.5-4.7,7-9.3,10.4-14c2-2.7,3.9-2.8,5.9-0.1c14.5,19.5,29,39,43.3,58.6
	c1.1,1.5,1.5,4.3,0.8,5.9c-0.5,1.1-3.2,1.9-5,1.9c-30.7,0.9-61.5,1.6-92.2,2.4C2151,706.3,2151,704.7,2151,703z M2243.3,700.1
	c-14.2-19.2-27.9-37.6-42.1-56.8c-14.6,20-28.6,39-42.9,58.5C2187.1,701.2,2214.6,700.7,2243.3,700.1z"
        />
        <path
          d="M2151,453c11.3-0.3,22.6-0.6,33.9-0.8c5-0.1,5.7,0.8,3.9,5.6c-6.1,16.7-12.2,33.4-18.3,50.2c-0.8,2.3-1.5,4.7-2.6,8
	c3.8,0,6.9-0.2,10,0.1c1.3,0.1,2.5,1.4,3.7,2.2c-1.2,0.6-2.3,1.6-3.6,1.8c-2.8,0.3-5.6,0.1-9.3,0.1c0.8,2.3,1.2,4,1.9,5.6
	c4.1,10,8.3,20,12.4,30c2.1,5.2,1.4,6.3-4.1,6.3c-9.3,0.1-18.6,0-27.9,0c0-1.3,0-2.7,0-4c9.2,0,18.3,0,28.4,0
	c-4.9-11.9-9.3-22.4-14.1-34.3c-5.2,12.1-9.7,22.7-14.3,33.3c0-3.7,0-7.3,0-11c3.6-8.4,7.2-16.8,10.9-25.4
	c-4.4-0.7-7.6-1.1-10.9-1.6c0-1,0-2,0-3c3.8,0,7.5,0,12.5,0c-4.4-12.3-8.4-23.7-12.5-35c0-4.3,0-8.7,0-13
	c4.7,13.3,9.4,26.6,14.1,39.8c0.4,0,0.8-0.1,1.2-0.1c6.1-16.8,12.3-33.7,18.4-50.7c-11.9,0-22.8,0-33.7,0
	C2151,455.7,2151,454.3,2151,453z"
        />
        <path
          d="M2151,574c10-0.2,20-0.6,30-0.7c20.3-0.2,40.7-0.2,61-0.2c1.4,0,2.9,1,4.3,1.5c-0.5,1.3-0.8,2.8-1.6,3.9
	c-12.6,15.9-25.3,31.8-38,47.6c-4,5-5.5,5.1-9.8,0.3c-12.6-14.1-26.1-27.2-41.3-38.4c-1.5-1.1-3-2-4.5-3.1
	C2151,581.3,2151,577.7,2151,574z M2237.5,578.8c-10.2-1.9-82.8-0.9-83.9,0.2c16.2,14.8,32.4,29.6,48.7,44.5
	C2213.9,608.9,2225.6,594,2237.5,578.8z"
        />
        <path
          d="M2151,594c3.8,2.8,8,5.4,11.5,8.6c8,7.4,16,15,23.6,22.8c4.4,4.6,3.8,6.5,0.3,11.8c-9.6,14.3-19.1,28.7-28.6,43.1
	c-1.6,2.5-2.9,5.3-6.7,4.7C2151,654.7,2151,624.3,2151,594z M2153.7,600.7c-0.5,0.3-0.9,0.6-1.4,0.8c1.1,23.7,2.1,47.4,3.2,71.3
	c0.8-0.3,1.2-0.3,1.3-0.5c8.8-12.9,17.6-25.8,26.1-38.9c0.7-1.1,0.5-3.8-0.4-4.7C2173,619.2,2163.3,610,2153.7,600.7z"
        />
        <path
          class="st0"
          d="M2151,457c10.9,0,21.8,0,33.7,0c-6.2,17-12.3,33.9-18.4,50.7c-0.4,0-0.8,0.1-1.2,0.1
	c-4.7-13.3-9.4-26.6-14.1-39.8C2151,464.3,2151,460.7,2151,457z M2164.6,462.7c-1.1-0.1-3.1-0.2-5.1-0.4c-3-0.2-4.6,1.1-3.5,4.2
	c2.1,6.3,4.2,12.6,6.6,18.7c0.4,1,2,2.4,2.7,2.2c1.2-0.3,2.4-1.6,2.9-2.8c1.6-4,3.1-8.1,4.4-12.2
	C2175.6,462.8,2175.6,462.8,2164.6,462.7z"
        />
        <path
          class="st0"
          d="M2151,557c4.5-10.6,9.1-21.2,14.3-33.3c4.9,11.9,9.3,22.4,14.1,34.3c-10.1,0-19.3,0-28.4,0
	C2151,557.7,2151,557.3,2151,557z"
        />
        <path
          class="st0"
          d="M2151,481c4,11.3,8.1,22.7,12.5,35c-4.9,0-8.7,0-12.5,0C2151,504.3,2151,492.7,2151,481z"
        />
        <path
          class="st0"
          d="M2151,519c3.2,0.5,6.4,1,10.9,1.6c-3.7,8.6-7.3,17-10.9,25.4C2151,537,2151,528,2151,519z"
        />
        <path
          d="M2151,245c2.8,2.8,5.8,5.5,8.5,8.4c0.7,0.7,0.6,2,0.9,3c-1.2,0-2.7,0.4-3.5-0.1c-2.1-1.5-4-3.5-6-5.3
	C2151,249,2151,247,2151,245z"
        />
        <path d="M2151,225c2.9-2.3,5.9-4.5,8.8-6.8c0.4,0.5,0.9,1,1.3,1.4c-1.4,5.4-6.7,7.5-10.1,11.3C2151,229,2151,227,2151,225z" />
        <path d="M2151,191c1.8-1.3,3.7-2.5,5.5-3.8c-0.6,2.3-0.9,4.6-1.9,6.7c-0.8,1.8-2.4,3.4-3.6,5C2151,196.3,2151,193.7,2151,191z" />
        <path
          d="M2151,235c2.1,0,4.3-0.2,6.4,0.1c0.8,0.1,1.4,1.4,2.1,2.1c-0.7,0.6-1.3,1.5-2.1,1.6c-2.1,0.2-4.3,0.1-6.4,0.2
	C2151,237.7,2151,236.3,2151,235z"
        />
        <path
          d="M2189.1,313c-0.3-2-0.9-4-0.8-5.9c0.9-19,2-37.9,3-56.9c0.9-16.6,1.9-33.3,2.8-49.9c0.4-6.6,0.4-6.8,6.8-6.8
	c25.7-0.1,51.3-0.3,77-0.4c7.1,0,7.3,0,7,7.3c-1.2,32.8-2.6,65.6-3.8,98.4c-0.1,3.4-1.4,4.8-4.7,4.9c-21.8,1.2-43.6,2.5-65.4,3.8
	c-3.2,0.2-4.7-1.1-4.8-4.2c0-0.3,0-0.7,0-1c-0.4-29.7-0.8-59.3-1.2-89c0-3,1.1-4.2,4-4.4c18.4-1.4,36.9-2.9,55.3-4.5
	c3.4-0.3,4.5,1.2,4.3,4.3c-1.4,25.6-2.9,51.2-4.2,76.8c-0.2,3.2-1.2,4.9-4.6,5.1c-11.3,0.7-22.6,1.5-33.9,2.4c-3.7,0.3-5-1-5-4.7
	c-0.4-20.3-0.9-40.6-1.5-61c-0.1-3.9,1-5.7,5.3-5.7c9.3-0.1,18.6-0.8,28-1.2c1.6-0.1,3.2,0.2,5.5,0.4c-0.4,8.7-0.8,16.9-1.3,25.2
	c-0.6,9.3-1.5,18.6-1.8,27.9c-0.2,4.5-1.9,6.6-6.5,6.7c-4.2,0.1-8.3,0.6-12.4,1.1c-3.2,0.4-4.7-1.1-4.7-4.2
	c-0.1-12.8-0.2-25.7-0.4-38.5c0-2.6,1.2-3.7,3.7-3.3c2.6,0.4,5.3,0.6,7.8,1.4c1.1,0.4,2.5,1.9,2.6,3.1c0.7,8.3,1.1,16.6,1.4,24.9
	c0,1-1.2,2-1.8,3c-0.6-1-1.8-1.9-1.9-3c-0.5-6.5-1-13-1.2-19.5c-0.1-4.2-1.8-5.8-6.5-4.5c0,6.6,0,13.2,0,19.8c0,3.7,0.2,7.3,0,11
	c-0.1,3.6,1,5.3,4.9,4.7c12-1.9,10.4,1.4,11.1-11.3c0.7-11.5,1.7-22.9,2.6-34.4c0.5-6.2,0.4-6.4-5.5-6.1c-6.8,0.3-13.6,0.7-20.5,0.8
	c-3,0.1-4.1,1.2-4.1,4.2c0.4,18.3,0.8,36.6,1.2,55c0,2.5,0.8,3.7,3.5,3.4c9.1-0.7,18.3-1.4,27.4-1.8c3.7-0.2,4.7-1.9,4.9-5.3
	c0.7-15.8,1.6-31.6,2.4-47.4c0.4-6.8,0.7-13.6,1.1-20.4c0.2-3.6-1.2-4.5-4.7-4.2c-14.9,1.3-29.9,2.5-44.8,3.5
	c-4.1,0.3-5.8,1.3-5.7,5.8c0.6,26.3,1,52.6,1.4,79c0.1,3.9,1,5.7,5.5,5.4c18.6-1.3,37.2-2.4,55.9-3.3c4.3-0.2,5.5-1.8,5.6-6
	c1-30.3,2.3-60.6,3.6-90.9c0.2-4-1-5.2-5-5.2c-24.2,0.2-48.3,0.2-72.5,0.1c-4.2,0-5.3,1.6-5.5,5.5c-1.8,34.9-3.8,69.8-5.8,104.8
	c-0.1,1.7-0.7,3.3-1.1,4.9C2190.4,313,2189.7,313,2189.1,313z"
        />
        <path
          d="M2316.6,640.3c-2.8-1.7-5-3.1-7.3-4.5c-3.3-2-3.6-4.5-1-7.4c2.6-2.8,5-5.8,7.8-8.5c2.3-2.2,2.3-4.1,0.3-6.4
	c-1.9-2.1-3.4-4.5-5.2-6.7c-2.6-3.1-2.4-6.1,0.4-8.9c2.6-2.5,5.5-4.8,8.7-7.6c-3.1-3.7-5.7-6.5-7.8-9.5c-1-1.4-0.9-3.5-1.2-5.3
	c1.6,0.7,3.6,1,4.8,2.2c2.5,2.4,4.5,5.3,7.3,8.7c2.7-2.9,5-5.7,7.6-8.1c1-0.9,2.6-1.1,4-1.7c-0.1,1.5,0.1,3.2-0.5,4.5
	c-0.6,1.5-1.9,2.7-3,3.9c-4.6,5.3-4.7,5.3-0.3,11.1c0.1,0.1,0.2,0.3,0.3,0.4c6.6,8.9,6.8,9.1-1.5,16.2c-4.1,3.5-5.5,6.4-1.7,10.9
	c3.6,4.3,3.2,8.4-1.3,12.1c-0.9,0.7-1.4,1.8-2.5,3.1c3.6,2.5,7,4.7,10.3,7.2c5.3,4,5.3,7,0.3,11.1c-0.3,0.2-0.5,0.4-0.8,0.6
	c-2.9,2.2-7.4,4-8.2,6.9c-0.7,2.6,2.4,6.4,4,9.6c1.6,3.2,1,5.8-1.7,7.9c-1.7,1.3-3.3,2.7-6,4.8c3.6,3.3,7.1,6.3,10.2,9.6
	c1.1,1.2,1.3,3.2,1.9,4.8c-1.6-0.4-3.6-0.2-4.7-1.2c-2.7-2.1-5.3-4.6-7.6-7.2c-2.5-2.9-4.5-2.8-7-0.2c-2.8,2.9-5.7,5.6-8.8,8.1
	c-1.2,1-3,1.4-4.5,2.1c0.4-1.9,0.3-4.1,1.4-5.5c3.1-4.1,6.7-7.8,11.4-13.2c-1.3-0.9-3.9-2.4-6.1-4.4c-4.2-3.9-4.1-5.5,0.2-9.2
	c2.8-2.4,5.7-4.6,9.2-7.3c-2.3-2.5-4.2-5-6.5-7c-2.7-2.4-2.7-4.7-0.7-7.4C2312.4,646.2,2314.3,643.5,2316.6,640.3z M2333.6,652.1
	c-10.7-10.7-12.2-10.7-18.9-0.8c-0.2,0.3-0.2,0.6-0.3,1.2c1.8,2,3.5,4.3,5.6,6.1c0.8,0.7,2.8,1.1,3.6,0.6
	C2326.9,657.1,2330,654.6,2333.6,652.1z M2331.5,604.6c-2.9-3.8-5.6-7.2-8.6-11c-3.3,3.1-6.1,5.8-9,8.6c3,3.9,5.5,7.3,8.4,11
	C2325.4,610.3,2328.3,607.6,2331.5,604.6z M2310.8,674.9c7,8.4,7.7,8.7,11.8,5.3c4.2-3.5,3.9-4.8-2.6-12.2
	C2317.1,670.2,2314.1,672.4,2310.8,674.9z M2319.3,635.8c7.3-5.7,7.5-7.3,1.3-13.8c-2.8,3.1-5.6,6.1-8.7,9.6
	C2315,633.3,2317.2,634.6,2319.3,635.8z"
        />
        <path
          d="M2376.1,670.3c-8-0.3-14.6-0.6-21.2-0.8c-7.3-0.3-8.6-2.3-4.6-8.4c7.6-11.7,15.4-23.3,23.2-35c1-1.5,2.1-3,3.9-5.7
	c-6.4-0.3-11.7-0.4-17.1-0.8c-3.3-0.3-6.7-1-10-1.5c1.3-3.3,2.1-6.9,4.1-9.8c6.4-9.5,13.2-18.8,19.9-28.2c0.7-1,1.4-2.1,2.6-4
	c-7.1,0-13.4,0.1-19.6-0.1c-1.7-0.1-3.3-1.2-5-1.9c1.6-1,3.2-2.7,4.8-2.8c7.2-0.3,14.3-0.2,21.5,0c1.6,0,4.4,0.9,4.6,1.8
	c0.3,1.6-0.6,3.8-1.6,5.2c-7.5,10.6-15.3,21-22.9,31.5c-0.9,1.3-1.6,2.8-3.1,5.5c6.8,0,12.3,0,17.9,0c1.7,0,3.3,0.3,5,0.5
	c5.4,0.7,6.1,2.4,2.9,7.1c-8.2,11.9-16.4,23.9-24.5,35.9c-1,1.5-1.9,3.1-3.4,5.5c4,0.2,7.2,0.3,10.4,0.5c4.5,0.3,9,0.6,13.4,1.2
	c1.6,0.2,3.9,1.2,4.3,2.4c0.5,1.3-0.5,3.5-1.6,4.8c-7.5,8.7-15.1,17.3-22.7,26c-0.8,0.9-1.5,2-2.7,3.6c7.7,0,14.6-0.1,21.5,0.1
	c2.4,0.1,5.8-1.6,6.3,2.5c0.1,0.5-3.9,2.1-6.1,2.2c-7.7,0.4-15.3,0.5-23,0.4c-1.9,0-3.9-1.1-5.8-1.7c0.8-1.9,1.1-4.1,2.4-5.6
	c7.5-8.7,15.1-17.3,22.7-26C2373.6,673.7,2374.3,672.6,2376.1,670.3z"
        />
        <path
          d="M2201.1,394c3.3,2.4,6.5,4.7,9.7,7c3.6,2.7,3.8,5.6,0.6,8.7c-2.8,2.6-5.6,5.2-8.5,7.7c-2.7,2.2-2.7,4.2,0,6.5
	c3.9,3.5,7.6,7.2,12.1,11.5c-2.5,1-3.8,2.1-5.1,2.1c-8.3,0-16.6,0.2-24.9-0.4c-4.5-0.3-5-2.5-1.9-5.9c2.4-2.5,4.9-5,7.6-7.3
	c3-2.5,2.8-4.5-0.4-6.6c-1.8-1.2-3.4-2.6-5.1-4c-4.2-3.5-4.3-5.1-0.7-9c2.4-2.6,4.8-5.1,7.3-7.5c2.5-2.3,2.2-4.2-0.3-6.1
	c-2.6-2-5.3-4-7.9-6.2c-2.9-2.4-3-5-0.1-7.5c0.8-0.7,1.5-1.3,2.3-2c7.6-6.1,7.5-5.1,0.9-12.2c-7.4-7.8-7.4-7.8-1.7-16.7
	c0.1-0.1,0.2-0.3,0.3-0.4c4.8-7.7,4.8-7.6-1.8-13.6c-1.9-1.7-3.1-4.3-4.6-6.4c2.6-0.9,5.1-2.1,7.7-2.5c4.1-0.6,8.3-0.8,12.4-0.9
	c1.2,0,2.3,0.8,3.5,1.2c-0.5,1.1-0.9,2.2-1.5,3.2c-1.7,2.6-3.8,5.1-5.1,7.9c-0.5,1.1,0.4,3.6,1.5,4.4c4.7,3.7,9.7,7,14.6,10.4
	c4.3,3,4.5,4.7,0.7,8.2c-3.6,3.2-7.4,6.2-11.2,9.2c-2.5,1.9-2.9,3.5-0.5,6c3,3,7.3,6.4,7.6,9.8c0.2,3-4.4,6.4-6.9,9.6
	C2201.5,392.6,2201.5,393,2201.1,394z M2212.1,354c-3.9-2.8-6.8-4.9-9.8-6.9c-10.1-7-10.2-7-16.2,3.6c-0.9,1.6-1.6,4.7-0.8,5.7
	c3.2,3.6,7.1,6.7,10.8,9.9c0.2,0.2,1.2-0.4,1.7-0.8C2202.4,361.9,2206.9,358.2,2212.1,354z M2210,405.6c-0.5-0.7-0.7-1.4-1.2-1.7
	c-3.6-2.4-7-5.6-10.9-6.6c-1.8-0.5-4.7,3.5-7.1,5.5c-1.1,1-2.1,2.2-3.2,3.2c-2.2,1.9-1.8,3.6,0.4,5.2c10.7,7.8,7,9,16.5,0.2
	c1.2-1.1,2.5-2.2,3.7-3.3C2208.9,407.4,2209.4,406.5,2210,405.6z M2195,371.9c-3.5,3-6.6,5.4-9.1,8.4c-0.3,0.3,2.6,3.6,4.1,5.4
	c0.7,0.9,1.7,1.5,2.6,2.3c4.9,4,5,3.7,10.7-2.1c3.7-3.7,0.5-5.4-1.6-7.6C2199.8,376.2,2197.6,374.3,2195,371.9z M2206.8,434.4
	c0.2-0.4,0.4-0.9,0.6-1.3c-3.1-2.9-6.1-5.8-9.3-8.6c-0.5-0.4-1.8-0.5-2.2-0.1c-3.3,2.8-6.4,5.7-9.6,8.6c0.3,0.5,0.6,1,0.9,1.5
	C2193.7,434.4,2200.2,434.4,2206.8,434.4z M2196.5,326.4c-4.8,0.2-8.3,0.4-11.7,0.6c-0.2,0.5-0.3,1-0.5,1.6c2.2,1.9,4.5,3.8,7.2,6.1
	C2193.2,331.9,2194.5,329.8,2196.5,326.4z"
        />
        <path
          d="M2335.4,503.6c-1.1,1.8-1.8,3.3-2.8,4.6c-11.9,16.2-23.9,32.4-35.7,48.7c-5.2,7.2-6.3,7.2-10.5-0.8
	c-7.4-14.1-14.9-28.2-22.5-42.3c-1.8-3.3-4.2-6.4-6.5-9.5c-2.2-3-2-5.5,0.2-8.6c10.4-14.6,20.8-29.3,30.9-44.1
	c4.2-6.1,4.3-6.3,8.6-0.4c11.5,15.5,23.1,31,34.6,46.5C2333.2,499.5,2334.2,501.6,2335.4,503.6z M2293.2,452.8
	c-1.5,2.1-2.6,3.5-3.6,5c-9.2,13-18.3,26.1-27.7,39c-2,2.8-1.4,4.4,0.1,7c9.1,15.9,17.9,31.8,26.8,47.8c0.8,1.5,1.6,3.1,2.7,5.1
	c1-1.1,1.6-1.7,2.1-2.3c11.8-16.1,23.5-32.2,35.5-48.2c1.9-2.6,0.9-4.1-0.6-6.1c-6-8-12-16-18-24
	C2304.9,468.6,2299.3,461,2293.2,452.8z"
        />
        <path
          d="M2373.8,374c0-15.5,0-31,0-46.5c0-1.2-0.3-2.5,0.1-3.5c0.7-1.6,1.9-3,3-4.5c1.3,1.2,3.2,2.1,3.7,3.6
	c3.3,9.6,5.9,19.4,9.5,28.9c8.5,22,17.3,44,26.2,65.8c4.6,11.4,3.9,12.5-8.4,12.7c-8.1,0.1-16.3,0.9-24.4,1.3
	c-7.7,0.4-9.2-0.9-9.3-8.5c-0.1-16.5,0-33,0-49.5C2374,374,2373.9,374,2373.8,374z M2378.6,334.5c-0.2,0-0.5,0-0.7,0
	c0,1.7,0,3.5,0,5.2c0.1,24,0.1,47.9,0.2,71.9c0,4.2,0.1,8.3,0.2,12.5c0,2.6,1,4,3.9,3.8c10.4-0.7,20.9-1.3,31.3-2
	c0.2,0,0.4-0.5,0.7-0.9C2402.3,394.8,2390.4,364.7,2378.6,334.5z"
        />
        <path
          d="M2388.9,248c0-18.5-0.1-36.9,0.1-55.4c0-1.7,1.2-3.4,1.9-5.1c1.5,1.3,3.6,2.2,4.3,3.8c7.8,17.3,15.5,34.6,27.4,49.7
	c0.9,1.1,0.7,3.8-0.1,5.3c-9.8,19.8-19.7,39.6-29.7,59.3c-0.8,1.6-2.4,2.9-3.6,4.3c-0.8-1.8-2.2-3.6-2.2-5.5
	c-0.2-18.8-0.1-37.6-0.1-56.4C2387.7,248,2388.3,248,2388.9,248z M2392.7,294.3c0.5-0.7,1.1-1.3,1.4-2c7.9-15.5,15.9-31.1,23.6-46.7
	c0.5-1,0.4-2.9-0.2-3.9c-7.2-12.9-14.5-25.7-21.7-38.6c-0.5-0.9-1.2-1.7-1.8-2.5c-0.4,0.1-0.9,0.2-1.3,0.3
	C2392.7,231.8,2392.7,262.9,2392.7,294.3z"
        />
        <path
          d="M2383.2,507.8c0,16.8,0.1,33.7-0.1,50.5c0,1.7-1.1,3.4-1.7,5.1c-1.3-1.3-3-2.4-3.9-4c-10.1-18-20-36.1-29.9-54.2
	c-0.7-1.3-0.6-4.1,0.4-5.2c11.3-13.4,18.8-29,26.4-44.6c0.9-1.7,3-2.8,4.6-4.3c0.7,1.9,2.1,3.7,2.1,5.6c0.2,17,0.1,34,0.1,51
	C2381.9,507.8,2382.6,507.8,2383.2,507.8z M2377.1,463c-8.5,13.2-16.6,25.7-24.5,38.3c-0.6,0.9-0.4,2.9,0.2,4
	c7.9,14.2,15.8,28.4,23.8,42.6c0.4,0.8,1.1,1.4,2.3,2.9C2378.3,520.8,2377.7,492,2377.1,463z"
        />
        <path
          d="M2251.1,651.4c0,8,0.1,16-0.1,24c0,1.5-1.5,3-2.4,4.5c-1.5-0.9-3.4-1.4-4.3-2.7c-9.5-12.8-18.9-25.8-28.4-38.5
	c-2.6-3.4-3.2-5.4,0.2-9.5c6.7-8.4,12-18,17.8-27c2.1-3.2,4-6.6,6.4-9.5c1.1-1.3,3.1-1.9,4.8-2.8c0.9,1.6,2.4,3.2,2.6,4.9
	c1,13.8,1.8,27.6,2.5,41.4c0.3,5.1,0,10.3,0,15.5C2250.5,651.4,2250.8,651.4,2251.1,651.4z M2243.4,598.6c-1.5,0.6-1.9,0.7-2,0.9
	c-6.9,10.6-13.9,21.1-20.4,32c-0.9,1.5-0.3,4.9,0.8,6.5c4.3,6.1,9.3,11.7,13.9,17.7c3.5,4.5,6.6,9.2,10,13.9
	c0.5-0.1,0.9-0.2,1.4-0.3C2245.8,645.9,2244.6,622.6,2243.4,598.6z"
        />
        <path
          d="M2315.6,342.6c1.3,1.1,2.9,2,4,3.3c10.1,11.8,20,23.6,30.1,35.4c3.2,3.8,3.6,7.6,0.9,11.8c-8.5,13.2-16.8,26.4-25.3,39.5
	c-1.1,1.6-3.5,2.4-5.3,3.6c-0.6-1.9-1.7-3.9-1.8-5.8c-1.5-26.9-2.9-53.9-4.2-80.8c-0.1-2.1,0.2-4.2,0.3-6.3
	C2314.7,343.1,2315.1,342.8,2315.6,342.6z M2322.4,428c1-0.6,1.6-0.7,1.8-1.1c7.8-12.2,15.6-24.3,23.1-36.6c0.8-1.3,0.4-4.5-0.6-5.8
	c-8-10-16.3-19.7-24.6-29.5c-0.9-1-2.2-1.8-4.5-3.6C2319.2,377.8,2320.8,402.7,2322.4,428z"
        />
        <path
          d="M2281.9,418.4c5.9,0.7,11.2,1.2,16.4,1.9c4.7,0.6,7.1,3,6.7,8.1c-0.3,3.1,0,6.3-0.2,9.5c0,0.8-1,1.5-1.5,2.2
	c-0.7-0.7-2.1-1.4-2.1-2.2c-0.3-2.8-0.4-5.7-0.2-8.5c0.2-3.4-1.3-5-4.5-5.3c-4.6-0.4-9.3-0.9-13.9-1.1c-4.2-0.2-6.5-1.5-4.9-6.1
	c0.3-0.8,0.3-1.6,0.5-2.5c2.1-10.9,1.3-11.9-10-12.5c-3-0.2-6-0.4-9-0.7c-6.3-0.5-7-1.3-6.5-7.8c0.5-6.8,0.9-13.6,1.8-20.4
	c0.4-3.4-0.4-5-4-5.1c-3.5-0.1-7-0.7-10.4-1.2c-3.8-0.5-5.5-2.1-4.5-6.4c2.3-9.7,4.2-19.4,6.5-30c-4.5-0.5-8.7-0.9-12.9-1.4
	c-1.5-0.2-3.1-0.1-4.4-0.6c-1.1-0.5-2-1.7-2.9-2.6c1.2-0.5,2.5-1.5,3.7-1.4c5.1,0.4,10.3,0.9,15.4,1.6c5,0.7,6,2.1,4.9,7.1
	c-1.8,8.1-3.4,16.3-5.4,24.3c-1,4.2,0.1,6,4.5,5.8c1.6-0.1,3.3,0.4,4.9,0.7c9.4,1.3,9.6,1.7,8.6,10.9c-0.7,6-0.9,12-1.5,17.9
	c-0.3,2.9,0.7,4.2,3.8,4.4c5.1,0.4,10.3,0.9,15.3,1.8c6.4,1.2,7.7,3.3,7.3,9.7c-0.1,1.7-0.4,3.3-0.7,4.9
	C2282.4,415,2282.2,416.5,2281.9,418.4z"
        />
        <path
          d="M2321.4,64.7c1.8-0.3,3.2-0.6,4.6-0.6c25.5-0.4,50.9-0.8,76.4-1c1.7,0,4.6,1.4,4.9,2.6c0.3,1.8-0.5,4.8-1.9,5.9
	c-15.5,11.7-28.8,25.7-41.1,40.6c-1.8,2.2-3.8,3.4-6.1,0.6c-12.1-15.2-24.2-30.5-36.3-45.7C2321.7,66.7,2321.7,66.1,2321.4,64.7z
	 M2404,67.6c-0.2-0.5-0.4-1.1-0.7-1.6c-24.9,0.6-49.7,1.3-75.7,1.9c1.7,2.3,2.5,3.5,3.4,4.7c9,11.4,18.1,22.6,26.8,34.1
	c2.7,3.6,4.1,2.8,6.8,0.1c11-11.3,22.1-22.4,33.3-33.6C2399.8,71.2,2401.9,69.5,2404,67.6z"
        />
        <path
          d="M2286.1,664.1c-4.5-3.5-8.6-6.7-12.5-9.9c-5.6-4.5-5.8-6.2-1.6-12c5.1-6.8,10.2-13.4,15.7-20.5c-4.7-3.5-9-6.6-13.2-9.8
	c-4.7-3.6-5-5.3-1.9-10.4c4.6-7.7,9.1-15.5,13.8-23.1c0.7-1.2,2.3-1.9,3.5-2.8c0.2,1.6,1,3.6,0.4,4.8c-4.1,7.4-8.4,14.7-13,21.9
	c-1.9,3-1.9,4.9,1.3,6.9c2.8,1.7,5.4,3.9,8.1,5.9c6.7,4.9,6.9,6.5,1.9,13.3c-4.2,5.6-8.4,11.2-12.8,16.6c-1.9,2.4-1.9,3.8,0.7,5.6
	c4.2,3,8.2,6.3,12,9.7c3.6,3.2,3.5,5.3-0.2,8.6c-3.7,3.3-7.5,6.6-11.4,9.7c-2.4,2-2.2,3.5,0,5.6c4.2,4,8.3,8.1,12.2,12.5
	c0.9,1,0.5,3.1,0.7,4.7c-1.3-0.6-3-0.8-4-1.8c-4.8-4.6-9.4-9.5-14.2-14.1c-3.3-3.1-3.2-5.9,0.2-8.8
	C2276.4,672.6,2280.9,668.6,2286.1,664.1z"
        />
        <path
          d="M2361.8,349.1c0,8.2,0.2,16.3-0.1,24.5c-0.1,2.2-1.8,4.3-2.8,6.5c-2-1.4-4.6-2.4-6-4.2c-10.7-13.6-21.3-27.3-31.7-41.1
	c-2.6-3.4-5.1-7.1-6.9-10.9c-1-2.1-1-5.1-0.2-7.3c0.3-1,3.6-1.4,5.5-1.3c10.9,0.8,21.8,2.1,32.8,2.9c7.4,0.6,8.6,1.5,8.6,9
	c0.1,7.3,0,14.6,0,22C2361.3,349.1,2361.5,349.1,2361.8,349.1z M2356.7,373.9c0.4-0.2,0.9-0.3,1.3-0.5c0-1.6,0-3.2,0-4.8
	c-0.3-12-0.6-23.9-0.9-35.9c-0.3-11.4,1.5-10.1-10.5-10.7c-9.7-0.5-19.4-1.6-30-2.5C2327.5,339.9,2344,355.4,2356.7,373.9z"
        />
        <path
          d="M2396.8,695.9c0.3-2.7,0-4.4,0.7-5.6c3.9-7.2,2.3-14-0.5-21.1c-2.2-5.4-1.5-10.9,1-16.4c1.3-3,1.2-7,0.7-10.4
	c-0.5-3.9-2.9-7.7-2.9-11.5c0-4.6,0.7-9.8,3-13.7c3.6-6,4.1-11.3,0.9-17.3c-3.7-6.8-6.1-13.9-3.3-21.5c1.1-3,3.2-5.8,5.4-8.2
	c0.9-0.9,3.4-0.3,5.2-0.4c-0.6,1.4-0.9,3.3-1.9,4.3c-5.2,4.7-6.7,9.8-4.2,16.6c1.3,3.7,3.1,7.3,4.6,11c2.3,5.7,1.9,11.2-1.8,16.1
	c-4.3,5.6-4.9,11.5-2.3,17.9c3.1,7.6,4.1,14.7-0.3,22.5c-1.5,2.6-0.6,7.3,0.6,10.4c2.7,7.2,4.3,14,0.8,21.6c-1.4,3.2,0,7.8,0.3,11.7
	c0.2,2.6,1.1,5.2,1.3,7.8c0.1,0.8-1.1,1.7-1.7,2.5c-0.8-0.7-2.1-1.2-2.3-2C2398.8,705.1,2397.7,699.9,2396.8,695.9z"
        />
        <path
          d="M2407.1,177.6c-9.3,0-17.4,0-25.6,0c-17.9,0.1-35.9,0.2-53.8,0.2c-2,0-4-0.8-6-1.3c0.8-2.1,1.1-4.5,2.4-6.1
	c11-14,22.2-27.8,33.4-41.6c3.7-4.6,4.5-4.7,8.3-0.3c12.9,15,25.8,30,38.6,45C2405.3,174.5,2405.9,175.7,2407.1,177.6z
	 M2400.4,174.6c-0.8-1.6-1.1-2.7-1.8-3.5c-11.3-13.1-22.7-26.2-34-39.3c-2.1-2.4-3.7-2.1-5.6,0.2c-10.4,13-20.9,26-31.3,39
	c-0.7,0.9-1,2.2-1.7,3.6C2351.1,174.6,2375.4,174.6,2400.4,174.6z"
        />
        <path
          d="M2184.2,148.2c6.4-5.8,12.4-11.4,19-17.4c-4.1-3.7-8.2-7.4-12.2-11c-2.6-2.3-5.3-4.6-7.7-7.1c-1.1-1.1-1.4-2.9-2-4.4
	c1.6,0.5,3.4,0.7,4.6,1.7c6.7,5.8,13.1,11.8,19.8,17.6c2.8,2.4,2.7,4.4,0.1,7c-5.7,5.6-11.2,11.4-16.7,17.2
	c-2.1,2.2-4.2,2.5-6.6,0.6c-5.8-4.7-11.8-9.3-17.2-14.4c-1.2-1.1-1.5-4.5-0.7-6c2.9-5.4,6.4-10.5,9.7-15.7c1.8-2.9,4.2-2.7,6.3-0.9
	c4.6,4,9.2,8.3,13.4,12.8c0.8,0.9,0.6,3.9-0.3,5c-2,2.4-4.7,4.1-7.2,6.2c-3.4,2.9-6.5,2.5-9.7-0.4c-6-5.4-6.1-8.1,0.5-12.9
	c1.1-0.8,3.6-1,4.7-0.3c1.5,0.9,2.3,3,3.4,4.6c-0.4,0.4-0.9,0.7-1.3,1.1c-1.4-0.6-2.9-1.2-4.3-1.8c-2.8,1.6-3.5,3.5-0.7,5.8
	c6.4,5.1,6.9-3.8,10.9-3.8c-4.2-4.6-8-8.8-12.2-13.3c-3.2,5.1-6.2,9.7-8.9,14.5c-0.5,0.9-0.3,3.1,0.5,3.7
	C2174.5,140.6,2179.8,144.7,2184.2,148.2z"
        />
        <path
          d="M2311.7,189.1c5.4,6.5,11,12.9,16.1,19.6c0.8,1-0.1,4.2-1.2,5.6c-3.3,4.2-6.9,8.1-10.6,11.9c-2.4,2.4-2.7,4.1,0.1,6.5
	c3.6,3.2,6.7,7,10.3,10.2c3.4,3,2.9,5.4,0.1,8.4c-4.3,4.6-8.4,9.5-12.7,14.1c-2.2,2.3-1.9,3.8,0.4,5.7c4.4,3.8,8.6,7.9,12.8,11.9
	c2.2,2.1,2.1,4,0.2,6.5c-4.4,5.8-8.5,11.9-12.7,17.9c-0.5,0.7-0.8,1.7-1.4,2c-1.3,0.7-2.7,1-4.1,1.5c0.1-1.3-0.1-2.9,0.5-3.9
	c3.6-5.6,7-11.4,11.3-16.5c3.1-3.7,2.4-5.7-0.9-8.4c-3.5-2.8-6.6-6.1-9.9-9.2c-3.2-3-3.8-5.9-0.4-9.4c4-4.2,7.6-8.8,11.7-13
	c2.7-2.7,2.4-4.6-0.3-7c-3.5-3.1-6.7-6.6-10.2-9.5c-3.1-2.6-3.4-4.9-0.6-7.9c3.8-4,7.3-8.2,11.1-12.2c2.2-2.3,2.2-3.8,0.1-6.3
	c-3.7-4.2-7.1-8.8-10.4-13.4c-0.9-1.2-0.8-3.1-1.1-4.7C2310.5,189.4,2311.1,189.3,2311.7,189.1z"
        />
        <path
          d="M2431.7,647.8c-0.8,2.7-1.4,5.7-2.6,8.3c-2,4.6-2.3,9.1,0.3,13.4c3.6,6.1,3.3,11.9-0.7,17.8c-3.5,5.2-2.8,10.6-0.7,16.3
	c0.7,2,0.2,4.5,0.2,6.8c-1.4-1.7-3.3-3.1-4.1-5.1c-3.1-7.3-3.1-14.5,1.3-21.4c2.5-3.8,2.3-7.9,0.1-11.9c-3.3-5.8-3.4-11.7-0.4-17.7
	c2.5-5,2.1-10,0.2-15c-2.7-7.3-2.4-14.4,1.4-21.2c2.9-5.2,3.2-10.5,1.5-15.9c-3.4-10.8-4.2-21.5,1-32c0.7-1.4,2.5-2.3,3.8-3.5
	c0.2,1.7,1,3.6,0.4,4.9c-4.2,9.3-4.4,18.5-1,28.1c2.4,6.8,2.2,13.9-1.5,20.3c-3.4,5.9-3.7,11.9-1.5,18.2
	C2430.5,641.2,2430.9,644.3,2431.7,647.8z"
        />
        <path
          d="M2422.1,330.5c-1.4,16.6-2.8,33.1-4.3,49.7c-0.1,1-0.2,2.1-0.7,2.9c-0.8,1.4-1.9,2.7-2.9,4.1c-1.3-1.3-3.2-2.4-3.7-3.9
	c-6-18.7-11.9-37.4-17.7-56.2c-1.4-4.7,0.3-6.8,4.9-6.8c5.5-0.1,11-0.1,16.4-0.3c6.9-0.2,7.6,0.4,7.7,7.6c0,1,0,2,0,3
	C2422,330.5,2422.1,330.5,2422.1,330.5z M2413.1,376.9c0.6-1.3,0.9-1.7,0.9-2.2c1.2-15.9,2.4-31.8,3.4-47.6c0.1-0.9-1.8-2.8-2.8-2.8
	c-5.8,0.1-11.5,0.6-17.8,1C2402.2,342.3,2407.5,359.1,2413.1,376.9z"
        />
        <path
          d="M2205,81c-6.5-4.8-13-9.6-20.4-15.2c-3,6.4-5.9,12.1-8.4,18c-3.8,8.5-3.7,8.5,4.7,12.9c4.2,2.2,8.3,4.7,13,7.4
	c2.2-4.4,4.4-8.3,6.2-12.4c0.4-0.9,0-2.8-0.8-3.4c-3.8-3-7.7-5.7-12.1-8.8c-1.7,2.9-3.1,5.4-4.9,8.5c2.7,1.3,4.8,2.4,7.7,3.8
	c-0.3-1.6-0.2-2.2-0.5-2.6c-0.9-1.3-1.8-2.5-2.7-3.7c0.7-0.4,1.6-1.3,2-1.1c1.8,1,4.7,2.1,4.9,3.5c0.4,2.3-0.6,5.1-1.9,7.2
	c-0.4,0.7-3.7,0.4-5.3-0.4c-10.1-5-10.8-9.6-2.3-17.6c1.1-1,4.2-1.2,5.6-0.3c4.6,2.7,9,5.8,13,9.3c1.3,1.1,2.1,4.3,1.5,5.9
	c-1.7,4.5-4.2,8.7-6.4,13c-1.7,3.2-4.1,3.5-7.1,1.8c-6.2-3.5-12.5-7-18.8-10.4c-2.8-1.5-4-3.6-2.7-6.5c3.3-7.6,6.5-15.3,10.2-22.8
	c2.6-5.3,5.3-5.8,10-2.6c5.2,3.6,10.1,7.6,15,11.6c0.9,0.7,1.4,2,2.1,3C2206.1,79.7,2205.6,80.3,2205,81z"
        />
        <path
          d="M2227.2,363.7c0,4.7,0.1,9.3,0,14c-0.1,4.4,1.7,6.5,6.2,6.2c11.8-0.8,9.6,0.2,9.5,9.9c-0.1,4.8-0.4,9.6-0.6,14.5
	c-0.1,3.3,1.2,4.9,4.7,4.7c4.8-0.2,9.7,0.1,14.5-0.1c4.1-0.1,5.7,1.8,5.5,5.8c-0.2,2.8-0.2,5.7-0.3,8.5c-0.3,6.2-0.3,6.3,6.1,6.4
	c3.5,0.1,7-0.2,10.5-0.1c1.1,0,2.2,0.9,3.3,1.4c-1,0.9-2,2.5-3,2.5c-6.1,0.2-12.3,0.2-18.4-0.3c-1.1-0.1-2.7-2.4-2.8-3.8
	c-0.2-3.6,0.2-7.3,0.6-10.9c0.4-3.8-0.6-5.6-4.9-5.3c-4.3,0.3-8.7,0-13,0.1c-4.4,0.1-6.6-2.1-6.7-6.2c0-6.1,0.1-12.3,0.5-18.4
	c0.2-3.2-0.7-4.4-4.1-4.4c-10.2,0-12-1.8-11.7-11.9c0.2-9.5,0.5-18.9,1-28.4c0.1-1.2,1.1-2.4,1.7-3.6c0.7,1.2,2.1,2.4,2.1,3.7
	c0.2,5.3,0.1,10.6,0.1,16C2227.7,363.7,2227.5,363.7,2227.2,363.7z"
        />
        <path
          d="M2320.3,119.5c0-11.6-0.1-23.3,0.1-34.9c0-1.6,1.2-3.2,1.8-4.8c1.6,0.9,3.7,1.5,4.7,2.9c5.8,8.7,11.4,17.6,17.1,26.4
	c0.8,1.3,1.6,2.5,2.5,3.8c4.5,6.4,4.5,6.4,0.1,12.8c-7.4,10.8-14.7,21.7-22.2,32.5c-0.8,1.2-2.4,1.8-3.7,2.7
	c-0.6-1.3-1.7-2.5-1.7-3.8c-0.2-4.1,0-8.3,0-12.4c0-8.3,0-16.6,0-25C2319.3,119.5,2319.8,119.5,2320.3,119.5z M2321.4,153
	c1.9-1.1,2.5-1.2,2.7-1.6c6.7-9.5,13.3-19.1,20.1-28.6c1.8-2.5,1.7-4.7,0-7.3c-4.7-7.1-9.2-14.3-13.8-21.4c-1.6-2.4-3.4-4.7-5.1-7
	c-0.6,0.1-1.3,0.3-1.9,0.4C2322.7,108.9,2322.1,130.4,2321.4,153z"
        />
        <path
          d="M2407.6,700.5c1.3-7.1,2.9-13.4,3.5-19.8c0.4-4.2,0.1-8.7-1-12.7c-1.7-6.7-1.7-13,0-19.6c1-4,1.1-8.7,0.1-12.7
	c-1.6-6.5-1.7-12.6,1-18.5c3.3-7.3,3.2-14.4,0.4-21.8c-3.1-7.9-2.7-15.7,2-22.9c0.9-1.3,3-1.8,4.5-2.7c-0.1,1.6,0.3,3.7-0.5,4.9
	c-4.2,6.3-4.5,12.7-1.7,19.5c3.5,8.5,3.2,16.8-0.7,25.1c-2.3,4.9-1.9,9.8-0.9,15.1c1,4.9,1.1,10.2,0,15c-1.4,6.1-1.2,11.5,0,17.6
	c1.3,6.5,0.6,13.4,0.2,20.1c-0.3,4.4-1.6,8.8-2.7,13.2c-0.3,1-1.5,1.8-2.3,2.7C2408.8,702,2408.1,701.1,2407.6,700.5z"
        />
        <path
          d="M2406.8,125.6c0,11,0.1,22-0.1,33c0,1.6-1,3.2-1.6,4.8c-1.3-1-3-1.8-3.9-3.2c-8.9-12.2-17.6-24.6-26.6-36.8
	c-2.3-3.1-2.6-5.9,0.1-8.4c9-8.7,18.1-17.2,27.3-25.6c1.1-1,3.3-0.9,5-1.3c0.4,1.5,1,3.1,1,4.6c0.1,11,0,22,0,33
	C2407.6,125.6,2407.2,125.6,2406.8,125.6z M2404.6,92.9c-0.5-0.3-1.1-0.5-1.6-0.8c-8.1,7.8-16.1,15.6-24.3,23.3
	c-2.8,2.7-2.2,4.9-0.1,7.7c6.6,8.7,13,17.6,19.5,26.5c1.2,1.6,2.4,3.3,3.7,4.9c0.4-0.2,0.8-0.3,1.1-0.5
	C2403.5,133.7,2404,113.3,2404.6,92.9z"
        />
        <path
          d="M2215.6,558.4c-1.4-1.1-3.1-2-4.1-3.4c-5.3-7-10.4-14.2-15.7-21.3c-2.3-3.1-2.2-5.7,0.7-8.3c3.9-3.4,7.7-6.8,12-10.7
	c-3.6-3.9-7.1-7.8-10.8-11.5c-3.3-3.3-3.3-6,0-9.3c4-4,7.9-8.2,11.4-12.6c1-1.2,1.3-4.1,0.5-5.5c-3.4-5.9-7.3-11.5-10.9-17.3
	c-0.9-1.5-1.4-3.3-2-5c0.4-0.3,0.9-0.6,1.3-1c1.1,0.8,2.5,1.3,3.2,2.3c4.5,6.8,8.8,13.7,13.3,20.5c1.9,2.8,1.5,5.1-0.8,7.5
	c-4.1,4.2-7.9,8.6-11.9,12.8c-1.9,1.9-2,3.5,0,5.4c3.2,3.1,6.3,6.4,9.4,9.7c4.1,4.3,4,4.9-0.6,8.4c-3.2,2.4-6.2,5-9.4,7.4
	c-2.5,1.9-2.5,3.8-0.6,6.3c4.8,6.2,9.4,12.5,14,18.8c1.1,1.5,1.8,3.3,2.7,5C2216.6,557.4,2216.1,557.9,2215.6,558.4z"
        />
        <path
          d="M2236.1,533.7c-4.3-4-8.6-7.9-12.8-12c-4.3-4.2-4.3-6-0.1-10.4c3.1-3.2,6.2-6.6,9.5-9.6c2.3-2.2,2.6-3.8,0.1-6.1
	c-3.7-3.6-7.1-7.5-10.8-11.1c-2.5-2.4-2.7-4.9-1.1-7.6c4.1-6.8,8.3-13.6,12.7-20.3c0.7-1.1,2.5-1.4,3.8-2.1
	c-0.2,1.5,0.1,3.2-0.6,4.4c-3.5,5.9-7.2,11.6-10.9,17.3c-1.9,2.8-1.6,4.9,1.1,7.3c3.6,3.2,6.6,7.1,10.1,10.3c3.6,3.3,3.2,6.1,0,9.3
	c-4.2,4.2-8.2,8.6-12.9,13.5c4.6,4.3,8.8,8.5,13.3,12.3c3.6,3.1,4,5.8,0.8,9.6c-4.4,5.4-8.2,11.3-12.5,16.8
	c-0.8,1.1-2.5,1.4-3.7,2.1c0.1-1.5-0.3-3.2,0.5-4.3C2226.8,546.9,2231.3,540.6,2236.1,533.7z"
        />
        <path
          d="M2156.3,375.2c-1-19.9-1.9-37-2.8-54.1c-0.1-1.2-0.3-2.4,0-3.5c0.2-0.8,1.2-1.8,1.9-1.8c0.7,0,1.6,1,1.9,1.8
	c0.4,1,0.4,2.3,0.4,3.4c0.9,31.4,1.8,62.9,2.8,94.3c0.2,5.8,1,11.6,1.2,17.4c0.1,1.3-0.9,2.7-1.4,4c-1-1.1-2.7-2.2-2.9-3.5
	c-0.7-3.9-1-7.9-1.1-11.9C2156.2,405.1,2156.3,388.8,2156.3,375.2z"
        />
        <path
          d="M2186.5,191c-1.2,36.2-2.3,72.5-3.5,108.7c-0.1,2.1-0.5,4.3-1.2,6.3c-0.3,0.8-1.7,1.2-2.6,1.8c-0.5-0.9-1.4-1.9-1.3-2.8
	c1.7-38,3.4-76.1,5.3-114.1c0.1-1.4,1.6-2.7,2.5-4.1c0.8,1.3,1.5,2.5,2.3,3.8C2187.4,190.8,2187,190.9,2186.5,191z"
        />
        <path
          d="M2267.8,63.5c0.3,2,0.9,4,0.9,6c0.2,35.3,0.3,70.6,0.4,105.9c0,1,0.2,2.1-0.2,3c-0.4,0.9-1.4,1.5-2.1,2.2
	c-0.6-0.7-1.5-1.3-1.8-2.1c-0.3-0.9-0.2-2-0.2-3c-0.3-35.3-0.6-70.6-0.8-105.9c0-2.2,0.8-4.3,1.3-6.5
	C2266.2,63.3,2267,63.4,2267.8,63.5z"
        />
        <path
          d="M2297,191.3c0,3.6,0.1,7.2,0,10.9c-0.7,32.9-1.4,65.9-2.1,98.8c-0.1,2.3-0.1,4.7-0.6,6.9c-0.2,1-1.5,1.8-2.3,2.6
	c-0.6-1-1.7-2-1.8-3.1c-0.2-2.3,0-4.6,0.1-7c0.6-28.8,1.1-57.5,1.7-86.3c0.1-6.5,0.5-13,1-19.4c0.1-1.5,1.3-2.8,1.9-4.2
	C2295.7,190.7,2296.3,191,2297,191.3z"
        />
        <path
          d="M2215.8,180.8c-0.4-1.7-1.1-3.3-1.1-5c-0.3-35.3-0.5-70.6-0.6-106c0-2.1,1.4-4.2,2.1-6.3c0.7,2.2,2,4.3,2,6.5
	c0.3,34.5,0.5,69,0.6,103.5c0,2.4-0.5,4.9-0.8,7.3C2217.3,180.8,2216.5,180.8,2215.8,180.8z"
        />
        <path
          d="M2298.4,378.1c1.1-14.4,2.1-28.9,3.4-43.3c0.4-3.9-0.4-5.6-4.7-6.1c-9.1-0.9-18.1-2.4-27.1-3.9c-1.4-0.2-2.6-1.8-3.9-2.8
	c1.5-0.4,3.1-1.4,4.6-1.3c9.9,1.3,19.7,2.8,29.6,4.1c4.2,0.6,6,2.7,5.8,7c-1.1,24.4-2.1,48.8-3.2,73.2c-0.1,1.3-1.4,2.6-2.1,3.8
	c-0.6-1.3-1.7-2.6-1.8-4c-0.2-9-0.1-18-0.1-27C2298.7,378.1,2298.5,378.1,2298.4,378.1z"
        />
        <path
          d="M2172.6,242.8c-0.5,15.2-1.2,30.3-1.6,45.5c-0.1,5,0.4,10,0.4,14.9c0,0.9-0.8,1.9-1.2,2.8c-0.9-0.6-2.3-1.1-2.5-1.9
	c-0.6-2.2-0.9-4.6-0.8-6.9c0.8-25.6,1.7-51.3,2.6-76.9c0.4-10.7,0.6-21.3,1-32c0-1.3,0-2.7,0.3-4c0.2-0.8,1.2-1.3,1.8-2
	c0.6,0.7,1.6,1.3,1.8,2c0.3,1.3,0.1,2.6,0.1,4c-0.6,18.2-1.2,36.3-1.8,54.5C2172.6,242.8,2172.6,242.8,2172.6,242.8z"
        />
        <path
          d="M2425.4,299.9c1.3,4.1-0.7,6.3-4.7,6.8c-6.3,0.8-12.5,1.8-18.8,2.4c-4.1,0.4-5.3-1.3-3.6-5c6.6-14.2,13.3-28.3,20.1-42.3
	c0.5-1,2.1-1.6,3.2-2.3c0.7,1.4,2,2.7,2.1,4.1C2424.4,275.6,2424.9,287.7,2425.4,299.9z M2419.4,271.5c-0.3-0.1-0.7-0.3-1-0.4
	c-5,10.7-9.9,21.4-15.4,33.2c6.4-0.8,11.6-1.3,16.8-2.2c0.9-0.2,2.2-2.1,2.1-3.1C2421.2,289.8,2420.3,280.7,2419.4,271.5z"
        />
        <path
          d="M2168.3,377.1c0.6-15.7,1.3-31.8,1.9-48c0-1.2-0.1-2.4,0.3-3.4c0.4-0.9,1.4-1.5,2.2-2.2c0.6,1,1.8,2,1.8,3
	c0,4.3-0.4,8.6-0.4,13c-0.5,28.1-0.9,56.2-1.3,84.3c0,2.3,0.6,4.6,0.5,6.9c0,1.3-0.7,2.6-1.2,3.9c-1-1.1-2.7-2-2.9-3.3
	c-0.6-2.9-0.8-5.9-0.9-8.9C2168.2,407.5,2168.3,392.5,2168.3,377.1z"
        />
        <path
          d="M2436.2,116.5c0,15.3,0,30.6,0,45.9c0,1.3,0.3,2.8-0.1,4c-0.4,1.4-1.5,2.5-2.3,3.8c-0.6-1.3-1.8-2.5-1.8-3.8
	c-0.5-19-0.8-37.9-1.2-56.9c-0.3-13.3-0.9-26.6-1.3-39.9c-0.1-1.5-0.3-3,0-4.5c0.2-0.9,1.2-1.7,1.9-2.5c0.8,0.7,2,1.2,2.3,2.1
	c0.5,1.2,0.4,2.6,0.4,3.9c0,16,0,31.9,0,47.9C2434.7,116.5,2435.5,116.5,2436.2,116.5z"
        />
        <path
          d="M2424.3,235.8c-1.3-1.5-2.1-2.2-2.6-3.2c-6.1-12.2-12.2-24.4-18.3-36.6c-0.6-1.2-1.1-2.4-1.5-3.7c-1.4-4.3-0.1-6.1,4.4-5.8
	c5.3,0.4,10.7,0.6,15.9,1.5c1.7,0.3,4.2,2.7,4.2,4.2c0.1,13.5-0.3,27-0.6,40.4C2425.7,233.4,2425.1,234.1,2424.3,235.8z
	 M2405.8,191.4c5.1,10,9.9,19.5,14.7,28.9c0.7-0.1,1.3-0.3,2-0.4c0-8.4,0.1-16.8-0.1-25.2c0-0.8-1.5-2.3-2.4-2.4
	C2415.6,191.8,2411.1,191.7,2405.8,191.4z"
        />
        <path
          d="M2342.4,550.1c0.5-10.9,0.9-21.9,1.6-32.8c0.1-1.3,1.5-2.6,2.3-3.9c1.1,0.9,2.6,1.5,3.2,2.7c6.7,12.6,13.3,25.3,19.8,38
	c1.9,3.7,0.8,5.3-3.5,4.9c-6.4-0.6-12.9-1.4-19.3-2.3C2342.3,556.2,2341.6,554.9,2342.4,550.1z M2348.5,523.1
	c-1,10.1-1.9,18.6-2.5,27.1c-0.1,0.9,1.2,2.6,2,2.7c5.2,0.8,10.4,1.2,16.8,1.8C2359.3,543.9,2354.2,534.1,2348.5,523.1z"
        />
        <path
          d="M2367.2,418.1c0.1,0.8,0.1,1.7,0.2,2.5c1,13.2-8.6,20.3-21.2,15.6c-1.3-0.5-3-3.7-2.5-4.9c5.1-13.5,10.6-26.8,16.1-40.2
	c0.4-0.8,2.7-1.8,2.9-1.6c1.2,1.4,2.8,3,3,4.7c0.6,5.6,0.7,11.3,1,16.9C2366.8,413.5,2367,415.8,2367.2,418.1
	C2367.1,418.1,2367.1,418.1,2367.2,418.1z M2362,400c-0.5,0-1.1-0.1-1.6-0.1c-4.4,10.6-8.7,21.2-13.5,32.9c5.2-0.4,8.9-0.2,12.4-1.1
	c1.7-0.5,4.1-2.9,4.1-4.5C2363.2,418.1,2362.5,409,2362,400z"
        />
        <path
          d="M2418.1,115.6c0-16.5,0-32.9,0.1-49.4c0-1.2,1-2.4,1.5-3.6c0.8,1.2,2.3,2.3,2.3,3.5c0.4,10.3,0.6,20.6,0.6,30.9
	c0.1,22.3,0,44.6,0,66.9c0,1,0.3,2.1,0,3c-0.5,1.2-1.4,2.2-2.2,3.3c-0.6-1.1-1.6-2.1-1.7-3.2c-0.4-10.1-0.7-20.3-0.8-30.4
	c-0.1-7,0-14,0-21C2418,115.6,2418,115.6,2418.1,115.6z"
        />
        <path
          d="M2345,494.7c-0.2-1.8-0.5-3.5-0.6-5.3c-0.3-10.6-0.4-21.3-0.8-31.9c-0.2-4,1.3-5.8,5.3-5.9c4.8-0.2,9.6-0.8,14.4-1.2
	c5-0.4,6.6,1.7,4.4,6.3c-3,6.3-6.5,12.3-9.8,18.5c-2.9,5.4-5.8,10.8-8.7,16.2c-0.7,1.3-1.7,2.5-2.6,3.7
	C2346.1,495,2345.5,494.9,2345,494.7z M2364.5,454.6c-6.3,0.5-11.2,0.8-15.9,1.2c0,8.7,0,16.9,0,25.1c0.4,0.1,0.9,0.2,1.3,0.3
	C2354.5,472.7,2359.2,464.2,2364.5,454.6z"
        />
        <path
          d="M2360.2,304.3c-1.4-37.1-1.6-74.1,0.2-111.2c0.6,0,1.3,0,1.9,0c0,37.1,0,74.1,0,111.2
	C2361.6,304.3,2360.9,304.3,2360.2,304.3z"
        />
        <path d="M2349.3,306c-3.2-5.8-4.1-109.1-1.4-114.5c0.5,0,0.9,0,1.4,0C2349.3,229.5,2349.3,267.5,2349.3,306z" />
        <path
          d="M2256.5,67.1c0,11.9,0,23.9-0.1,35.8c0,1.2-0.8,3.2-1.4,3.3c-1.2,0.2-2.9-0.4-3.8-1.3c-5.9-6.1-11.6-12.3-17.4-18.5
	c-2.8-3-2.8-5.8,0.7-8.4c6-4.4,11.9-9,18-13.4c1.1-0.8,2.8-1.3,4-1c0.7,0.1,0.9,2.2,1.4,3.3C2257.5,67,2257,67,2256.5,67.1z
	 M2234.7,82.1c6,6.4,11.4,12.1,16.7,17.7c0.5-0.2,1.1-0.4,1.6-0.6c0-9.9,0-19.7,0-30.3C2246.9,73.3,2241.1,77.4,2234.7,82.1z"
        />
        <path d="M2407.7,559.4c0-34.8,0-68.7,0-102.9C2411.8,461,2412.3,554.1,2407.7,559.4z" />
        <path d="M2420.7,455.1c4.1,4.2,3.7,99.6,0,105.2C2420.7,524.6,2420.7,489.9,2420.7,455.1z" />
        <path
          d="M2232.2,91.6c1.6,1.2,2.6,1.7,3.3,2.6c6.9,8.7,13.8,17.5,20.6,26.3c1,1.3,1.6,3.4,1.2,4.9c-0.2,0.8-2.8,1.5-4.2,1.4
	c-6-0.3-11.9-0.9-17.9-1.5c-3.8-0.4-5.4-2.5-5.3-6.4c0.3-7.1,0.3-14.3,0.6-21.5C2230.5,95.7,2231.4,93.9,2232.2,91.6z M2235,99.3
	c-0.6,0.2-1.3,0.4-1.9,0.6c0,6.7-0.1,13.4,0.1,20.1c0,0.8,1.4,2.1,2.3,2.2c5.7,0.7,11.3,1.1,17,1.6c0.2-0.5,0.5-1,0.7-1.5
	C2247.1,114.6,2241.1,106.9,2235,99.3z"
        />
        <path
          d="M2234.7,131.3c6.8,0,12.1,0.2,17.4-0.1c4.1-0.2,5.9,1.4,5.6,5.5c-0.7,7.8-1.2,15.6-1.9,23.3c-0.1,1-1.1,2.8-1.4,2.8
	c-1.3-0.3-2.9-0.7-3.7-1.7c-6.1-7.8-12.2-15.8-18.1-23.8c-0.9-1.2-1.7-3.5-1.1-4.4C2232.3,131.8,2234.4,131.4,2234.7,131.3z
	 M2236.8,134.9c-0.3,0.6-0.6,1.1-0.8,1.7c5.1,6.7,10.1,13.4,15.2,20.1c0.5-0.2,1-0.4,1.5-0.6c0.5-6.2,1.1-12.4,1.4-18.7
	c0-0.8-1.5-2.3-2.4-2.4C2246.7,134.8,2241.7,134.9,2236.8,134.9z"
        />
        <path
          d="M2260.1,331c7.7,1.8,14.7,3.4,21.6,5.2c3.4,0.9,4.4,3.6,4.5,6.9c0.2,9.6,0.4,19.3,0.8,28.9c0.1,4,0.7,7.9,0.8,11.9
	c0,1.1-0.9,2.2-1.3,3.2c-1-0.8-2.5-1.5-2.9-2.5c-0.6-1.7-0.5-3.6-0.6-5.4c-0.3-11.3-0.7-22.6-0.8-33.9c0-3.5-1.2-5.1-4.6-5.8
	c-5.8-1.2-11.7-2.7-17.4-4.3c-1.1-0.3-1.8-1.8-2.7-2.8C2258.5,331.9,2259.6,331.3,2260.1,331z"
        />
        <path
          d="M2247.9,182.2c-3.8-0.5-7.6-0.9-11.4-1.4c-3.2-0.4-4.7-2.1-4.7-5.3c0.1-6.8-0.1-13.6,0.1-20.5c0-1.5,1.1-3,1.6-4.4
	c1.5,0.8,3.5,1.2,4.5,2.4c6.2,7.8,12.2,15.8,18,23.9c0.9,1.2,0.9,3,1.3,4.5c-1.5,0.4-3,0.9-4.6,1c-1.6,0.1-3.3-0.3-5-0.5
	C2247.9,182.1,2247.9,182.1,2247.9,182.2z M2251.9,179.4c0.3-0.6,0.5-1.2,0.8-1.7c-5.4-7.2-10.9-14.3-16.3-21.5
	c-0.5,0.2-1,0.5-1.6,0.7c0,5.9-0.1,11.8,0.1,17.7c0,1.1,1.5,2.8,2.5,3C2242.2,178.5,2247.1,178.9,2251.9,179.4z"
        />
        <path
          d="M2183.4,164.1c8-6.2,14.9-11.7,21.9-17.1c0.2-0.1,0.9,0.4,2.1,0.9c-1.4,1.8-2.4,3.6-3.9,4.8c-5.1,4.5-10.4,8.8-15.5,13.2
	c-2.4,2.1-4.8,2.5-7.1,0.2c-6.7-6.5-13.2-13-19.8-19.7c-0.6-0.6-0.3-1.9-0.4-2.9c1.3,0.2,3.1,0,3.9,0.7c5.4,5.1,10.7,10.4,15.9,15.8
	C2181.8,161.3,2182.6,162.8,2183.4,164.1z"
        />
        <path
          d="M2288.5,181.2c-1.9-2-3.5-3-4.1-4.5c-2.6-6.1-5-12.3-7.4-18.4c-0.3-0.7,0.1-2.5,0.4-2.5c1.5-0.3,3.7-1,4.7-0.2
	c5.6,4.6,11.1,9.5,16.4,14.5c0.6,0.6,0.9,2.8,0.4,3.2C2295.7,175.8,2292.3,178.3,2288.5,181.2z M2294.4,172c-3.4-2.9-6-5.2-8.6-7.5
	c-0.3,0.2-0.5,0.4-0.8,0.6c1.2,3.3,2.4,6.6,3.7,10.4C2290.5,174.3,2291.9,173.5,2294.4,172z"
        />
        <path
          d="M2239.6,431.1c-3.4-0.3-7.8-0.2-12-1.2c-1.7-0.4-4.1-2.9-4.1-4.3c0.3-8.1,1.3-16.2,2.1-24.3c0.1-0.8,0.3-1.8,0.9-2.3
	c0.9-0.7,2.1-1,3.1-1.5c0.2,1,0.7,2.1,0.6,3.1c-0.8,7.2-1.6,14.5-2.6,21.7c-0.4,2.9,0.9,3.8,3.6,3.9c4.8,0.1,9.6,0.3,14.4,0.8
	c1.7,0.2,3.3,1.3,4.9,2c-1.7,0.7-3.4,1.7-5.3,2.1C2243.9,431.4,2242.2,431.1,2239.6,431.1z"
        />
        <path
          d="M2205.6,68.4c-1.6-0.6-3.3-0.9-4.7-1.7c-4.6-2.6-9.3-5.3-13.7-8.2c-2.7-1.8-4.8-1.6-7.2,0.3c-5.6,4.5-11.1,9-16.8,13.3
	c-1.2,0.9-3.1,1-4.7,1.4c0.7-1.5,1-3.4,2.2-4.4c5.8-4.8,11.7-9.3,17.6-13.9c3.4-2.6,6.9-2.7,10.6-0.3c4.9,3.2,9.9,6.1,14.8,9.2
	c1.1,0.7,2,1.8,2.9,2.7C2206.3,67.3,2206,67.9,2205.6,68.4z"
        />
        <path
          d="M2304.1,106.1c-1.5-2.1-2.9-4.3-4.4-6.4c-7.4-10-14.9-20-22.3-30c-0.9-1.2-2-2.3-2.6-3.6c-0.3-0.7,0.1-1.8,0.2-2.6
	c0.9,0.1,2,0,2.7,0.4c0.8,0.5,1.4,1.4,2,2.2c8.2,11.1,16.5,22.1,24.5,33.3c1.2,1.7,1.4,4,2.1,6.1
	C2305.6,105.6,2304.9,105.9,2304.1,106.1z"
        />
        <path
          d="M2272.8,370.2c0,4.2,0.1,8.3-0.1,12.5c-0.1,1.4-1.1,2.7-1.7,4.1c-0.8-1.4-2.2-2.8-2.2-4.2c-0.1-7.2,0.3-14.3,0.4-21.5
	c0.2-7.8,0-8-8-8c-2.3,0-4.7,0.1-7-0.1c-0.8-0.1-1.6-1.3-2.3-1.9c0.8-0.7,1.5-1.8,2.3-1.9c4.5-0.2,9-0.3,13.5,0.1
	c3.4,0.3,5.4,2.4,5.5,6c0.1,5,0,10,0,15C2273.1,370.2,2273,370.2,2272.8,370.2z"
        />
        <path
          d="M2308,124.2c-0.3,0.5-0.7,1.5-1,1.5c-1-0.1-2.4-0.3-3-1c-2.4-2.8-4.5-5.8-6.7-8.7c-6.9-8.8-13.8-17.5-20.5-26.4
	c-0.9-1.1-1-2.9-1.4-4.3c1.5,0.6,3.6,0.7,4.4,1.8c9.1,11.5,18,23.1,26.9,34.7C2307.2,122.6,2307.5,123.4,2308,124.2z"
        />
        <path
          d="M2276.9,107.9c1.1,1,2.3,1.6,3.1,2.6c8.2,10.7,16.4,21.3,24.4,32.1c0.7,1,0.5,2.6,0.7,4c-1.3-0.6-3.2-0.7-3.9-1.7
	c-8.3-10.6-16.4-21.3-24.6-32c-0.7-1-1-2.3-1.2-3.5C2275.3,109,2276.2,108.5,2276.9,107.9z"
        />
        <path
          d="M2206.3,164.5c-2.1,1.4-4.3,2.7-6.4,4.1c-3.9,2.5-7.6,5.4-11.7,7.6c-1.9,1-5.4,1.8-6.7,0.8c-6.3-5-12-10.6-18-16.1
	c0.3-0.7,0.7-1.3,1-2c1.6,0.6,3.5,0.9,4.7,1.9c4.2,3.6,8.1,7.3,12.1,11.2c1.9,1.8,3.4,1.9,5.6,0.4c4.5-3.1,9.2-6,13.9-8.7
	c1.4-0.8,3.2-0.7,4.9-1C2206,163.3,2206.1,163.9,2206.3,164.5z"
        />
        <path
          d="M2278,554.5c-0.6,0.6-1.4,1.9-1.7,1.8c-1-0.3-2-1.1-2.5-1.9c-4-6.8-7.9-13.8-12-20.7c-2.9-4.8-6.1-9.5-8.9-14.4
	c-0.5-0.9-0.1-2.3-0.1-3.5c1.2,0.4,3,0.5,3.5,1.4c6.9,11.3,13.7,22.7,20.5,34.1C2277.3,552.2,2277.5,553.2,2278,554.5z"
        />
        <path
          d="M2308.2,161.4c-0.5,0.6-1.1,1.7-1.4,1.6c-1.2-0.3-2.5-0.8-3.3-1.6c-5.2-4.9-10.2-10-15.4-14.9c-3.5-3.3-7.1-6.5-10.6-9.9
	c-0.6-0.6-0.5-2-0.7-3c1.1,0,2.5-0.4,3.1,0.1c9.1,8.5,18.1,17.1,27.2,25.7C2307.5,159.8,2307.7,160.5,2308.2,161.4z"
        />
        <path
          d="M2309.1,450.2c0.9,0.7,1.8,1.2,2.4,1.9c7.2,8.7,14.3,17.4,21.4,26.2c0.7,0.9,0.4,2.6,0.5,4c-1.2-0.5-2.8-0.7-3.6-1.5
	c-7.3-8.6-14.4-17.4-21.5-26.2c-0.7-0.9-0.8-2.3-0.9-3.5C2307.5,450.9,2308.5,450.5,2309.1,450.2z"
        />
        <path
          d="M2165.8,80.4c0.4,2.1,1.2,3.7,0.8,4.7c-1,2.4-2.4,4.8-4,6.9c-2.1,2.7-1.8,5.1,0.2,7.7c2.7,3.4,5.4,6.8,7.8,10.4
	c0.6,1,0.2,2.6,0.3,4c-1.2-0.5-2.7-0.8-3.6-1.7c-1.8-1.8-3.2-3.8-4.7-5.8c-7.8-10.2-7.7-13.7,0.6-23.5
	C2163.6,82.3,2164.2,81.8,2165.8,80.4z"
        />
        <path
          d="M2279.8,451.9c-0.6,1-1.1,1.9-1.6,2.6c-6.3,8.4-12.5,16.8-18.9,25.2c-0.6,0.8-2,1.1-3.1,1.6c-0.1-1.3-0.7-3-0.1-3.8
	c6.2-8.6,12.6-17.1,19.1-25.6c0.7-0.9,2.1-1.2,3.3-1.5C2278.8,450.3,2279.3,451.3,2279.8,451.9z"
        />
        <path
          d="M2382.7,638.1c-0.5,1-0.8,1.8-1.3,2.5c-3.9,5.2-7.7,10.5-11.7,15.5c-0.6,0.8-2.1,0.9-3.2,1.3c-0.2-1.3-1-3.1-0.4-3.9
	c4-5.7,8.2-11.3,12.5-16.8c0.5-0.6,1.8-1,2.6-0.8C2381.9,636.2,2382.3,637.4,2382.7,638.1z"
        />
        <path
          d="M2384.1,261.8c-0.4,0.9-0.6,1.5-0.9,2.1c-3.9,6.4-7.9,12.7-12,19c-0.5,0.8-2.1,0.8-3.2,1.2c-0.2-1-0.9-2.4-0.4-3.1
	c4-6.7,8.2-13.3,12.5-19.8c0.4-0.6,1.7-1,2.5-1C2383.2,260.2,2383.6,261.3,2384.1,261.8z"
        />
        <path
          d="M2381.2,216.3c0.1,1.4,0.9,3.1,0.3,4.1c-3.6,6.2-7.4,12.2-11.3,18.1c-0.5,0.8-2.2,0.8-3.3,1.2c-0.2-1-0.9-2.4-0.5-3.1
	c4.1-6.9,8.4-13.8,12.7-20.6C2379.8,216,2380.5,216.1,2381.2,216.3z"
        />
        <path
          d="M2362.8,630.9c-0.6,1-1,1.8-1.7,2.6c-3.8,4.8-7.6,9.6-11.6,14.3c-0.7,0.8-2.4,0.9-3.6,1.3c0-1.3-0.6-3.1,0-4
	c3.8-5.2,7.8-10.3,12-15.2c0.6-0.8,2.3-0.9,3.4-1C2361.9,628.9,2362.3,630.1,2362.8,630.9z"
        />
        <path d="M2228.9,60.4c0.8-0.7,2.4-3.2,4.5-3.8c3.5-0.9,6.5,2.7,6.6,6.9c0.1,3.7-2.2,4.9-5.3,5.2C2231,69.1,2229,66.7,2228.9,60.4z" />
        <path d="M2298.8,70c0.9-0.7,2.5-3.1,4.6-3.6c3.4-0.7,6.6,3.3,6.5,7.2c-0.1,3.7-2.6,4.8-5.6,4.9C2300.5,78.7,2298.7,76.4,2298.8,70z" />
        <path
          d="M2368.5,260.5c-0.5-1.3-1.7-2.9-1.3-3.6c3.6-6.3,7.5-12.6,11.4-18.7c0.4-0.6,2-0.9,2.5-0.6c0.6,0.4,1.2,2,0.9,2.5
	c-3.8,6.5-7.7,12.8-11.6,19.2C2370.2,259.6,2369.6,259.8,2368.5,260.5z"
        />
        <path
          d="M2367.6,220c-0.5-1.8-1.4-3.4-1-4.2c3.4-6,7.1-11.9,10.8-17.8c0.5-0.7,1.9-0.8,2.9-1.2c0.2,1,1,2.3,0.6,3
	c-3.5,6.2-7.2,12.2-10.9,18.3C2369.7,218.7,2369,219,2367.6,220z"
        />
        <path
          d="M2388.5,517.6c0.8,0.7,1.9,1.3,2.5,2.1c3.8,5.3,7.5,10.6,11,16c0.7,1,0.3,2.7,0.5,4c-1.3-0.6-3.2-0.9-3.9-1.9
	c-3.9-5.2-7.6-10.6-11.1-16c-0.6-1-0.4-2.6-0.5-3.9C2387.4,517.8,2388,517.7,2388.5,517.6z"
        />
        <path
          d="M2380.9,587.8c-0.3,1.5-0.1,3.3-0.9,4.5c-3.4,4.9-7.1,9.6-10.8,14.2c-0.6,0.8-2.2,0.8-3.3,1.2c-0.1-1.2-0.8-2.9-0.2-3.7
	c4.2-5.8,8.6-11.5,12.9-17.2C2379.3,587.1,2380.1,587.5,2380.9,587.8z"
        />
        <path
          d="M2403.7,499.9c-1.8-1-3.3-1.3-4-2.3c-3.8-5.2-7.4-10.6-10.8-16c-0.5-0.8,0-2.3,0.1-3.4c1,0.3,2.5,0.2,3,0.9
	c4.1,5.9,8.1,12,12.1,18.1C2404.2,497.5,2403.9,498.1,2403.7,499.9z"
        />
        <path
          d="M2392,458.7c0.5,0.9,1,1.9,1.7,2.9c3.2,4.9,6.5,9.7,9.5,14.7c0.6,0.9-0.1,2.5-0.1,3.8c-1.2-0.6-2.8-0.8-3.5-1.8
	c-3.4-4.8-6.5-9.6-9.6-14.6c-0.6-1-0.5-2.4-0.5-3.6C2389.3,459.9,2390.4,459.6,2392,458.7z"
        />
        <path
          d="M2403.3,516.1c-0.3,0.4-0.7,1.3-1.3,1.5c-0.6,0.2-1.9,0-2.2-0.5c-3.9-5.7-7.8-11.4-11.5-17.3c-0.4-0.6,0-2.2,0.6-2.5
	c0.6-0.4,2.3-0.3,2.6,0.2c4,5.7,7.8,11.5,11.6,17.2C2403.3,515.1,2403.2,515.4,2403.3,516.1z"
        />
        <path
          d="M2199.4,475.1c-0.5,1.1-0.7,2.1-1.2,3c-2.5,4.5-4.9,9-7.7,13.3c-0.6,0.9-2.2,1.2-3.3,1.8c-0.2-1.2-0.9-2.6-0.5-3.5
	c2.6-5.2,5.4-10.2,8.4-15.2c0.4-0.7,1.8-1.1,2.8-1.2C2198.4,473.3,2198.9,474.5,2199.4,475.1z"
        />
        <path
          d="M2368.7,305.7c-0.3-1.8-1.1-3.5-0.5-4.3c3.1-4.5,6.4-8.8,9.8-13c0.5-0.7,1.9-0.6,2.9-0.9c0.1,1.2,0.8,2.8,0.2,3.5
	c-3.1,4.5-6.5,8.8-9.8,13.1C2371,304.5,2370.3,304.7,2368.7,305.7z"
        />
        <path
          d="M2335.9,460.2c-1.8,1.3-3.5,3.5-5.5,3.8c-3.1,0.6-5.8-1.5-5.3-4.8c0.4-2.2,2.8-4.1,4.3-6.1c2.1,1.6,4.3,3.2,6.4,4.8
	C2335.9,458.7,2335.9,459.4,2335.9,460.2z"
        />
        <path
          d="M2353,598.3c-0.3-1.7-1.3-3.7-0.7-4.5c3.1-4.1,6.5-8,10-11.8c0.4-0.5,2.1-0.4,2.6,0c0.5,0.5,0.9,2.1,0.5,2.6
	c-3.4,4.3-6.9,8.5-10.4,12.7C2354.8,597.6,2354.4,597.7,2353,598.3z"
        />
        <path
          d="M2347.4,690.8c0-2.2-0.6-4.1,0.1-5c3-3.7,6.4-7.1,9.8-10.5c0.5-0.5,2.4-0.5,2.9-0.1c0.6,0.6,0.8,2.4,0.3,3
	c-3.2,3.8-6.6,7.3-10,10.9C2349.9,689.6,2349.2,689.8,2347.4,690.8z"
        />
        <path
          d="M2257.7,464.8c-2.8-2.8-4.7-4.7-6.6-6.6c2-1.6,3.8-4.2,6.1-4.6c2.9-0.6,5.7,1.6,5.3,4.9
	C2262.3,460.3,2260,461.9,2257.7,464.8z"
        />
        <path
          d="M2253.1,552.9c-2.8-2.7-5.8-4.3-6.1-6.3c-0.6-3.3,2.4-4.8,5.5-4.8c3.2-0.1,5.9,1.4,5.4,4.9
	C2257.6,548.6,2255.3,550.1,2253.1,552.9z"
        />
        <path
          d="M2323.7,549.1c-1.3-2-3.8-4.1-3.7-6.1c0-1.8,2.6-4.8,4.4-5.1c3.4-0.5,5.4,2.3,5.5,5.6C2330,546.5,2328.3,548.4,2323.7,549.1
	z"
        />
        <path
          d="M2379.5,689.4c-3.6,3.6-7.1,7.1-10.7,10.6c-0.6-1.2-2-2.9-1.7-3.4c2.4-3.3,5.1-6.4,7.9-9.5c0.5-0.5,1.8-0.6,2.6-0.4
	C2378.2,686.9,2378.5,687.9,2379.5,689.4z"
        />
        <path
          d="M2339.7,255.8c-0.8,1.4-1.1,2-1.5,2.5c-2.5,2.9-5,5.8-7.6,8.5c-0.6,0.7-2,0.6-3,0.9c-0.2-1-1-2.5-0.6-3.1
	c2.8-3.4,5.8-6.7,8.9-9.8C2336.4,254.5,2338.1,255.4,2339.7,255.8z"
        />
        <path
          d="M2337.8,292c0.3,1.3,1.3,3,0.8,3.7c-2.1,3.3-4.6,6.5-7.2,9.5c-0.5,0.6-1.8,0.6-2.7,0.6c-0.3,0-1-1.3-0.9-1.5
	c2.6-4.3,5.3-8.5,7.9-12.7C2336.5,291.8,2337.1,291.9,2337.8,292z"
        />
        <path
          d="M2340.3,222.3c-3.6,2.9-7.1,5.7-10.7,8.6c-0.8-1.1-2.4-2.8-2.2-3.1c2.6-3.2,5.3-6.2,8.3-9c0.4-0.4,2.3,0.7,3.5,1.1
	C2339.5,220.8,2339.9,221.6,2340.3,222.3z"
        />
        <path
          d="M2272.8,586.2c-3,1.8-4.9,3.9-6.5,3.7c-1.6-0.2-3-2.6-4.5-4.1c1.6-1.5,3.1-4,4.9-4.3
	C2268.1,581.3,2269.9,583.9,2272.8,586.2z"
        />
        <path
          d="M2223.8,497.9c-1.9,1.1-3.7,3-5.7,3.2c-1.2,0.1-2.7-2.4-4-3.7c1.7-1.5,3.1-3.7,5-4.3c1.1-0.4,3,1.8,4.6,2.8
	C2223.8,496.5,2223.8,497.2,2223.8,497.9z"
        />
        <path d="M2269.8,627.8c-2.1-3.1-4.3-4.9-4.3-6.8c0-1.3,3-3.6,4.6-3.6c1.3,0,3.4,2.8,3.6,4.5C2273.8,623.3,2271.7,625,2269.8,627.8z" />
        <path
          d="M2186.5,502.3c3.9,4.3,6.7,7.3,9.5,10.4c-1,0.7-2.6,2.1-2.9,1.9c-2.8-2.3-5.4-4.8-7.8-7.5
	C2184.9,506.7,2185.8,505,2186.5,502.3z"
        />
        <path
          d="M2340.6,192.9c-3.4,2.1-6.1,3.8-8.7,5.5c-0.1,0.1-0.4,0-0.5-0.1c-0.4-0.9-1.4-2.5-1.2-2.7c2.3-2,4.8-3.8,7.3-5.5
	C2337.7,190.1,2338.9,191.4,2340.6,192.9z"
        />
        <path d="M2222.7,461c-2.4,1.6-4,3.5-5,3.2c-1.5-0.4-2.5-2.3-3.8-3.6c1.3-1.2,2.5-3.3,3.9-3.4C2219,457.2,2220.4,459.2,2222.7,461z" />
        <path
          d="M2272.2,694c-1.4,1.6-2.5,2.8-3.5,3.9c-0.8-1.4-2.2-2.8-2.2-4.3c-0.1-1.1,1.5-2.4,2.3-3.5
	C2269.7,691.2,2270.7,692.3,2272.2,694z"
        />
        <path
          d="M2311.4,242.8c1.5,2.2,3,3.3,2.8,4.2c-0.3,1.6-1.6,3-2.4,4.4c-0.9-1.2-2.4-2.3-2.6-3.6
	C2309,246.7,2310.2,245.3,2311.4,242.8z"
        />
        <path
          d="M2271,660.9c-2.5,1.3-3.8,2.5-4.7,2.2c-1.2-0.3-2.2-1.7-3.3-2.6c1-0.8,2-2.3,3.1-2.3C2267.3,658.1,2268.5,659.4,2271,660.9z
	"
        />
        <path
          d="M2310.9,214.1c-1.1-1.9-2.2-3.1-2.4-4.5c-0.1-0.6,1.7-1.4,2.6-2.1c0.8,1.3,1.9,2.4,2.3,3.8
	C2313.5,211.8,2312.2,212.7,2310.9,214.1z"
        />
        <path
          class="st0"
          d="M2243.3,700.1c-28.7,0.6-56.2,1.1-85,1.7c14.3-19.5,28.2-38.5,42.9-58.5C2215.3,662.5,2229,680.9,2243.3,700.1z
	 M2182.9,698.6c0.1-0.3,0.1-0.6,0.2-1c11.4-0.7,22.8-1.5,34.3-2.2c5.3-0.3,6.7-2.7,3.6-7c-5.8-8.1-12-15.8-18.1-23.8
	c-2.6-3.4-4.6-2.7-6.8,0.6c-5.6,8.6-11.5,17.1-17,25.7c-0.9,1.5-1.7,4-1,5.3C2178.6,697.6,2181.2,697.9,2182.9,698.6z"
        />
        <path
          class="st0"
          d="M2237.5,578.8c-12,15.2-23.7,30.1-35.1,44.7c-16.3-14.9-32.5-29.7-48.7-44.5
	C2154.8,577.9,2227.4,576.9,2237.5,578.8z M2196.4,584.1c-5.7,0-11.3-0.1-17,0c-3.6,0.1-4.7,1.9-2.5,4.8
	c5.6,7.2,11.4,14.3,17.2,21.4c1.9,2.4,4.3,2.6,6.3,0.1c5.4-6.8,10.8-13.5,15.9-20.5c2.5-3.4,1.2-5.7-3-5.8
	C2207.8,584,2202.1,584.1,2196.4,584.1z"
        />
        <path
          class="st0"
          d="M2153.7,600.7c9.7,9.3,19.4,18.6,28.9,28.1c0.9,0.9,1.1,3.6,0.4,4.7c-8.6,13-17.4,25.9-26.1,38.9
	c-0.1,0.2-0.6,0.2-1.3,0.5c-1.1-23.9-2.1-47.6-3.2-71.3C2152.7,601.2,2153.2,600.9,2153.7,600.7z M2157.3,621.8
	c0.6,6.9,1.2,13.7,1.8,20.4c0,0.5-0.1,1.1,0.2,1.5c0.8,1.4,1.4,3.4,2.6,3.8c1.1,0.4,3-0.7,4.3-1.5c1.1-0.7,1.8-1.9,2.6-3
	c9-11,9-10.9-2-20C2163.9,620.7,2161.2,619.2,2157.3,621.8z"
        />
        <path
          d="M2164.6,462.7c11,0.1,11,0.1,7.9,9.9c-1.3,4.1-2.7,8.2-4.4,12.2c-0.5,1.2-1.8,2.4-2.9,2.8c-0.7,0.2-2.3-1.2-2.7-2.2
	c-2.3-6.2-4.5-12.5-6.6-18.7c-1-3.1,0.5-4.4,3.5-4.2C2161.5,462.5,2163.5,462.6,2164.6,462.7z M2164.5,479.2
	c0.5,0.1,0.9,0.1,1.4,0.2c1.3-3.9,2.6-7.8,4.1-12.2c-3.6,0-6.2,0-9.6,0C2161.9,471.4,2163.2,475.3,2164.5,479.2z"
        />
        <path
          class="st0"
          d="M2333.6,652.1c-3.6,2.6-6.7,5.1-10,7.1c-0.8,0.5-2.8,0.1-3.6-0.6c-2.1-1.9-3.8-4.1-5.6-6.1
	c0.1-0.5,0.1-0.9,0.3-1.2C2321.3,641.4,2322.8,641.4,2333.6,652.1z"
        />
        <path
          class="st0"
          d="M2331.5,604.6c-3.2,3-6,5.7-9.2,8.7c-2.9-3.8-5.4-7.1-8.4-11c3-2.8,5.8-5.5,9-8.6
	C2325.8,597.4,2328.5,600.9,2331.5,604.6z"
        />
        <path
          class="st0"
          d="M2310.8,674.9c3.3-2.5,6.2-4.7,9.2-7c6.5,7.5,6.8,8.7,2.6,12.2C2318.5,683.6,2317.8,683.3,2310.8,674.9z"
        />
        <path
          class="st0"
          d="M2319.3,635.8c-2.1-1.2-4.4-2.5-7.4-4.3c3.1-3.4,5.9-6.5,8.7-9.6C2326.9,628.5,2326.7,630.1,2319.3,635.8z"
        />
        <path
          class="st0"
          d="M2293.2,452.8c6.1,8.2,11.7,15.8,17.3,23.3c6,8,11.9,16,18,24c1.5,2,2.5,3.5,0.6,6.1
	c-11.9,16-23.7,32.1-35.5,48.2c-0.5,0.6-1,1.2-2.1,2.3c-1.1-2-1.8-3.6-2.7-5.1c-8.9-15.9-17.7-31.9-26.8-47.8
	c-1.5-2.6-2.1-4.3-0.1-7c9.4-12.9,18.5-26,27.7-39C2290.7,456.4,2291.7,454.9,2293.2,452.8z M2296.6,474.4c-2,1.3-3,1.6-3.6,2.3
	c-6.2,6.9-12.5,13.7-18.4,20.9c-0.9,1.1-0.7,3.9,0,5.3c4.7,9.2,9.6,18.2,14.6,27.2c2.8,4.9,4.6,4.9,7.8,0.2
	c5.7-8.2,11.2-16.5,17-24.6c1.9-2.7,1.6-4.8-0.3-7.2c-5.3-7-10.5-14.1-15.7-21.2C2297.4,476.4,2297,475.3,2296.6,474.4z"
        />
        <path
          class="st0"
          d="M2377.1,463c0.6,29.1,1.2,57.9,1.8,87.8c-1.2-1.5-1.9-2.1-2.3-2.9c-8-14.2-16-28.4-23.8-42.6
	c-0.6-1.1-0.8-3-0.2-4C2360.5,488.7,2368.6,476.2,2377.1,463z M2375,506.1c-0.2-4.4-4.3-7.6-9.4-7.3c-4.1,0.2-5.9,2.6-5.9,6.5
	c0.1,4.4,4.1,7.7,8.8,7.5C2372.5,512.5,2375.2,509.8,2375,506.1z"
        />
        <path
          class="st0"
          d="M2243.4,598.6c1.2,23.9,2.4,47.3,3.5,70.6c-0.5,0.1-0.9,0.2-1.4,0.3c-3.3-4.6-6.5-9.3-10-13.9
	c-4.6-5.9-9.5-11.6-13.9-17.7c-1.2-1.6-1.7-5-0.8-6.5c6.5-10.8,13.5-21.3,20.4-32C2241.5,599.3,2241.9,599.2,2243.4,598.6z
	 M2241,639.9c-0.4-4.5-0.7-7.8-1.1-11.1c-0.2-2.3-0.4-4.6-0.9-6.9c-0.2-1-0.9-2.7-1.4-2.7c-1.1-0.1-2.6,0.4-3.4,1.3
	c-1.7,1.8-3.1,3.9-4.6,5.9c-5.3,7-5.2,8,1.1,13.9c0.8,0.8,1.7,1.7,2.7,2.2c1.7,0.8,4.1,2.3,5.1,1.7
	C2240,643.2,2240.5,640.7,2241,639.9z"
        />
        <path
          class="st0"
          d="M2348.5,523.1c5.7,11,10.7,20.8,16.3,31.6c-6.4-0.6-11.6-1.1-16.8-1.8c-0.8-0.1-2.1-1.8-2-2.7
	C2346.7,541.7,2347.6,533.2,2348.5,523.1z"
        />
        <path
          class="st0"
          d="M2364.5,454.6c-5.3,9.6-10,18.1-14.6,26.5c-0.4-0.1-0.9-0.2-1.3-0.3c0-8.2,0-16.4,0-25.1
	C2353.2,455.5,2358.1,455.1,2364.5,454.6z"
        />
        <path
          d="M2182.9,698.6c-1.7-0.7-4.3-1-4.9-2.3c-0.7-1.3,0.1-3.8,1-5.3c5.6-8.7,11.4-17.1,17-25.7c2.2-3.3,4.2-4,6.8-0.6
	c6,7.9,12.2,15.7,18.1,23.8c3.1,4.3,1.7,6.7-3.6,7c-11.4,0.7-22.8,1.4-34.3,2.2C2183,697.9,2182.9,698.3,2182.9,698.6z
	 M2216.5,690.2c-5.8-7.6-10.9-14.3-16.5-21.8c-5.7,8.4-10.7,15.9-16.2,24.2C2194.9,691.8,2205,691,2216.5,690.2z"
        />
        <path
          d="M2196.4,584.1c5.7,0,11.3-0.2,17,0c4.3,0.2,5.5,2.4,3,5.8c-5.1,7-10.5,13.7-15.9,20.5c-2,2.5-4.4,2.2-6.3-0.1
	c-5.8-7.1-11.6-14.2-17.2-21.4c-2.2-2.9-1.1-4.7,2.5-4.8C2185.1,584.1,2190.8,584.1,2196.4,584.1z M2183.6,589.6
	c5.1,6.2,9.3,11.3,14,17.1c4.6-5.9,8.7-11.3,13.2-17.1C2201.5,589.6,2193.2,589.6,2183.6,589.6z"
        />
        <path
          d="M2157.3,621.8c3.9-2.5,6.6-1,9.4,1.2c11,9,11.1,9,2,20c-0.8,1-1.6,2.3-2.6,3c-1.3,0.8-3.1,1.9-4.3,1.5
	c-1.2-0.4-1.8-2.4-2.6-3.8c-0.2-0.4-0.1-1-0.2-1.5C2158.5,635.4,2157.9,628.7,2157.3,621.8z M2170.8,632.7c-2.8-2.1-4.9-3.8-8.1-6.2
	c0.4,5.2,0.8,9.1,1.2,14.4C2166.7,637.5,2168.5,635.4,2170.8,632.7z"
        />
        <path
          class="st0"
          d="M2164.5,479.2c-1.3-3.9-2.6-7.7-4.1-12c3.3,0,5.9,0,9.6,0c-1.5,4.4-2.8,8.3-4.1,12.2
	C2165.4,479.3,2164.9,479.2,2164.5,479.2z"
        />
        <path
          d="M2296.6,474.4c0.5,0.9,0.9,2.1,1.5,3c5.2,7.1,10.4,14.2,15.7,21.2c1.8,2.4,2.1,4.5,0.3,7.2c-5.8,8.1-11.3,16.4-17,24.6
	c-3.3,4.7-5.1,4.7-7.8-0.2c-5-9-9.9-18-14.6-27.2c-0.7-1.5-0.9-4.3,0-5.3c5.9-7.1,12.2-14,18.4-20.9
	C2293.6,476,2294.6,475.6,2296.6,474.4z M2292.3,529c1.1-0.5,1.7-0.6,1.9-0.9c5.5-7.9,11.2-15.7,16.4-23.8c0.7-1.1,0-3.8-1-5.2
	c-3.8-5.5-8.1-10.6-11.8-16.1c-2.2-3.3-4.2-3.4-6.6-0.7c-4.4,4.9-9,9.8-13,15c-1,1.3-0.8,4.4,0.1,6.1c3,6.1,6.4,12,9.8,17.9
	C2289.4,524,2290.9,526.5,2292.3,529z"
        />
        <path
          d="M2393.1,417.6c-6.8,0-8-0.8-8.7-7.4c-0.8-8.1-1.2-16.2-1.5-24.4c-0.1-1.5,1.2-3,1.9-4.5c1.2,1.2,2.8,2.2,3.4,3.6
	c3.5,8,6.6,16.3,10.3,24.3C2401.9,416.6,2401.5,417.6,2393.1,417.6z M2388.4,395.5c-0.5,0.1-1.1,0.2-1.6,0.3c0.3,4.3,0.7,8.7,1,13
	c0.4,5.7,3,6.9,8.5,3.6C2393.7,406.8,2391,401.1,2388.4,395.5z"
        />
        <path
          d="M2402.5,254.5c-4.1,0.1-7-2.6-7.2-6.6c-0.2-4.6,4-8.8,8.9-8.8c3.8,0,6.5,2.7,6.6,6.6C2410.9,250.3,2407,254.4,2402.5,254.5z
	 M2408.2,246.2c-2.6-1.1-4.1-2.3-4.9-2c-1.4,0.6-2.5,2.1-3.8,3.2c1,0.9,2.2,2.6,2.9,2.4C2404.1,249.3,2405.5,248,2408.2,246.2z"
        />
        <path
          d="M2375,506.1c0.1,3.7-2.6,6.4-6.5,6.6c-4.7,0.3-8.7-3.1-8.8-7.5c-0.1-3.9,1.8-6.2,5.9-6.5
	C2370.7,498.5,2374.9,501.7,2375,506.1z M2367.2,509.2c0.8,0.4,1.5,0.8,2.3,1.2c0.4-1.3,1.5-2.8,1.1-3.7c-0.6-1.4-2-2.7-3.4-3.3
	c-0.6-0.3-2.1,1.3-3.1,2.1C2365,506.6,2366.1,507.9,2367.2,509.2z"
        />
        <path
          d="M2241,639.9c-0.4,0.8-1,3.3-2.4,4.2c-1,0.6-3.4-0.9-5.1-1.7c-1-0.5-1.8-1.4-2.7-2.2c-6.4-5.9-6.5-6.9-1.1-13.9
	c1.5-2,2.9-4.1,4.6-5.9c0.8-0.8,2.3-1.3,3.4-1.3c0.6,0,1.2,1.7,1.4,2.7c0.4,2.3,0.6,4.6,0.9,6.9
	C2240.3,632.1,2240.5,635.4,2241,639.9z M2234.8,628.3c-4.7,4.9-4.6,5.6,1,8C2235.4,633.8,2235.1,631.4,2234.8,628.3z"
        />
        <path
          d="M2323.6,385.9c0.7-2.8,1.3-5.6,2-8.4c2.8,1.2,5.8,2.1,8.2,3.8c5.8,4.3,5.8,5.7,1.3,11.2c-1.6,1.9-3.2,3.9-4.9,5.6
	c-2.6,2.6-4.3,2-4.9-1.7c-0.2-1.5-0.6-3-0.7-4.4c-0.2-2-0.2-4-0.3-6C2324,386,2323.8,385.9,2323.6,385.9z M2328.3,392.9
	c0.5,0.2,1,0.5,1.6,0.7c1.5-2.2,4.1-4.4,4.2-6.6c0.1-1.6-3.3-3.3-5.7-5.6C2328.3,386.2,2328.3,389.5,2328.3,392.9z"
        />
        <path
          d="M2365.5,72.6c4.5,0,9-0.1,13.5,0c4.2,0.1,5.1,1.5,2.7,4.8c-4.6,6.5-9.5,12.9-14.3,19.2c-1.8,2.3-3.8,2.5-5.8,0.1
	c-4.7-6-9.6-11.9-14.1-18.1c-2.3-3.1-1.2-5.3,3-5.5c5-0.3,10-0.1,15-0.1C2365.5,72.9,2365.5,72.8,2365.5,72.6z M2352.2,78
	c4.4,5.6,8.1,10.3,12.2,15.5c4-5.4,7.5-10.1,11.6-15.5C2367.8,78,2360.7,78,2352.2,78z"
        />
        <path
          d="M2332.7,330.5c5.3-0.1,10.6-0.3,15.9-0.1c0.8,0,2,2.2,2,3.4c0.3,4.8,0.3,9.6,0.1,14.4c0,1.1-0.9,3.2-1.5,3.2
	c-1.2,0.1-2.9-0.5-3.7-1.5c-4.5-5.7-8.8-11.5-13.2-17.3C2332.4,331.9,2332.6,331.2,2332.7,330.5z M2345.9,344.9
	c0.7-0.3,1.5-0.5,2.2-0.8c-0.5-3.4-0.4-7.2-1.9-10c-0.6-1.2-4.8-0.5-7.4-0.7c-0.1,0.5-0.3,1-0.4,1.4
	C2340.9,338.2,2343.4,341.6,2345.9,344.9z"
        />
        <path
          d="M2377.8,168.4c-9.8,0-19.6,0.1-29.4,0c-4.7-0.1-5.9-2.1-3.2-5.9c4.9-6.9,10.2-13.5,15.3-20.3c2.4-3.1,4.2-2.3,6.1,0.5
	c4.8,6.8,9.8,13.4,14.6,20.2c1,1.5,2.2,3.9,1.7,5.2c-0.4,1.2-3.2,1.5-4.9,2.1C2378,169.7,2377.9,169,2377.8,168.4z M2377.9,165.5
	c-5.3-7.2-9.7-13.4-14.7-20.2c-4.8,6.4-9.2,12.1-14.2,18.7C2359.2,164.5,2367.9,165,2377.9,165.5z"
        />
        <path
          d="M2326.8,126.2c0.5-5.6,0.7-9.5,1.2-13.5c0.1-1,0.7-2.5,1.4-2.9c0.7-0.3,2.2,0.4,2.9,1.1c2.3,2.6,4.8,5.1,6.5,8.1
	c0.8,1.3,0.5,3.7-0.2,5.3c-0.7,1.6-2.2,2.9-3.7,3.9c-1.9,1.2-4.2,1.9-6.4,2.8C2327.8,128.9,2327.1,126.9,2326.8,126.2z"
        />
        <path
          d="M2400.1,110c-0.6,7.1-0.9,13.6-1.8,19.9c-0.4,3.5-2.7,4-5.3,1.7c-1.2-1.1-2.4-2.3-3.4-3.7c-1.9-2.8-5.9-7.1-5.1-8.2
	c2.5-3.8,6.6-6.8,10.4-9.6C2395.9,109.4,2398,110,2400.1,110z M2393.6,125.8c0.4-0.2,0.8-0.4,1.2-0.6c0-3.1,0-6.2,0-10.2
	c-2.4,2-4.1,3.4-6.3,5.1C2390.4,122.3,2392,124.1,2393.6,125.8z"
        />
        <path
          class="st0"
          d="M2216.5,690.2c-11.4,0.8-21.5,1.6-32.7,2.4c5.5-8.3,10.6-15.7,16.2-24.2
	C2205.6,675.8,2210.7,682.5,2216.5,690.2z"
        />
        <path
          class="st0"
          d="M2183.6,589.6c9.6,0,18,0,27.3,0c-4.5,5.8-8.7,11.2-13.2,17.1C2192.9,600.9,2188.6,595.7,2183.6,589.6z"
        />
        <path
          class="st0"
          d="M2170.8,632.7c-2.3,2.7-4,4.9-6.8,8.2c-0.5-5.3-0.8-9.2-1.2-14.4C2165.9,628.9,2168,630.6,2170.8,632.7z"
        />
        <path
          class="st0"
          d="M2292.3,529c-1.5-2.5-2.9-5-4.3-7.6c-3.3-6-6.8-11.8-9.8-17.9c-0.8-1.7-1.1-4.8-0.1-6.1c4-5.3,8.6-10.1,13-15
	c2.5-2.7,4.4-2.6,6.6,0.7c3.7,5.5,8,10.6,11.8,16.1c0.9,1.4,1.7,4.1,1,5.2c-5.2,8.1-10.8,15.9-16.4,23.8
	C2294,528.4,2293.4,528.5,2292.3,529z M2303.4,505.8c-0.7-2-1-2.9-1.5-3.8c-5.3-10.2-8.4-10.5-15-1.3c-1,1.4-1.5,4.4-0.7,5.7
	c1.9,3.3,4.7,6.2,7.2,9.2c0.1,0.1,0.8,0.1,0.9-0.1C2297.5,512.2,2300.6,508.9,2303.4,505.8z"
        />
        <path
          class="st0"
          d="M2367.2,509.2c-1.1-1.3-2.2-2.5-3.2-3.8c1.1-0.7,2.5-2.3,3.1-2.1c1.4,0.6,2.8,1.9,3.4,3.3
	c0.4,0.9-0.7,2.4-1.1,3.7C2368.7,510,2367.9,509.6,2367.2,509.2z"
        />
        <path
          class="st0"
          d="M2234.8,628.3c0.4,3.1,0.7,5.4,1,8C2230.1,634,2230,633.3,2234.8,628.3z"
        />
        <path
          d="M2303.4,505.8c-2.9,3.1-5.9,6.4-9.1,9.7c-0.2,0.2-0.8,0.2-0.9,0.1c-2.5-3-5.3-5.8-7.2-9.2c-0.8-1.4-0.3-4.3,0.7-5.7
	c6.7-9.2,9.7-8.9,15,1.3C2302.4,502.9,2302.7,503.9,2303.4,505.8z"
        />
        <g>
          <path
            class="st0"
            d="M1005.9,456.4c-4.3-4.4-5.5-4.3-8.5,1.2c-8.8,16.1-17.7,32.2-26.5,48.3c0.8,0.2,1.5,0.6,2.2,0.9
		c1-0.9,1.8-1.8,2.6-2.5c0-1.4-0.2-2.8-0.7-4.2c0.9-0.1,1.8-0.2,2.8-0.2c1.2-4.5,3.2-6.7,7.6-7.1c14.6-1.4,29.2-3.2,43.8-4.8
		c0,0,0.1,0,0.1,0c1.4-1,2.8-2.1,4.1-3.2C1024.3,475.3,1015.1,465.8,1005.9,456.4z M1000.8,457.2c0.2-0.4,1.9-0.4,2.4,0
		c2.5,2.2,4.8,4.5,7.1,6.8c-0.2,0.4-0.3,0.9-0.5,1.3c-4.2,0.3-8.4,0.5-13.8,0.9C997.9,462.6,999.2,459.9,1000.8,457.2z M997.5,469
		c4.6-0.4,9.4-0.7,14-0.2c2.4,0.3,4.7,2.3,7,3.5c-0.2,0.7-0.4,1.4-0.6,2.1c-8.9,1-17.9,1.9-26.8,2.9
		C991.2,472.1,993.2,469.3,997.5,469z M984,489.9c0-6.4,3.2-9.8,9-10.3c8.1-0.7,16.2-1.5,24.3-2.4c5.2-0.5,9.2,2,11.5,7.5
		C1013.8,486.4,998.9,488.2,984,489.9z M974.8,508c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1,0-0.1,0-0.2C975.1,508.1,974.9,508,974.8,508z"
          />
          <path
            class="st0"
            d="M881.3,338.7c-6.9-6.8-13.7-13.6-20.6-20.3c-3.5-3.4-4-3.2-6.1,1.2c-6.8,14.3-13.5,28.6-20.3,42.8
		c-0.8,1.6-1.7,3.1-3,5.3c-2.3-2.2-4-4.1-6-5.7c-0.6-0.5-1.8-0.4-2.8-0.6c0.2,1,0,2.3,0.5,3c1.5,1.8,3.3,3.3,5.4,5.4
		c-2,1.1-3.3,2-4.7,2.6c-8.4,3.9-16.7,7.8-25.1,11.8c-4.8,2.3-4.8,2.9-1,6.7c4.9,4.9,9.8,9.9,14.7,14.8c3.8,3.9,4.5,3.8,6.6-1.1
		c4-9.2,7.9-18.5,11.9-27.7c0.4-1,1.1-2,1.9-3.6c1.8,1.7,3,3.2,4.5,4.3c0.7,0.5,1.8,0.4,2.8,0.6c-0.2-0.9,0-2.1-0.5-2.7
		c-1.4-1.7-3.1-3.1-5.2-5.1c1.9-1.2,3.1-2,4.3-2.7c13.9-7.6,27.7-15.1,41.6-22.7C884.4,342.8,884.5,341.9,881.3,338.7z M815.9,403.5
		c-5.6-5.5-10.7-10.4-16.3-15.9c9.6-4.6,18.6-8.8,27.6-13.1c0.3,0.2,0.5,0.5,0.8,0.8C824,384.5,820,393.7,815.9,403.5z M838.4,364
		c-0.3-0.3-0.5-0.5-0.8-0.8c6.6-13.9,13.1-27.8,20-42.3c7.3,7.1,14.1,13.7,21.5,20.9C865.1,349.5,851.8,356.7,838.4,364z"
          />
          <path
            class="st0"
            d="M1041.8,476.9c0.9-0.9,1.7-1.7,2.6-2.6c-2.8-0.2-5.6-0.3-8.4-0.3c-1.1,0-2.2,0.6-3.2,1
		c0.3,1.1,0.4,2.4,1.1,3.1c1.1,1.1,2.3,2.2,3.4,3.3c0.6-0.6,1.2-1.1,1.7-1.7c-0.6-0.6-1.3-1.2-1.9-1.8c0.1-0.4,0.3-0.7,0.4-1.1
		C1038.9,476.9,1040.3,476.9,1041.8,476.9z M1101.4,419c-0.6-0.6-1.8-0.7-2.7-1c-0.4,3.2-0.7,5.7-1,8.2c-0.3,3.2-1.9,4.4-5.1,3.7
		c-3.9-0.8-7.9-1.3-11.9-2c-5-0.9-6.3,0.2-5.7,5.1c0.4,3.3,0.9,6.6,1.5,9.9c0.5,2.9-0.6,3.9-3.4,3.8c-2.3-0.2-4.7-0.4-6.8,0.2
		c-1.4,0.4-3.2,1.9-3.6,3.3c-0.8,2.5-0.6,5.4-0.9,8c-2.9-0.4-5.8-0.7-8.6-1.1c-0.1,0-0.3,0-0.4-0.1c-3.4-0.5-5.2,0.9-5,4.4
		c0.1,2.9,0.3,5.9,0.6,8.8c0.8-0.8,1.6-1.6,2.5-2.4c-0.1-1.7-0.2-3.3-0.4-5c-0.2-2.3,0.8-3.2,3-2.8c1.8,0.3,3.5,0.6,5.3,0.9
		c2-1.5,4.2-2.9,6.4-4.1c-0.2-1.5,0.4-3.3,0.1-4.8c-0.4-2.4,1.4-2.9,3.3-2.8c0.3,0,0.5,0,0.8,0c5.1,0,7.5,0.5,8.6,2.9
		c0.9-0.3,1.9-0.6,2.8-1c0.4-1.4,1.7-1.9,3.9-2c1.2-0.8,2.3-1.7,3.2-2.8c-10,0.3-7.9,1.8-9.6-9.3c-0.3-2.1-0.5-4.2-0.8-7.2
		c6.1,1.1,11.4,2,16.7,3c0.4,0.1,1,0.4,1,0.6c-0.1,0.5-0.1,1.1-0.2,1.7c1.7-1.8,3.8-3.4,5.9-4.9c-0.2-0.1-0.4-0.2-0.5-0.3
		c-0.7-0.5,0.4-3.6,0.6-5.6c0.4-0.1,0.8-0.1,1.1-0.2c1,1.1,2,2.1,3,3.2c0.7-0.5,1.5-1,2.2-1.5c0,0,0.1,0,0.1-0.1
		C1105.5,423.4,1103.5,421.2,1101.4,419z"
          />
          <path
            class="st0"
            d="M777.1,383.2c-0.3-3.4-2-3.8-4.4-1.3c-2.6,2.7-4.9,5.7-7.5,8.3c-9.8,10-19.5,20-29.3,30
		c0.5,0.6,1.1,1.2,1.5,1.8c0.4,0.6,0.8,1.2,1.2,1.9c4.9-5.1,9.5-9.8,14.7-15.1c-0.2,6.7-0.4,12.4-0.6,18.7
		c-4.6-0.5-8.7-0.9-13.1-1.4c0.4,1.1,0.6,2.3,0.8,3.5c0.1,0,0.2,0,0.3,0.1c3.3,0.7,6.7,0.7,10,1.2c3.6,0.6,5.3-0.9,5.5-4.5
		c0.3-5.7,0.5-11.5,0.8-17.6c0.5-0.2,0.8-0.5,1.2-0.5c2.5,0.1,5.1,0.2,7.6,0.3c11,0.6,11.2,2.8,11.6-10.7
		C777.3,393.1,777.5,388.1,777.1,383.2z M773.4,405.8c-5.7-0.4-10.8-0.8-17-1.2c6.1-6.3,11.5-11.8,17.7-18.2
		C773.9,393.2,773.6,399.3,773.4,405.8z"
          />
          <path
            class="st0"
            d="M729.4,377.3c-0.6,0.1-1.2,0.4-2.6,0.8c1.8,1.9,3.2,3.5,4.7,5.1c2.3,2.4,4.7,4.8,6.9,7.3
		c1,1.2,1.5,2.9,2.2,4.4c-1.4,0.5-2.8,1.3-4.3,1.6c-2.5,0.4-5,0.7-7.5,1.1c-0.6-1.3-1.3-2.3-2.2-3.4c0.2,0,0.5-0.1,0.7-0.1
		c2.5-0.4,5-0.7,7.5-1c0.3-0.4,0.5-0.7,0.8-1.1c-3.3-3.2-6.7-6.3-9.8-9.6c-1.6-1.7-2.8-3.7-4.1-5.6c2.2-0.9,4.4-2.3,6.7-2.6
		c8.7-1.3,17.5-2.2,26.3-3.3c0.7-0.1,1.5-0.3,3-0.6c-4.2-4.1-7.9-7.6-11.5-11.3c-0.7-0.7-0.9-1.8-1.2-2.8c-0.1-0.2-0.1-0.5-0.2-0.7
		c1.1,0.4,2.3,0.5,3.2,1c0.2,0.1,0.3,0.2,0.5,0.3c4.1,3.7,8,7.5,11.8,11.5c1.2,1.2,1.7,3,2.5,4.5c-1.6,0.5-3.2,1.1-4.8,1.3
		C748.5,375.2,738.9,376.2,729.4,377.3z"
          />
          <path
            class="st0"
            d="M932.5,389.4c-2.4,0.1-4.8,0.1-7.3,0.4c-2.6,0.2-3.7-0.6-3.3-3.4c0.4-2.6,0.3-5.3,0.4-7.9
		c-0.5-0.1-1.1-0.2-1.6-0.2c-0.4,1.2-1,2.4-1.2,3.7c-1,7.4-0.9,7.4-8.6,8c-0.3,0-0.5,0-0.8,0c-2.7,0.1-4.1,1.6-4.6,4.2
		c-0.6,3.1-0.5,7.2-2.4,8.9c-2,1.7-6.1,1-9.3,1.3c-0.1,0-0.3,0-0.4,0c-4,0.4-4.5,1.3-3.5,5.2c1.4,5.3,1.4,5.3-4,6.3
		c-0.9,0.2-1.8,0.4-2.8,0.5c-2.3,0.3-3.1,1.5-3.2,3.8c-0.3,7.5-0.4,7.5-8,6.7c-0.4,0-0.8-0.1-1.2-0.1c-3.4-0.6-4.6,1.1-4.5,4.3
		c0.1,7.1,0.1,7.1-7.1,6.9c-1.6,0-3.2-0.1-4.8,0.1c-1.2,0.1-2.3,0.7-3.4,1.1c0.1,0.5,0.2,1,0.2,1.5c2.6,0.1,5.2,0,7.8,0.2
		c9,0.6,7.5-0.4,7.9,8c0.1,2.2,0.6,4.3,1.1,6.9c1.1-0.8,1.5-0.9,1.5-1.1c0.1-2.8,0.3-5.6,0.1-8.3c-0.3-3.6,0.6-5.4,4.7-4.4
		c3.3,0.8,5.4-0.7,6.1-4.1c0.2-1.2,0.6-2.3,0.9-3.5c0.5-2.5,1.9-3.3,4.3-2.8c2.4,0.4,4.8,0.8,7.1,1c3.1,0.2,4.9-1.1,4.4-4.5
		c-0.4-2.4-0.6-4.8-1.2-7.1c-0.8-2.9,0.1-4.1,3-4c3.3,0.1,5.5-1.2,6-4.5c1.2-6.9,2.3-6,8.1-5.8c6.2,0.2,6.8-0.4,7.6-6.5
		c0.1-0.8,0.2-1.6,0.2-2.4c0-3.6,1.4-5.3,5.3-5.1c2.2,0.1,4.5-0.8,6.7-1.2C932.7,390.5,932.6,390,932.5,389.4z M876.9,438.2
		c-1.2,1.1-5.9,0.7-6.5-0.4c-1.2-2.1-0.5-5.1-0.5-7.8c2.8,0.1,5.7,0.2,9.3,0.3C878.4,433.5,878.4,436.7,876.9,438.2z M893.8,429.3
		c-3.6-0.7-7.5-0.9-10.7-2.4c-0.8-0.4-0.6-5.6,0.8-7.3c1.2-1.5,4.7-1.2,7.1-1.6c0.3,0,0.7,0.3,1.2,0.6c0.7,3.1,1.4,6.4,2.1,9.6
		C894.4,428.6,893.9,429.3,893.8,429.3z M898.2,414.1c-3.7,0-5.4-1.3-5.2-5c0-0.7,1.1-1.9,1.7-2c2.6-0.3,5.2-0.3,8.4-0.5
		C902.4,410.4,903.2,414,898.2,414.1z M917.3,401.7c-0.2,0.9-2.6,2-3.7,1.7c-2.1-0.5-6,2.2-6-2.6c0.1-7.9,0.5-8.3,6.2-8.2
		c1.3,0,2.6,0.1,4.5,0.3C918.1,396,918.1,399,917.3,401.7z"
          />
          <path
            class="st0"
            d="M860.1,287.7c0.1,3.3,0.2,6.6,0.3,9.9c0,1.4-0.9,2.8-1.4,4.2c-1.2-0.6-2.7-1-3.7-2c-2.6-2.5-5.1-4.9-7.6-7.4
		c1.3-0.4,2.5-0.8,3.8-1.2c1.5,1.4,3,2.9,4.5,4.3c0.3-0.1,0.7-0.3,1-0.4c-0.1-2-0.2-4-0.2-6C857.8,288.7,859,288.2,860.1,287.7z"
          />
          <path
            class="st0"
            d="M879.4,274.9c-0.1,0.8-0.2,1.6-0.3,2.4c-0.2,1.7-1.4,3.3-2.1,4.9c-1.2-0.7-2.6-1.2-3.8-2
		C875.5,278.6,877.6,276.8,879.4,274.9z"
          />
          <path
            class="st0"
            d="M902.5,238.7c-0.4,4.7-0.8,9.5-1.2,14.2c-0.1,1.3-1.1,2.6-1.6,3.9c-1.4-0.6-3-1-4-1.9
		c-1.5-1.3-2.9-2.7-4.4-4.1c0.4-1.1,0.8-2.3,1.3-3.4c1.7,1.5,3.4,2.9,5.1,4.5c0.4-1.4,0.8-2.5,0.9-3.5c0.2-3.4,0.4-6.8,0.6-10.2
		c0.6,0,1.2,0.1,1.8,0.2C901.4,238.4,902,238.5,902.5,238.7z"
          />
          <path
            class="st0"
            d="M995.6,313.2c-1.7,0.1-3.5-0.2-4.9,0.5c-14.6,7-29,14.3-43.7,21.2c-9.8,4.6-20,8.4-29.9,12.8
		c-5.4,2.4-5.5,4.4-1.4,8.5c5.1,5.1,10,10.4,14.9,15.8c4.7,5.3,6.4,5.5,11.3,0.5c8.6-8.8,17.2-17.7,25.8-26.5c0,0,0,0,0,0
		c8.9-9.1,17.8-18.2,26.5-27.5C995.3,317.2,995.2,314.9,995.6,313.2z M959,350c-6.6,6.8-13.2,13.7-20,20.3c-0.9,0.9-3.6,1.5-4.1,1
		c-6.1-6.3-11.9-12.9-18-19.6c23.4-10.4,46.8-20.8,70.1-31.2c0.1,0.2,0.2,0.3,0.4,0.5C977.9,330.6,968.5,340.3,959,350z"
          />
          <path
            class="st0"
            d="M828.9,501.3c-17,2.8-34,5.7-51.1,8.6c-1.2,0.2-2.4,0.6-4.5,1.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.2,0.3,0.3
		c1.4,1.3,2.2,2.2,3,3c4.7,4.3,9.4,8.7,14.1,13c2.9,1.9,5.9,3.4,9.1,4.1c-6.7-6.2-13.3-12.4-20.1-18.7c1.3-0.4,2.3-0.9,3.4-1.1
		c14.6-2.5,29.3-4.9,43.9-7.3c2.2-0.4,3.9-0.4,3.5,2.6c-0.5,3.4-0.9,6.7-1.4,10.1c0.9,0.1,1.8,0.3,2.7,0.4c0.5-4,1-8.1,1.6-12.1
		C833.8,501.2,833.2,500.6,828.9,501.3z"
          />
          <path
            class="st0"
            d="M1090.6,405.1c-1.1-0.2-2.5-0.1-3.4-0.8c-3.6-2.5-7-5.3-10.7-8.1c-3.8,6.3-7.3,12.1-10.9,17.8
		c-0.7,1.1-1.5,2.2-2.2,3.4c-1.3,2-2.8,2.3-4.7,0.9c-2.6-1.9-5.3-3.7-7.7-5.8c-2.1-1.8-3.3-1.3-4.9,0.7c-3.1,4.2-6.4,8.2-9.8,12.2
		c-3.6,4.2-4.4,4.2-8.7,0.4c-2.9-2.5-6.1-4.8-9.1-7.3c-2.4-1.9-4.4-1.4-6.1,1.1c-1.3,1.9-2.5,3.8-3.7,5.7c-1.5,2.4-3.2,2.5-5.2,0.7
		c-3-2.6-6.1-5.2-9.3-7.7c-2-1.6-3.8-1.5-5.5,0.6c-1.3,1.6-2.7,3-4.3,4.3c-0.6,0.5-1.8,0.3-2.8,0.5c0.1-0.8-0.1-1.9,0.4-2.5
		c2.1-2.4,4.2-4.9,6.8-6.8c1.2-0.8,3.8-0.7,5.1,0c4.1,2.5,7.8,5.5,12,8.5c1.3-1.7,2.5-3.6,3.9-5.3c3.6-4.6,6.1-5,10.9-1.5
		c2.9,2.1,5.7,4.4,8.4,6.8c2,1.7,3.5,2,5.3-0.3c3.1-4.1,6.3-8,9.5-12c0.2-0.2,0.3-0.4,0.5-0.6c1.7,0.2,3.6-0.8,4.6-2.3
		c0-0.1,0.1-0.1,0.1-0.2c0.9,0.3,2,0.9,3.4,2c8.3,6.2,8.3,6.2,13.7-2.7c2.3-3.8,4.5-7.6,6.7-11.4c1.7-3.1,3.7-3.5,6.5-1.3
		c3.2,2.6,6.6,5,9.7,7.7C1089.9,402.6,1090.1,404,1090.6,405.1z"
          />
          <path
            class="st0"
            d="M884.7,488.8c-15.1-14.5-30.1-29-45.3-43.4c-0.8-0.8-3.1-1.4-3.7-0.9c-1.1,0.9-2.1,2.9-1.9,4.2
		c2.6,14.7,3.7,29.5,2.9,44.4c-0.3,5.1,0.1,5.3,5.2,4.7c14.2-1.7,28.4-3.5,42.7-5.2c0.6-0.1,1.2-0.4,2.4-0.9
		C886,490.4,885.5,489.5,884.7,488.8z M840.4,495.2c-1-16-2-31.7-3-47.9c14.5,14.1,28.9,28.1,43.7,42.5
		C867.1,491.7,853.7,493.4,840.4,495.2z"
          />
          <path
            class="st0"
            d="M792.5,396.5c-1.5,7.9-3,15.8-4.7,23.6c-0.6,2.6-2.7,3-5.1,2.6c-4.3-0.7-8.7-1.3-13.7-2.1
		c-0.5,6.9-1,13.3-1.6,19.7c-0.5,5.5-1.8,6.4-7.2,5.7c-3.9-0.5-7.9-1.1-12.4-1.7c0,0.2,0.1,0.4,0.1,0.7c-0.1-0.2-0.2-0.4-0.2-0.6
		c-0.4-1.1-0.8-2.3-1.2-3.5c0.8-0.1,1.8-0.1,3,0c3.3,0.3,6.7,0.6,9.9,1.3c3,0.6,4.4,0,4.5-3.4c0.2-5.5,0.7-11,1.3-16.4
		c0.5-5.2,1.6-5.9,6.7-5.2c4,0.6,7.9,1.2,12.3,1.9c1.3-6.5,2.6-12.9,3.9-19.2c0.3-1.4,0.6-2.9,1.2-4.2c0.3-0.7,1.4-1,2.1-1.4
		C791.8,395,792.6,395.9,792.5,396.5z"
          />
          <path
            class="st0"
            d="M813.5,254.3c-0.2,0.7-1,1.3-1.6,1.9c-0.6-0.6-1.7-1.2-1.7-1.9c-0.4-6-0.7-12-0.9-18c0-0.7,0.1-1.3,0.3-1.7
		c0.1,0.3,0.1,0.7,0.2,1c0.5,2,1.7,3.8,3.4,5.1c0.2,3.4,0.3,6.7,0.5,10C813.8,251.9,813.8,253.2,813.5,254.3z"
          />
          <path
            class="st0"
            d="M1014.9,360.9c-12,1.2-24,2.4-35.9,4c-1.4,0.2-3.3,2.2-3.6,3.6c-2.2,11.4-4,22.9-5.8,34.4
		c-0.2,1.2,0.8,2.6,1.3,3.9c1.1-0.6,2.4-1,3.3-1.8c8.4-7.9,16.8-15.8,25-23.8c6.2-6.1,12.1-12.4,18.8-19.2
		C1016.5,361.5,1015.6,360.9,1014.9,360.9z M973.5,401.1c-0.4-0.2-0.8-0.3-1.2-0.5c1.8-10.2,3.6-20.5,5.6-30.7
		c0.2-0.9,1.4-2.3,2.3-2.4c10-1.2,19.9-2.1,29.9-3.1c0.2,0,0.4,0.3,1.4,0.9C998.7,377.4,986.1,389.2,973.5,401.1z"
          />
          <path
            class="st0"
            d="M982.6,301.4c-1.6-1.6-3.3-3.2-4.9-4.8c0,0,0,0-0.1,0.1c-1.5-1.7-3.2-3.3-4.6-5c-2.1-2.6-4.2-2.7-6.3-0.2
		c-3.2,3.6-6.4,7.1-9.5,10.8c-6.3,7.7-12.5,15.3-18.5,23.2c-0.9,1.2-0.7,3.3-1.1,5c1.6-0.2,3.4,0,4.8-0.7
		c8.2-4.3,16.2-8.8,24.3-13.2c4.8-2.6,9.8-4.9,14.6-7.5C986,306.8,986.2,305,982.6,301.4z M944.2,324.8c-0.2-0.3-0.5-0.5-0.7-0.8
		c4.8-5.9,9.6-11.8,14.4-17.7c3-3.6,6.2-7,9.2-10.6c1.6-1.9,2.8-2.4,4.8-0.3c2.9,3.2,6.2,6.1,9.8,9.6
		C968.7,311.9,956.5,318.3,944.2,324.8z"
          />
          <path
            class="st0"
            d="M811,344.1c-0.7,5-1.4,9.3-1.8,13.6c-0.3,3.1-1.5,5-4.6,5.6c-7.7,1.6-15.4,3.4-23.2,4.9
		c-1,0.2-2.2-0.7-3.4-1.1c0.9-0.8,1.7-2,2.8-2.2c7-1.6,14.1-3.1,21.2-4.4c2.8-0.5,4-1.7,3.7-4.6c-0.4-3.6-0.5-7.2-1-10.8
		c-0.4-3,0.6-4.3,3.5-4.4c4-0.3,8-0.8,12-0.9c2.8-0.1,4-1,4-4c0.1-4.9,0.4-9.9,1.1-14.8c0.2-1.6,1.9-3.7,3.4-4.2
		c6.8-2.3,13.8-4.1,20.8-5.9c0.8-0.2,2,0.6,2.9,0.9c-0.7,0.8-1.3,2-2.2,2.3c-6,1.8-12.1,3.4-18.2,4.9c-2.7,0.7-3.6,2.1-3.7,4.8
		c-0.1,4.8-0.6,9.6-0.8,14.4c-0.2,3-1.7,4.6-4.7,4.8C818.2,343.3,813.6,343.8,811,344.1z"
          />
          <path
            class="st0"
            d="M829.9,458.6c0-0.8-0.3-1.6-0.6-2.3c-1.2-2.8-2.5-3-4.6-0.7c-12.4,13.9-24.8,27.7-37.2,41.5
		c-0.9,1-1.6,2.3-2.3,3.4c0.2,0.4,0.5,0.7,0.7,1.1c1.3-0.1,2.6-0.2,3.8-0.4c12.4-2.6,24.8-5.3,37.2-8c2.6-0.5,4.1-1.9,4-4.6
		C830.6,478.6,830.3,468.6,829.9,458.6z M825.4,490.8c-10.1,2-20.2,4.1-30.2,6.2c-0.8,0.2-1.5,0.5-2.2,0.7c-0.1-0.5-0.3-1.1-0.4-1.6
		c11.1-12.5,22.2-25,34-38.3c0.5,2.4,1,3.6,1.1,4.8c0.5,7.7,0.8,15.5,1.2,23.2C828.9,488.4,828.3,490.2,825.4,490.8z"
          />
          <path
            class="st0"
            d="M945.3,477.3c-11.3,2.2-22.6,4.3-33.9,6.5c-1.6,0.3-3.3,1.2-4.6,2.2c-0.8,0.6-0.9,2-1.2,2.8
		c1.1,0.8,1.3,0.9,1.5,1.1c9.8,7.4,19.5,14.8,29.3,22.2c0,0,0.1,0.1,0.1,0.1c1.7,1.3,2.8,1.8,3.6,1.3c0.6-0.4,1.1-1.4,1.6-3.1
		c2.7-9.4,5.3-18.8,7.9-28.2C951.1,476.9,950.5,476.3,945.3,477.3z M938.4,509.3c-9.7-7.3-18.7-14.1-28.9-21.8
		c12.8-2.4,24.4-4.6,37-7C943.8,490.5,941.2,499.6,938.4,509.3z"
          />
          <path
            class="st0"
            d="M872.1,498.9c-8.9,1.9-17.7,4.6-26.7,5.8c-4.2,0.6-4.6,2.2-5,5.3c-0.4,2.8-0.7,5.7-1.1,8.5
		c0.9,0.1,1.9,0.2,2.8,0.3c0.3-2.3,0.6-4.5,0.9-6.7c0.2-1.6,1.7-4,3-4.4c8.5-2.3,17.1-4.1,25.7-6c0.3-0.1,0.8,0.1,2.1,0.4
		c-4.9,6.2-9.9,12.1-14.9,17.9c1.2,0,2.3,0.1,3.5,0.1c1.6-1.8,3.2-3.6,4.7-5.4c1.1-1.3,2.3-2.6,3.4-4c1.7-1.9,3.3-3.9,5-5.8
		c1.4-1.6,2-3.8,2.9-5.7C876.4,499,874.1,498.5,872.1,498.9z"
          />
          <path
            class="st0"
            d="M777.2,356.3c0.3,0.4,0.6,0.8,0.8,1.2c-0.3,1.2-0.6,2.3-0.9,3.4c-0.3,1-1.6,1.7-2.5,2.5
		c-0.2-1-0.8-2.1-0.7-3.1c0.3-2,0.7-3.9,1-5.9C775.8,354.9,776.5,355.5,777.2,356.3z"
          />
          <path
            class="st0"
            d="M830.2,290.7c-0.2,1.1-0.5,2.3-0.7,3.4c-0.9,4.3-1.7,8.6-2.9,12.8c-2.8,10.2-2.9,10.1-13.2,11
		c-5.2,0.5-7.9,0.7-9.2,2.1c-0.2-1.6-0.4-3.2-0.6-4.8c0.4-0.1,0.8-0.1,1.3-0.1c4.3-0.2,8.5-0.8,12.8-0.9c2.5-0.1,3.7-1.1,4.2-3.3
		c1.4-5.6,2.7-11.2,4.1-16.7c0.1-0.6,0.4-1.1,0.6-1.6c0.4-0.9,1-1.8,1.4-2.7C828.8,290.1,829.5,290.4,830.2,290.7z"
          />
          <path
            class="st0"
            d="M1078.9,412.7c-0.4,1.1-0.5,2.5-1.2,3.4c-5.2,5.9-10.5,11.8-15.9,17.5c-4.3,4.5-6.8,4.5-11.1-0.1
		c-2.9-3.2-4.6-2.4-6.8,0.5c-2.4,3.1-5,5.9-7.8,8.6c-3.4,3.3-5.1,3.2-8.5,0c-2.5-2.4-5.1-4.8-7.5-7.3c-1.9-2-3.3-1.6-4.9,0.3
		c-1.8,2.3-3.7,4.6-5.9,6.6c-0.9,0.8-3.3,1.2-4,0.6c-3.7-3-7.2-6.4-10.5-9.8c-0.6-0.6-0.2-2.1-0.3-3.1c0.9,0.3,2,0.5,2.6,1.1
		c2.6,2.3,5,4.8,7.5,7.3c1.6,1.6,2.9,1.5,4.4-0.3c1.9-2.3,3.9-4.4,5.8-6.6c1.8-2,3.6-2,5.5-0.2c2.8,2.7,5.8,5.2,8.4,8.1
		c2.3,2.6,4.1,2.3,6.2-0.2c3-3.6,6.3-6.9,9.3-10.5c2.1-2.5,3.9-2.4,5.9-0.1c0.1,0.1,0.2,0.2,0.3,0.3c5.5,6.3,5.9,5.9,12.3-0.9
		c4.9-5.2,10-10.3,15-15.4C1078.1,412.4,1078.5,412.6,1078.9,412.7z"
          />
          <path
            class="st0"
            d="M1029.1,345.9c-6.9-5.4-14-10.7-20.9-16.1c-2.3-1.8-4.3-1.9-6.3,0.2c-3.5,3.7-7.1,7.3-10.6,10.9
		c-0.1-0.1-0.3-0.2-0.4-0.4c-4.3,4.8-8.6,9.5-12.7,14.5c-0.9,1.1-0.9,2.8-1.3,4.3c1.5,0.3,3,0.9,4.4,0.7c6.4-0.8,12.7-1.7,19-2.8
		c8.8-1.5,17.7-3.1,26.4-4.9c1.5-0.3,2.8-2.1,3.8-3.5C1030.8,348.3,1029.9,346.5,1029.1,345.9z M980.9,357c-0.2-0.3-0.3-0.7-0.5-1
		c7.6-7.8,15.2-15.7,22.9-23.5c0.5-0.5,2.4-0.6,3.1-0.1c7,5.4,13.8,10.9,21,16.6C1012.2,354,996.3,354,980.9,357z"
          />
          <path
            class="st0"
            d="M961.1,472.8c-3.5-9-7-17.9-10.6-26.8c-1.2-2.9-2.9-3.1-5.5-0.5c-8,7.7-15.9,15.5-23.7,23.4
		c-1.1,1.1-1.6,2.8-2.3,4.3c1.7,0.6,3.3,1.6,5,1.6c11.4,0,22.7-0.1,34.1-0.3c1,0,2-0.3,2.9-0.4C961,473.3,961.2,473,961.1,472.8z
		 M925.8,469.8c6.3-6.5,13.6-13.9,21.8-22.4c3.1,8.3,5.7,15.5,8.4,22.7C945.4,469.9,935.1,469.8,925.8,469.8z"
          />
          <path
            class="st0"
            d="M726.1,335.7c-1,0.3-2.1,1-3.1,1c-5.7-0.2-8.4,1.5-10.4,6.8c-0.7,1.7-1.1,3.6-1.7,5.4
		c-0.9,2.7-2.2,4.9-4.2,6.3c-0.7-0.9-1.3-1.9-1.9-2.9c1.5-1.3,2.4-3.5,3.3-5.6c1.6-3.7,3.2-7.8,6-10.5c2.1-2,5.9-2.4,9.1-3
		C724,333,725.1,334.8,726.1,335.7z"
          />
          <path
            class="st0"
            d="M991.3,441.7c-11.5-0.5-22.9-0.6-34.4-0.8c-2.2,0-3.1,1.1-2.2,3.4c3.2,8.1,6.2,16.2,9.4,24.3
		c1.5,3.8,3.2,4.3,6.2,1.4c3.8-3.7,7.5-7.6,11.2-11.5c0.1,0.1,0.1,0.1,0.2,0.2c3.8-3.9,7.7-7.8,11.4-11.8
		C995.5,444.2,994.8,441.8,991.3,441.7z M967.4,468c-2.9-8.2-5.5-15.7-8.3-23.7c10.1-1.2,20.1-0.4,30.8,0.6
		C982.3,452.7,975.1,460.1,967.4,468z"
          />
          <path
            class="st0"
            d="M735.9,351.6c-4.5,2.4-7.8,5.9-10,10.9c-1.9,4.4-5.1,7.3-9.4,9.3c-0.4-1-0.9-2-1.4-3c4.5-1.5,6.7-5.4,8.6-9.4
		c1.9-4,4.6-7,8-9.2C732.7,351.3,734.3,351.5,735.9,351.6z"
          />
          <path
            class="st0"
            d="M941.7,260.9c-0.4,1.9-0.9,3.9-1.3,5.8c-4,1.7-6.9,3.3-10,4.2c-4.5,1.4-7.1,3.8-7.4,8.7
		c-0.3,5.5-4,8.6-8.8,10.4c-4.2,1.6-6.5,4.2-8,8.6c-1,2.9-4.6,5.5-7.6,7c-3.6,1.8-6.2,3.6-7.6,7.5c-1.9,5.3-7.1,6.9-11.4,9.5
		c-0.3,0.2-1.2,0-1.4-0.3c-0.3-0.5-0.4-1.3-0.1-1.7c0.5-0.8,1.2-1.6,2-1.9c5.1-2.1,7.8-6,10.1-10.8c1.1-2.3,4.4-3.7,6.9-4.9
		c3.6-1.7,5.9-3.9,7.2-7.9c0.9-2.7,3.7-5.4,6.3-6.7c5.4-2.7,9.5-5.6,9.8-12.3c0.2-3.6,3-5.8,6.3-7.2c2.4-0.9,4.8-1.6,7.1-2.7
		c3-1.4,4.6-3.2,5.2-5.7C939.9,260.7,940.8,260.8,941.7,260.9z"
          />
          <path
            class="st0"
            d="M922.1,252.2c-1.4,1.4-3.1,2.6-5.1,3.5c-5.2,2.5-9,5.8-10.1,12c-0.7,4.2-3.8,7-7.7,8.7c-4,1.7-6.5,4.4-7.6,8.6
		c-0.9,3.4-3.3,5.6-6.6,6.3c-4,1-6,3.3-6.8,7.4c-1,5.3-5.8,9.7-11,10.4c-0.7,0.1-1.6-0.5-3.5-1.1c2.3-1.4,3.6-2.5,5.1-3.1
		c3.7-1.5,6-4,6.5-8c0.6-4.5,3.1-6.9,7.3-8.5c2.4-0.9,5.1-3.2,6-5.6c1.7-4.2,4.1-7.3,8-8.9c4.3-1.8,6.8-4.9,7.8-9.4
		c1-4.6,3.8-8.1,8-10c2.7-1.2,5-2.8,7-4.6C920.3,250.6,921.2,251.4,922.1,252.2z"
          />
          <path
            class="st0"
            d="M823.5,427.5c-0.8,1.5-1.3,3.3-2.4,4.5c-9.7,10.2-19.6,20.2-29.4,30.3c-0.4-0.3-0.7-0.7-1.1-1
		c-10.1,10.4-20.1,20.8-30.3,31.1c-0.6,0.6-1.5,0.9-2.4,1.2c-0.2-0.4-0.4-0.7-0.7-1.1c0.1-0.9,0.2-1.8,0.6-2.3
		c7.6-7.9,15.3-15.8,23.1-23.5c12.5-12.3,25-24.6,37.7-36.8C819.9,428.6,821.9,428.2,823.5,427.5z"
          />
          <path
            class="st0"
            d="M1021.3,386.2c-5.6,6.4-11.3,12.7-17,19c-1.1,1.2-2.9,1.7-4.4,2.5c0.7-1.6,1.1-3.6,2.3-4.9
		c5.7-6.5,11.4-13,17.1-19.4C1020,384.3,1020.7,385.2,1021.3,386.2z"
          />
          <path
            class="st0"
            d="M929.5,314.1c-9.5,9.8-19,19.6-28.6,29.3c-0.8,0.9-2.4,1-3.6,1.5c0.4-1.4,0.5-3.1,1.4-4
		c15.4-15.4,30.9-30.7,46.4-46.1c3.8-3.8,7.5-7.6,11.4-11.3c1.1-1,2.9-1.4,4.4-2c-0.6,1.4-1,3.1-2,4.2c-9.4,9.9-19,19.6-28.5,29.4
		C930.1,314.7,929.8,314.4,929.5,314.1z"
          />
          <path
            class="st0"
            d="M879.8,402.8c-10.2,10.5-20.3,20.9-30.6,31.3c-0.8,0.8-2.2,1-3.3,1.4c0.4-1.2,0.4-2.8,1.2-3.6
		c19.9-20.6,39.8-41.2,59.8-61.8c0.3-0.3,0.5-0.7,0.9-0.8c1.2-0.5,2.5-0.8,3.7-1.2c-0.5,1.2-0.7,2.6-1.5,3.4
		C900,382.1,889.9,392.4,879.8,402.8z"
          />
          <path
            class="st0"
            d="M763.8,500.3c0.7-1,1.4-2.2,2.2-3.1c17.8-18.5,35.6-37,53.5-55.5c2.5-2.6,5.1-5.1,7.8-7.5
		c0.6-0.5,2.1-0.2,3.1-0.2c-0.3,0.9-0.3,2.1-0.9,2.7c-17.5,18.2-35.1,36.4-52.7,54.5c-3,3.1-6.1,6-9.2,8.9c-0.7,0.7-1.9,0.9-2.8,1.3
		C764.4,501.1,764.1,500.7,763.8,500.3z"
          />
          <path
            class="st0"
            d="M730.3,343.1c-0.3,0.2-0.6,0.4-0.9,0.4c-4.8,0.9-8,3.6-9.7,8.1c-1.7,4.6-4.5,7.9-8.4,10.3
		c-0.6-1-1.2-1.9-1.8-2.8c3.4-2.3,5.9-5.2,7.5-9.3c2-5.3,6.1-8.4,11.5-9.5c0.3-0.1,0.5,0,0.8,0C729.7,341.3,730,342.2,730.3,343.1z"
          />
          <path
            class="st0"
            d="M813.8,416.6c-0.3,1.2-0.2,2.9-0.9,3.7c-15.5,16.5-31.1,32.8-46.7,49.2c-4.5,4.7-9.3,9.2-14.1,13.7
		c-0.7,0.6-1.5,1.1-2.4,1.6c-0.6-0.5-1.2-1.1-1.8-1.7c0.4-0.5,0.7-1.1,1.2-1.5c20.5-21.1,40.9-42.3,61.5-63.3
		C811.3,417.3,812.7,417.1,813.8,416.6z"
          />
          <path
            class="st0"
            d="M864.4,400.8c-2.1-2-4.2-4.1-6.3-6.1l0,0c-1-0.9-1.9-1.9-2.9-2.8c-1-0.9-1.9-1.9-2.9-2.8
		c-2.9-2.8-4.9-2.7-7,0.9c-4.8,8.5-9.4,17.1-13.9,25.7c-0.5,1-0.1,2.5-0.2,3.7c1.2,0,2.7,0.4,3.7-0.1c9.6-4.5,19.2-9.2,28.8-13.9
		C866.3,404.2,866.4,402.6,864.4,400.8z M836.5,414.5c-0.2-0.2-0.4-0.3-0.7-0.5c1.7-3.1,3.3-6.2,5-9.2c2.1-3.9,4.1-7.8,6.4-11.5
		c0.5-0.8,2.7-1.5,3.1-1.1c3.6,3.1,6.9,6.5,10.7,10.2C852.4,406.7,844.5,410.6,836.5,414.5z"
          />
          <path
            class="st0"
            d="M932,258.6c-1.3,1.6-3,2.8-5.2,3.7c-5.7,2.2-8.9,6.1-11.1,11.8c-1.2,3.1-4.5,5.4-6.8,8
		c-2.2,2.4-4.5,4.7-6.6,7.1c-2.2,2.4-3.9,5.8-6.5,7.2c-4.8,2.6-8.4,5.9-10.8,10.8c-1.1,2.3-2.6,4.4-4.1,6.4
		c-0.4,0.6-1.5,0.6-2.3,0.9c-0.1-0.8-0.7-1.8-0.4-2.4c2.7-4.7,5.6-9.4,8.6-14.4c7.6-2.6,10.8-9.9,16.1-14.9
		c5.4-5.1,10-10.1,13.3-16.7c1.2-2.5,4.4-4.2,7.1-5.6c2-1,3.9-2.2,5.3-3.6C929.8,257.5,930.9,258.1,932,258.6z"
          />
          <path
            class="st0"
            d="M898.2,356.7c-10.1,3.1-20.1,6.3-30.1,9.7c-3,1-3.2,2.7-0.7,5c2.3,2.1,4.6,4,7,6c0,0-0.1,0.1-0.1,0.1
		c1,0.9,1.9,1.9,2.9,2.8c7.4,7.3,7.7,7.1,12.4-2.2c3.3-6.5,7.4-12.6,11.1-18.9c0.4-0.6,0.3-1.4,0.5-2.1
		C900.1,357,899,356.5,898.2,356.7z M883.9,381.8c-4.5-4.4-8.6-8.3-13.1-12.7c7.7-2.6,15.1-5.1,23.5-7.9
		C890.5,368.6,887.3,375,883.9,381.8z"
          />
          <path
            class="st0"
            d="M970.3,362.3c-1.3,0.3-3,0.2-3.9,1c-2.8,2.5-5.3,5.3-7.9,8c-0.1-0.1-0.2-0.2-0.3-0.3c-1.4,1.3-2.8,2.5-4.1,3.8
		c-8,7.5-7.9,9.8,0.2,17.3c4,3.6,5.5,3.4,7.3-1.7c3-8.1,5.9-16.3,8.8-24.5C970.8,364.8,970.3,363.5,970.3,362.3z M958.1,392.1
		c-2.7-3.1-4.7-5.3-6.5-7.7c-0.5-0.6-0.4-2.4,0.1-2.9c4.5-4.6,9.1-9,13.7-13.5c0.3,0.3,0.7,0.5,1,0.8
		C963.7,376.2,961.1,383.6,958.1,392.1z"
          />
          <path
            class="st0"
            d="M920.2,306c-6.1,6.2-12.1,12.5-18.2,18.6c-1,1-2.8,1.2-4.2,1.8c0.5-1.3,0.7-3,1.7-4
		c12.2-12.7,24.6-25.3,37-37.9c0.8-0.9,2.4-1,3.7-1.4c-0.4,1.3-0.4,3-1.3,3.9c-6,6.4-12.2,12.7-18.4,19.1
		C920.4,306.1,920.3,306.1,920.2,306z"
          />
          <path
            class="st0"
            d="M1028.8,398.5c-0.8,0.8-1.6,1.6-2.4,2.4c-0.9,0.8-1.6,1.9-2.7,2.4c-1,0.4-2.4,0.2-3.6,0.3c0.5-1,0.7-2.3,1.4-3
		c1.6-1.6,3.2-3.1,4.8-4.7C1027.1,396.8,1027.9,397.7,1028.8,398.5z"
          />
          <path
            class="st0"
            d="M1066.4,389c-1,0.1-2.1,0.5-2.8,0.1c-0.2-0.1-0.4-0.2-0.6-0.3c1.1-0.2,2.2-0.3,3.4-0.4
		C1066.3,388.6,1066.3,388.8,1066.4,389z"
          />
          <path
            class="st0"
            d="M957.8,502.3c-3.1,2.5-5.9,5.4-8.8,8.1c-2.4,2.3-3.5,3.7-3.4,5l8.5-4.7l2.6-1.4l4.8-2.6c0,0,0,0,0.1,0
		c0.1,0,0.2-0.1,0.2-0.1c0.1-0.4,0.2-0.8,0.4-1.2c0.4-1.2,0.3-2.6,0.4-3.9C960.9,501.7,958.9,501.5,957.8,502.3z M956.7,509.3
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7,0.6-1.4,1.1-2.1,1.7c-0.6,0.5-1.1,1-1.7,1.5c-0.8,0.9-1.4,2.1-2,3.1c1.1,0.7,2.2,1.4,3.8,2.3
		c1-3.2,1.8-5.7,2.7-8.3C956.8,509.4,956.7,509.4,956.7,509.3z"
          />
          <path
            class="st0"
            d="M1020.5,452c-0.8-1.8-1.5-3.6-2.3-5.3c1.7,0.9,3.7,1.5,5.2,2.6c2.6,1.9,4.9,4.1,7.2,6.4c1.8,1.8,3.2,2,4.8-0.3
		c3.2-4.6,6.6-9,10-13.4c0.4-0.6,1.4-0.7,2.2-1c0.2,1,0.9,2.2,0.5,2.9c-1.4,2.6-3,5-4.8,7.4c-2.1,2.8-4.5,5.3-6.7,8
		c-2,2.4-4.2,2.6-6.5,0.5c-3-2.7-6-5.3-9.1-7.9C1020.9,451.8,1020.7,451.9,1020.5,452z"
          />
          <path
            class="st0"
            d="M1039.7,403.1c-2.2,2.4-4.5,4.9-6.7,7.2c-0.8,0.8-2.3,0.8-3.5,1.2c0.4-1.2,0.6-2.6,1.4-3.6
		c1.1-1.5,2.6-2.7,4.5-4.7c0.6-0.7,1.3-1.5,2-2.3c0.3,0.1,0.7,0.3,1,0.5C1038.9,401.8,1039.4,402.4,1039.7,403.1z"
          />
          <path
            class="st0"
            d="M1059.6,402.4c-0.9-0.3-2-0.3-2.7-0.8c-0.9-0.7-1.7-1.4-2.5-2.2c0.7-1.1,0.9-2.4,0.9-3.7
		c1.2,1.2,2.4,2.4,3.6,3.7C1059.4,400.1,1059.3,401.4,1059.6,402.4z"
          />
          <path
            class="st0"
            d="M967.5,494.2c-0.8,0.1-1.7,0.3-2.6,0.5c0-1-0.5-2.2-0.1-2.9c1.8-2.9,4-5.7,5.9-8.6c4.1-6.1,8.1-12.3,12.2-18.4
		c0.6-0.8,2-1.1,3-1.7c0,1.1,0.5,2.5,0,3.2C980,475.6,973.9,484.7,967.5,494.2z"
          />
          <path
            class="st0"
            d="M963.3,418.5c-1-0.4-2.1-0.6-2.9-1.2c-6.7-5.3-13.4-10.5-20-15.9c-0.7-0.6-0.9-1.9-1.3-2.9
		c1.1-0.1,2.7-0.7,3.4-0.2c6.7,5.1,13.2,10.3,19.7,15.6c1,0.8,1.4,2.4,2.1,3.7C964,417.8,963.6,418.2,963.3,418.5z"
          />
          <path
            class="st0"
            d="M932.3,406.1c0-0.2-0.2-1.1-0.4-2.1c1.1-0.1,2.5-0.8,3.2-0.4c7.2,5.1,14.3,10.3,21.4,15.6
		c0.6,0.5,0.7,1.8,1,2.7c-1.1,0-2.5,0.4-3.2-0.1C947,416.9,940,411.7,932.3,406.1z"
          />
          <path
            class="st0"
            d="M898.7,441.2c-0.1-0.4-0.4-1.4-0.7-2.5c1.2,0,2.6-0.5,3.4,0.1c6.8,4.7,13.5,9.6,20.1,14.5
		c0.8,0.6,0.9,2.2,1.3,3.3c-1,0-2.4,0.4-3.1-0.1C912.9,451.7,906.1,446.7,898.7,441.2z"
          />
          <path
            class="st0"
            d="M925,413.2c0-0.1-0.1-1.1-0.3-2.2c1,0,2.3-0.5,2.9,0c6.8,5.2,13.6,10.4,20.3,15.8c0.7,0.5,0.6,2,0.8,3.1
		c-1-0.1-2.4,0.2-3.1-0.3C939,424.4,932.4,419.1,925,413.2z"
          />
          <path
            class="st0"
            d="M803,296.2c-0.5,0.1-1.7,0.5-1.8,0.3c-0.4-0.7-0.7-1.7-0.5-2.5c0.9-5.5,2-11.1,2.9-16.6
		c0.6-3.6,1-7.2,1.6-10.7c0.2-0.8,1.1-1.5,1.7-2.2c0.5,0.8,1.6,1.6,1.5,2.3c-1.4,9.4-3,18.7-4.6,28.1
		C803.6,295.2,803.3,295.5,803,296.2z"
          />
          <path
            class="st0"
            d="M884.4,455.5c-0.1-0.5-0.3-1.6-0.5-2.6c1.1,0,2.5-0.5,3.2,0c6.7,4.8,13.3,9.8,19.9,14.8
		c0.7,0.6,0.7,2.1,1.1,3.2c-1.1-0.1-2.4,0.2-3.1-0.4C898.2,465.8,891.6,460.8,884.4,455.5z"
          />
          <path
            class="st0"
            d="M892.1,449.2c-0.1-0.5-0.6-1.8-0.2-2.2c0.6-0.5,2.1-0.9,2.7-0.5c6.7,4.6,13.4,9.3,19.9,14.1
		c0.5,0.4,0.4,1.8,0.5,2.7c-0.8,0.1-2,0.6-2.5,0.2C905.9,459,899.3,454.3,892.1,449.2z"
          />
          <path
            class="st0"
            d="M897.1,474.1c0.6,0.6,1.5,1,1.6,1.7c0.2,0.9,0.1,2-0.3,2.8c-0.2,0.3-1.8,0.3-2.3-0.1c-6.1-4.6-12.1-9.2-18-14
		c-0.8-0.6-0.8-2.1-1.2-3.2c1.2,0.1,2.6-0.1,3.5,0.5c5.7,4,11.2,8.2,16.8,12.3C897.1,474,897.1,474,897.1,474.1z"
          />
          <path
            class="st0"
            d="M864.9,385.5c-2.1-2.1-4.1-3.7-5.7-5.7c-0.6-0.7-0.5-2-0.7-3.1c1,0.2,2.2,0,2.8,0.5c4.8,4.1,9.5,8.2,14.2,12.4
		c0.4,0.4,0.3,1.4,0.4,2.1c-1,0.1-2.2,0.6-2.8,0.2C870.2,389.8,867.4,387.5,864.9,385.5z"
          />
          <path
            class="st0"
            d="M960.5,436c0-0.7-0.3-1.7-0.1-1.9c0.8-0.6,1.8-1.2,2.7-1.2c6.1,0.2,12.3,0.5,18.4,0.8c0.3,0,0.6,0,0.8,0.1
		c0.7,0.5,1.3,1.1,1.9,1.6c-0.6,0.5-1.2,1.5-1.9,1.5c-1.6,0.2-3.2,0-4.8-0.1c-4.5-0.2-9.1-0.4-13.6-0.6
		C962.9,436.3,961.8,436.1,960.5,436z"
          />
          <path
            class="st0"
            d="M898.7,503.3c-2.4,2.6-4.8,3.3-7.2,1c-2-1.9-2.3-4.1-0.1-6.2c2.5-2.4,5.7-2.7,7.8-0.6
		C901,499.2,900.8,501.2,898.7,503.3z"
          />
          <path
            class="st0"
            d="M923.6,437.6c-1.6,1.9-3.1,4-4.9,5.7c-0.6,0.6-1.8,0.4-2.8,0.6c0-1-0.4-2.2,0.1-2.8c3.8-4.6,7.7-9.1,11.7-13.6
		c0.4-0.4,1.4-0.3,2.1-0.4c0.1,0.8,0.6,1.9,0.3,2.4c-2,2.8-4.2,5.5-6.3,8.2C923.7,437.7,923.7,437.6,923.6,437.6z"
          />
          <path
            class="st0"
            d="M916,437.3c-0.7,0.2-2.1,0.5-3.4,0.8c0.4-1.3,0.6-2.7,1.3-3.7c2.7-3.8,5.6-7.5,8.5-11.2
		c0.7-0.8,2.1-1.1,3.1-1.7c-0.1,1.2,0.3,2.7-0.3,3.5C922.5,428.9,919.5,432.7,916,437.3z"
          />
          <path
            class="st0"
            d="M836.4,341.9c-1,0.2-1.8,0.7-2.3,0.4c-0.5-0.3-1.1-1.4-0.9-1.9c1.5-5.2,3.1-10.4,4.9-15.6
		c0.2-0.5,1.3-0.9,1.9-0.8c0.4,0,1.2,1,1.1,1.3C839.5,330.9,837.9,336.4,836.4,341.9z"
          />
          <path
            class="st0"
            d="M949.7,278.3c-1.1-0.2-3.2-0.2-4.6-1.2c-2.2-1.6-1.8-5.5,0.4-7.4c1.8-1.6,3.8-1.8,5.7,0
		C953,271.6,952.7,275.5,949.7,278.3z"
          />
          <path
            class="st0"
            d="M730.8,403.4c0,0.3-0.2,0.9-0.5,1.3c-0.1-0.8-0.1-1.5-0.2-2.3C730.4,402.7,730.7,403.1,730.8,403.4z"
          />
          <path
            class="st0"
            d="M722.3,388.5c-0.2-0.3-0.4-0.7-0.6-1.1C721.9,387.6,722.1,388,722.3,388.5z"
          />
          <path
            class="st0"
            d="M888.9,273.5c0,0.2-0.2,0.5-0.5,0.9c-0.3,0.1-1,0.4-1.3,0.2c-0.6-0.4-1.4-1.1-1.4-1.7c0-2.6,0-5.2,0-7.8v-0.1
		c1.1-2.4,2-4.8,2.9-7.3c0.1-0.3,0.2-0.5,0.3-0.8c0.1,0.1,0.1,0.2,0.1,0.2C889.1,262.6,889,268.1,888.9,273.5z"
          />
          <path
            class="st0"
            d="M735.9,380.5c0.5-0.8,0.8-2.3,1.4-2.3c5-0.6,10.1-1,15.2-1.3c0.4,0,1.3,0.8,1.3,1.1c0,0.7-0.6,1.8-1,1.9
		c-5.2,0.5-10.4,0.9-15.6,1.3C737,381.2,736.7,381,735.9,380.5z"
          />
          <path
            class="st0"
            d="M886.8,335.3c0.2-2-0.3-4.8,0.8-5.6c1.3-1,4.3-1.1,5.9-0.3c2.3,1.2,2.4,4.1,0.3,5.7c-1.4,1.1-3.7,1-5.6,1.5
		C887.7,336.2,887.2,335.8,886.8,335.3z"
          />
          <path
            class="st0"
            d="M897.3,238.3c0,1.2-0.1,2.4-0.1,3.6c0,0.2-0.3,0.4-0.6,0.9c-0.3,0.1-1,0.5-1.2,0.4c-0.3-0.2-0.7-0.6-1-0.9
		c0.6-1.6,1.4-3.2,2.8-3.8C897.2,238.3,897.3,238.3,897.3,238.3z"
          />
          <path
            class="st0"
            d="M811.9,283.9c-1.9-1.8-1.9-3.8-0.1-5.6c1.9-1.8,4.6-1.9,5.9,0.3c0.9,1.6,1.3,4.6,0.4,6
		C816.3,287.2,813.9,285.5,811.9,283.9z"
          />
          <path
            class="st0"
            d="M793.5,375.6c0.6-1,0.9-2.5,1.6-2.7c3.7-1.1,7.5-1.9,11.2-2.7c0.5-0.1,1.6,0.6,1.7,1.1
		c0.1,0.6-0.3,1.8-0.8,1.9c-4,1.1-8,2-12,2.9C794.9,376.2,794.5,375.9,793.5,375.6z"
          />
          <path
            class="st0"
            d="M867.9,292.9c0,0.4-0.2,0.7-0.6,1.9c-1.1-0.9-2.5-1.5-2.6-2.2c-0.3-2.3-0.5-4.5-0.6-6.8
		c1.1-0.6,2.2-1.2,3.2-1.8C867.5,287,867.7,290,867.9,292.9z"
          />
          <path
            class="st0"
            d="M733.2,367.9c0.4-0.8,0.8-2.3,1.3-2.4c4-0.3,8-0.4,11.9-0.4c0.5,0,1.2,1,1.3,1.6c0.1,0.4-0.6,1.4-1,1.4
		c-4.1,0.2-8.2,0.3-12.3,0.5C734.3,368.6,734.1,368.3,733.2,367.9z"
          />
          <path
            class="st0"
            d="M909.9,242.2c0,0.2,0,0.4,0,0.6c-0.1,0.9-1.2,1.7-1.8,2.6c-0.5-0.8-1.5-1.6-1.5-2.5c-0.1-0.9-0.1-1.8-0.1-2.8
		C907.6,240.8,908.7,241.5,909.9,242.2z"
          />
          <path
            class="st0"
            d="M817.5,331.3c-3.1-0.4-5.8-0.1-6.2-0.8c-0.7-1.6-0.7-4,0-5.7c0.2-0.6,3.9-0.9,4.5-0.1
		C816.9,325.9,816.8,328.1,817.5,331.3z"
          />
          <path
            class="st0"
            d="M793.9,356c-3-0.4-5.5,0-6-0.9c-0.8-1.3-0.8-3.8,0.1-5.1c0.5-0.8,3.8-1,4.6-0.3
		C793.5,350.8,793.3,353.1,793.9,356z"
          />
          <path
            class="st0"
            d="M802.2,416.9c-1.7-0.4-4-0.2-5-1.2c-0.8-0.9-0.6-3.7,0.2-4.7c0.7-0.8,3.7-0.9,4.6-0.1c1.1,0.9,1,3.2,1.4,4.8
		C803.1,416.1,802.6,416.5,802.2,416.9z"
          />
          <path
            class="st0"
            d="M828.4,349.9c-0.5,0.9-0.9,2.4-1.6,2.5c-2.9,0.4-5.8,0.5-8.7,0.4c-0.4,0-0.8-1.8-1.2-2.8
		c3.5-0.5,7.1-0.9,10.6-1.4C827.9,349.1,828.1,349.5,828.4,349.9z"
          />
          <path
            class="st0"
            d="M781.6,428.3c-0.5,2.8-0.2,5.3-1.1,5.9c-1.1,0.7-3.5,0.3-4.8-0.5c-0.7-0.4-0.7-3.4,0-4
		C776.8,428.8,778.8,428.9,781.6,428.3z"
          />
          <path
            class="st0"
            d="M974.9,430.4c-2.8-0.4-4.8,0-5.5-0.9c-0.8-0.9-1-3.4-0.3-4.3c0.6-0.8,3.2-0.9,4.2-0.3
		C974.2,425.6,974.2,427.7,974.9,430.4z"
          />
          <path
            class="st0"
            d="M832.8,308.5c0.7-2.7,0.6-4.6,1.4-5.2c1.1-0.7,2.9-0.3,4.4-0.4c0,1.5,0.5,3.4-0.2,4.2
		C837.7,308.1,835.7,307.9,832.8,308.5z"
          />
          <path
            class="st0"
            d="M744.4,470.6c-0.2,1.2-0.3,2.3-0.5,4c-0.1,0-0.2,0-0.3,0c-0.1-1.4,0.1-2.7,0.5-4
		C744.2,470.6,744.3,470.6,744.4,470.6z"
          />
          <path
            class="st0"
            d="M761.9,454.8c-0.4,1.1-0.6,2.6-1.4,3.1c-0.8,0.5-2.6,0.6-3.3,0c-0.7-0.6-0.5-2.2-0.6-3.3
		c1.2-0.1,2.3-0.2,3.5-0.3C760.7,454.4,761.3,454.6,761.9,454.8z"
          />
          <path
            class="st0"
            d="M804.8,241.7c-0.4,0.5-1,0.7-1.6,1c-0.3-1.1-0.6-2.2-0.8-3.3c0.8-0.4,1.9-0.5,2.8-0.7
		C805,239.7,805.3,241.1,804.8,241.7z"
          />
          <path
            class="st0"
            d="M868.9,338.4c-2.2-2.1-4.3-4.4-6.7-6.3c-0.8-0.7-2.3-0.6-3.7-1c-3.2,6.5-6.2,12.2-8.6,18.1
		c-0.9,2.3,1.3,3.1,3.2,2.2c5.3-2.6,10.4-5.4,15.6-8.2C871.2,341.8,870.8,340.1,868.9,338.4z M855.9,346.3c-0.2-0.2-0.5-0.4-0.8-0.7
		c1.6-3.2,3.2-6.3,5-9.9c2,2,3.6,3.5,5.5,5.3C862.2,342.9,859,344.6,855.9,346.3z"
          />
          <path
            class="st0"
            d="M810.4,389.5c1.2-1,2.3-2.1,3.6-2.9c0.1-0.1,1.2,1.3,1.8,2c-1.3,0.9-2.7,1.8-4,2.7
		C811.4,390.7,810.9,390.1,810.4,389.5z"
          />
          <path
            class="st0"
            d="M953.2,343.9c-6.3,2.4-12.6,5.2-18.9,7.6c-5.3,2-5.6,2.9-1.4,6.9c0.4,0.4,0.8,0.7,1.1,1.1c2.5,3,5,2.9,7.8,0.4
		c4-3.6,8.1-7,11.5-9.9c1.2-1.3,2.1-1.9,2.5-2.6c0.7-1.2,1.1-2.6,1.7-3.9C956.1,343.6,954.5,343.4,953.2,343.9z M939.3,358.5
		c-2.6,1.6-4.1-1-5.4-3.8c4.9-1.8,9.5-3.6,14.1-5.3c0.3,0.4,0.5,0.8,0.8,1.2C945.7,353.3,942.8,356.4,939.3,358.5z"
          />
          <path
            class="st0"
            d="M794.4,517.7c-2.7,0.6-2.9,2.7-0.6,4.7c3.7,3.2,7.5,6.3,11.3,9.5c0.2,0,0.3,0,0.5-0.1c1.4-0.2,2.7-0.5,4-1
		c-4.1-3.4-8-6.7-12.3-10.3c7-1.5,13.1-2.8,20.1-4.3c-0.4,3.6-0.8,7-1.2,10.4c1.4-1.2,2.7-2.6,3.9-4.1c0.5-3.4,0.9-6.9,1.4-10.6
		C811.3,514.1,802.8,515.7,794.4,517.7z"
          />
          <path
            class="st0"
            d="M865.3,480.1c-2.6-2.8-5.5-5.4-8.3-8.2l0,0c-2.8-2.7-5.5-5.5-8.4-8.1c-2-1.8-3.5-1.4-3.8,1.3
		c-0.7,6.3-1.2,12.5-1.7,18.8c-0.2,2.3,1,3.5,3.4,3.2c5.7-0.7,11.4-1.4,17.1-2.3C867.1,484.4,867.7,482.7,865.3,480.1z M846.5,484
		c0.4-5.1,0.8-9.6,1.3-15.6c5,4.9,9.1,8.9,13.8,13.4C856.5,482.5,851.9,483.2,846.5,484z"
          />
          <path
            class="st0"
            d="M993.6,373c-7-3.2-10.5-0.8-10.3,6.9c0,1.4,0.2,2.9,0.4,5.3c5.1-2.6,8.5-5.7,11.2-9.6
		C995.3,375.2,994.4,373.4,993.6,373z M986.7,380.8c-0.4-0.1-0.7-0.3-1.1-0.4c0.2-1.9-0.3-4.4,0.7-5.4c1-1,3.5-0.5,5.4-0.6
		c0.1,0.3,0.2,0.7,0.4,1C990.2,377.2,988.5,379,986.7,380.8z"
          />
          <path
            class="st0"
            d="M822.2,474.5c-0.6-2.6-3.2-3.2-4.3-2c-3.2,4-6.1,7.5-8.8,11.1c-0.7,0.9-0.9,2.6-0.6,3.6c0.2,0.6,2,0.9,3.1,0.9
		c2.8-0.2,5.6-0.3,8.3-1c1.3-0.4,3.2-2,3.3-3.1C823.4,480.8,822.9,477.6,822.2,474.5z M813.8,484.6c-0.1-0.3-0.3-0.6-0.4-1
		c1.6-2,3.3-4.1,5.7-7c0.4,2.9,0.7,4.9,1.1,7.2C817.8,484.1,815.8,484.3,813.8,484.6z"
          />
          <path
            class="st0"
            d="M859.9,512.2c-1.8,2.4-3.7,4.7-5.9,7.6c-2.3-0.1-4.5-0.3-6.7-0.5c0-0.1-0.1-0.3-0.1-0.4
		c-0.4-1.7-0.7-3.6-0.2-5.2c0.4-1.4,1.8-3.1,3-3.5c2.6-0.8,5.5-1,8.3-1.2C861,508.8,861.4,510.2,859.9,512.2z"
          />
          <path
            class="st0"
            d="M1010.3,343.2c-5.7-5.7-5.7-5.6-10.9,0.1c-0.7,0.8-1.6,1.4-2.2,2.3c-0.8,1.1-1.3,2.4-1.9,3.7
		c1.3,0.3,2.7,0.9,4,0.8c3-0.2,6.1-0.7,9.1-1.3c1.6-0.3,3-1.2,4.5-1.8C1012.1,345.7,1011.4,344.3,1010.3,343.2z M1008.8,346.2
		c-2.9,0.5-5.7,1-8.6,1.4c-0.2-0.4-0.4-0.7-0.5-1.1c1.6-1.6,2.9-3.9,4.8-4.6c1.1-0.4,3.1,1.7,4.7,2.7
		C1009.1,345.2,1008.9,345.7,1008.8,346.2z"
          />
          <path
            class="st0"
            d="M1175.5,340.6c-26.7,16.6-53.4,33.2-80.1,49.8c-3.9,2.5-4,3.3-0.7,6.5c6.9,6.5,13.8,13,20.8,19.5
		c0.6-1.4,1-2.8,1.2-4.3c-1.3-0.7-2.4-2-3.3-3.8c3.2-0.7,6.3-1.5,9.5-2.2c0.5-1.2,0.8-2.5,1.2-3.8c0,0,0,0,0-0.1
		c-3.8,0.8-7.5,1.6-11.3,2.4c-1.6,0.3-3.8-0.2-5.2-1.1c-2-1.2-3.5-3.2-5.4-5.1c9.6-2.3,19.1-4.6,28.8-7c1-1.4,1.8-2.9,2.5-4.5
		c0-0.1,0-0.1,0.1-0.2c-10.9,2.6-21.8,5.2-32.7,7.7c-1,0.2-2,0-3.1,0c-0.1-0.4-0.2-0.9-0.4-1.3c6.8-3.1,11.6-9,20-10.1
		c6.2-0.8,12.3-2.1,18.4-3.5c0.1-0.4,0.3-0.8,0.5-1.2c0.5-1.1,1.2-2.2,1.9-3.2c-4.8,1.1-9.6,2.1-14.4,3.2c-0.6,0.1-1.2,0.1-3,0.2
		c7.7-4.8,14.5-9.1,21.3-13.2c0.5-0.3,1.3-0.5,1.9-0.5c2.3,0.1,4.5,0.1,6.8,0.2c1.8-1.2,3.5-2.4,5.3-3.6c-2.1,0.1-4.2,0.2-6.3,0.3
		c-0.1-0.4-0.2-0.8-0.3-1.2l23.6-14.7c0.3,0.2,0.6,0.4,0.8,0.6c-0.7,1.2-1.3,2.4-2,3.6c0.6-0.5,1.1-1.1,1.6-1.7
		c2.2-2.7,3.5-5.8,4.4-9.2C1177,339.8,1176.2,340.2,1175.5,340.6z"
          />
          <path
            class="st0"
            d="M1146.5,181.3c0-0.2,0-0.4,0-0.7c-1.5,0.9-3.2,1.9-5.3,3.1c0.1-5.5,0.2-9.7,0.3-14c0.4-0.2,0.8-0.4,1.1-0.6
		c0.8,0.8,1.6,1.6,2.5,2.4c-0.4-1.5-0.9-3-1.4-4.5c-3.3-3-6.6-6-9.9-8.9c-1.5,0.2-3.1,0.7-4.6,1.2c0.3,1.2,0.4,2.4,0.3,3.7
		c-0.6,8.5-1.1,17.1-1.8,25.6c-0.1,1.6-1.1,3.6-2.3,4.7c-2,1.6-4.5,2.6-7.6,4.3c1-12.4,2-24.2,3-35.9c-1.1,0.1-2.1,0-3.2-0.1
		c-0.8,11.1-1.7,22.2-2.4,33.2c-0.3,4.5-2.6,6.7-7.3,8.2c0-2,0-3.4,0.1-4.8c0.6-10.1,2.1-20.3,1.6-30.4c-0.2-3,0.3-5.8,1-8.3
		c-0.9-0.4-1.8-0.8-2.7-1.2c-2.2,4.9-4.3,9.8-6.6,14.7c-7.2,15.4-14.6,30.7-21.9,46c-0.5,1.1-0.8,2.4-1.2,3.6
		c0.4,0.3,0.7,0.6,1.1,0.9c3.2-1.8,6.4-3.7,9.6-5.5c19.3-11.3,38.5-22.7,57.8-34C1146.6,182.9,1146.5,182.1,1146.5,181.3z
		 M1095.5,211c-3.6,2.2-7.3,4.2-10.9,6.3c-0.3-0.3-0.7-0.5-1-0.8c3.4-7.4,6.7-14.8,10.1-22.2c0.6,0.1,1.2,0.2,1.9,0.3
		c0.4,4.8,0.8,9.7,1.1,14.5C1096.7,209.7,1096.1,210.6,1095.5,211z M1107,173.2c-0.6,9.7-1.4,19.4-2,29.1c-0.2,2.9-1.4,4.7-5.2,4.9
		c-0.4-2.8-0.2-5.7-1.2-8.1c-3.9-9.9,1.1-18,5-26.3c0.6-1.3,1.4-2.5,2.1-3.7c0.3,0.1,0.7,0.1,1,0.2
		C1106.8,170.6,1107.1,171.9,1107,173.2z M1138,184.2c-0.3,2.2-4,3.8-6.2,5.7c-0.5-0.3-1-0.6-1.5-0.9c0.6-9,1.2-18.1,1.9-27.1
		c0.7-0.2,1.3-0.4,2-0.7c1.3,2.1,3.6,4.1,3.8,6.3C1138.6,173,1138.8,178.7,1138,184.2z"
          />
          <path
            class="st0"
            d="M1080.8,236.1c-19.1,4-38.2,8.1-57.3,12.1c-3.9,0.8-5.3,3-5.9,6.9c-2.6,16.5-5.6,32.9-8.5,49.4
		c-0.4,2.4-1.1,4.7-1.5,7.1c-1,5.3,0.3,6.3,5.5,4.6c0.5-0.2,1-0.4,1.5-0.6c17.6-7.5,35.4-14.4,54.2-18.5c1.6-0.4,3.4-2.8,4-4.6
		c0.9-2.9,0.6-6.1,1.3-9.1c3-14.1,6.1-28.2,9.1-42.4c0.3-1.5,0.3-3,0.5-4.8C1082.2,236.1,1081.5,236,1080.8,236.1z M1077.4,248.4
		c-2.6,13.5-5.4,27-7.8,40.6c-0.5,3-1.4,4.7-4.6,5.4c-16.6,3.5-32.3,9.5-47.8,16.2c-1.7,0.7-3.4,1.4-5.7,2.3
		c0.1-1.5,0.2-2.5,0.3-3.4c3.1-17.9,6.2-35.8,9.5-53.7c0.3-1.4,1.8-3.5,3.1-3.8c17.7-3.8,35.4-7.3,53.1-10.9c0.2,0,0.5,0.1,1.5,0.2
		C1078.5,243.8,1077.9,246.1,1077.4,248.4z"
          />
          <path
            class="st0"
            d="M1092.7,159.3c-4,0.9-8.1,1.4-13.2,2.2c1.8,1.8,3.4,3.1,4.7,4.6c0.6,0.7,0.5,1.8,0.8,2.7
		c-0.9-0.2-2.1,0-2.7-0.5c-1.8-1.5-3.4-3.2-6-5.7c-0.5,4.7-0.8,8.3-1.4,11.7c-0.2,1.2-1.5,2.3-2.3,3.4c-0.3-1.3-1-2.6-1-3.8
		c0.1-3.1,0.6-6.2,0.4-9.5c-5.2,5.4-12.4,9-13.5,17.6c-0.5,4.1-2.1,8-3.5,11.9c-0.3,0.9-1.7,1.5-2.6,2.3c-0.2-1.1-0.8-2.2-0.5-3.2
		c1.3-4.7,2.7-9.4,4.5-15.4c-5.2,3-9.2,5.5-13.4,7.8c-1,0.5-2.5,0.3-3.8,0.4c0.7-1,1.1-2.4,2.1-3c4-2.6,8.5-4.6,12.3-7.5
		c4.6-3.4,8.9-7.2,13.3-10.9h0c2.6-1,4.6-2.5,5.4-5.3c0.5-1.6,0.5-3.4,1.5-4.6c0.6-0.7,1.3-1.1,2.1-1.3c0,0.5,0,1,0.1,1.4
		c0.6-0.6,1.2-1.2,1.7-1.7c0.4,0,0.8-0.1,1.2-0.1c1.2,0,2.4-0.1,3.5-0.1c-1.1,1.2-2.2,2.3-3.4,3.4c0.1,0.5,0.3,1,0.4,1.5
		c4.1-0.5,8.2-1.1,12.3-1.5c1.3-0.1,2.6,0.4,3.9,0.6C1094.8,157.7,1093.8,159,1092.7,159.3z"
          />
          <path
            class="st0"
            d="M1188.6,293.3c-0.4,0.3-0.7,0.6-1.1,0.9c0.1,0.3,0.1,0.6,0.2,0.9c1.1,5,1.1,5-4.2,6.3
		c-4.9,1.2-5.4,1.7-4.4,6.6c0.6,3.1-0.2,4.2-3.4,3.9c-2.9-0.2-5.9-0.1-8.8-0.2c-0.1,0.4-0.1,0.9-0.2,1.3c1.2,0.4,2.4,1.1,3.7,1.2
		c2,0.2,4,0.3,6,0.2c2.9-0.2,4.2,1.1,4.3,3.9c0,0.8,0.1,1.6,0.2,2.5c0.2-0.9,0.4-1.7,0.7-2.5c0.6-2.1,1.5-4.2,2.4-6.3
		c-1.6-0.5-2.1-1.7-2.3-4c-0.3-3.2,1.3-5.1,5.6-4.6c0.1,0,0.1,0,0.2,0C1188.6,300.1,1189.2,296.8,1188.6,293.3z"
          />
          <path
            class="st0"
            d="M989.5,228.9c1.3-0.3,2.7-0.7,4-1.2c-0.1,1.6-0.2,3.2-0.2,4.7c-0.1,3.5-1.5,4.8-5,4.3
		c-3.7-0.5-7.5-0.5-11.2-0.9c-3.6-0.4-5.2,1-5.7,4.7c-1,7.5-2.6,15-3.9,22.5c-0.1,0.8-0.1,1.7-0.6,2.3c-0.5,0.8-1.4,1.2-2.2,1.8
		c-0.5-0.8-1.6-1.6-1.5-2.4c0.1-2,0.6-4,1-5.9c0.1-0.3,0.1-0.6,0.2-1c0.8-0.5,1.5-1.1,2.2-1.8c5.3-5.9,3-14.8,3.3-22.9
		c1.4-0.4,2.7-1,4-1c4-0.1,8-0.2,12,0.2c2.9,0.3,3.8-0.7,3.8-3.4V228.9z"
          />
          <path
            class="st0"
            d="M1024.1,190.3c-1.5,6.2-3.1,12.4-4.6,18.6c-0.6,2.7-2.1,4-5,4.2c-2.2,0.2-4.4,0.4-6.6,0.6c0-0.1,0-0.1,0.1-0.2
		c0.3-1.2,0.4-2.5,0.4-3.8c1.1-0.1,2.3-0.2,3.4-0.3c2.7-0.2,4.1-1.4,4.7-4c1-4.4,2-8.9,3.1-13.3
		C1021.1,191.6,1022.6,190.9,1024.1,190.3z"
          />
          <path
            class="st0"
            d="M1048.3,210.4c-1.3,0.4-2.5,0.7-3.6,1c-6.3,1.7-12.6,3.6-18.9,5.2c-2.7,0.7-3.4,2.3-3.4,5
		c0,4.9-0.6,9.9-0.7,14.8c-0.1,3.6-1.9,5.4-5.4,5.6c-4.9,0.3-9.9,0.8-14.8,1c-2.6,0.1-3.6,1-3.5,3.6c0.1,4.9-0.1,9.9,0,14.9
		c0.1,3.6-1.5,5.3-5,6c-7,1.3-13.9,2.9-20.8,4.2c-1.1,0.2-2.3-0.5-3.4-0.8c0.8-0.9,1.4-2.5,2.4-2.7c6.2-1.6,12.5-3,18.8-4.1
		c3.1-0.5,4.3-1.6,4.1-4.8c-0.3-4.5-0.1-9.1,0.1-13.6c0.2-4.5,1.5-5.9,6.1-6.4c4.5-0.4,9.1-0.8,13.6-0.9c3.2,0,4.2-1.3,4.2-4.3
		c0.1-4.9,0.3-9.9,0.8-14.8c0.2-1.6,1.2-4.1,2.4-4.5c8.4-2.7,16.9-5.1,25.3-7.4c0.6-0.2,1.8,0.3,2.2,0.9
		C1048.9,208.5,1048.4,209.5,1048.3,210.4z"
          />
          <path
            class="st0"
            d="M1117.3,358.8c-1.9,0.8-3.6,2-5.5,2.3c-3.9,0.7-6.3,2.8-7.3,6.5c-1.6,5.7-4.4,10-10.5,12.1
		c-2.4,0.8-3.9,4.1-5.7,6.3c-0.5,0.6-0.5,1.6-1.1,2.1c-1.2,1.1-3.1,3.2-3.9,2.9c-2.8-1.1-0.6-3,0-4.6c2.2-5.8,5.6-10.4,12.1-12.4
		c1.7-0.5,3.3-3.1,3.9-5.1c1.9-6.1,4.7-10.8,11.7-12.2c1.9-0.4,3.5-3.2,4.8-5.3c1.6-2.6,2.3-6,4.3-8c2.2-2.2,5.4-3.9,8.5-4.5
		c5.7-1.2,7.5-2.3,8.9-8.1c1.2-5.2,2.7-10.2,7.7-13c3.4-1.9,7.1-2.7,10.7-0.7c0.8,0.5,1.2,1.9,1.8,2.8c-1,0.3-2.1,1-3.1,0.9
		c-6.9-0.8-10.1,1.3-12.1,8c-0.6,2.1-1,4.2-1.8,6.2c-1.6,4.4-4.7,7.4-9.4,7.6c-5.6,0.3-8.7,3.2-10.5,8.3
		C1119.9,353.6,1118.5,356.1,1117.3,358.8z"
          />
          <path
            class="st0"
            d="M1127.8,230.1c-9.7,3-19.4,6.1-29,9.2c-3.8,1.2-4.1,2.2-1.6,5.3c7.5,9.2,15,18.4,22.5,27.6
		c0.9,1.1,2,1.9,3.5,3.4c1.2-2.4,2.6-4.2,2.9-6.2c2.1-12,3.9-24,5.8-36C1132.4,229.8,1131.4,229,1127.8,230.1z M1122.7,269.3
		c-0.3,0.1-0.7,0.2-1,0.3c-7.1-8.8-14.2-17.7-21.9-27.1c9.7-3.1,18.7-5.9,28.5-9.1C1126.4,245.9,1124.6,257.6,1122.7,269.3z"
          />
          <path
            class="st0"
            d="M1131.5,298.5c-0.6,0.3-1.2,0.6-1.7,0.9v0c-0.6,0.3-1.2,0.6-1.8,0.8c-6.1,2-12.2,4.1-18.3,6
		c-2.7,0.8-4,2.4-4.2,5.1c-0.5,5.2-1.3,10.4-1.8,15.6c-0.3,3.2-1.9,4.8-5,5.2c-3.6,0.4-7.1,1.1-10.7,1.4c-3.6,0.3-5.2,1.7-4.7,5.6
		c0.4,3.4,0.3,7-0.2,10.4c-0.3,1.6-1.8,4-3.2,4.4c-1.4,0.4-2.9,0.9-4.3,1.3c-0.7-0.7-1.4-1.4-2.2-2.2c1-0.2,1.9-0.5,2.9-0.7
		c3.1-0.7,4.4-2.3,4.2-5.3c-0.2-3.5-0.1-7-0.6-10.4c-0.4-3.5,0.7-5.3,4.3-5.6c3.9-0.3,7.7-1.1,11.6-1.4c3.3-0.2,5-1.5,5.3-5.1
		c0.4-5.1,0.9-10.1,1.9-15.1c0.4-1.8,1.9-4.1,3.5-4.7c7.3-2.7,14.7-5,22.1-7.3c0.2-0.1,0.4-0.1,0.7-0.1c0.7,0,1.4,0.1,2.1,0.1
		C1131.3,297.8,1131.4,298.2,1131.5,298.5z"
          />
          <path
            class="st0"
            d="M1108.5,277.3c-0.5,2.3-0.8,4.6-1.4,6.8c-1.6,5.5-3.4,11-5,16.5c-1.1,3.7-3.5,5-7.1,5.2
		c-4.3,0.3-8.5,1.1-12.8,1.6c-2.6,0.3-3.8,1.6-3.9,4.1c-0.2,4-0.6,8-1,12c-0.6,5.7-1.6,6.8-7.4,7.4c-3.7,0.4-7.5,0.8-11.2,0.9
		c-3.2,0.1-4.7,1.3-5.3,4.6c-0.7,4.2-1.7,8.4-2.7,12.5c-1,0.3-2,0.6-3,0.9c0.5-1.9,1-3.8,1.4-5.8c0.7-3.3,1.3-6.6,1.8-9.9
		c0.6-3.4,2.3-5.2,5.9-5.3c4.4-0.2,8.8-0.7,13.2-1c2.8-0.2,4.1-1.4,4.3-4.2c0.3-4.5,0.9-9.1,1.2-13.6c0.2-3.5,1.7-5.3,5.4-5.6
		c4.8-0.4,9.6-1,14.3-2c1.6-0.3,3.8-1.8,4.3-3.3c2.2-6,3.8-12.2,5.6-18.4c0.4-1.4,1.1-2.7,1.7-4
		C1107.4,276.9,1107.9,277.1,1108.5,277.3z"
          />
          <path
            class="st0"
            d="M1146.7,308.8c-1.5,1-2.8,2.1-4.4,2.8c-5.5,2.4-9.6,6.1-12.2,11.6c-2.9,6.4-7.6,10.9-14.6,12.8
		c-3.5,0.9-5.7,3.3-7.1,6.6c-2.3,5.3-5.9,9.2-11.5,11.5c-2.3,0.9-4.3,3.9-5.3,6.5c-0.4,1.1-0.9,2.2-1.4,3.1c0-0.2-0.1-0.4-0.2-0.6
		c-0.4-1-1.4-1.3-2.6-1.4c0.3-0.6,0.5-1.2,0.7-1.9c1.2-4.7,4.4-7.5,8.7-9.8c3-1.6,5.8-4.4,7.5-7.3c3.1-5.4,6.8-9.5,12.9-11.1
		c4.5-1.2,7.1-4.6,9-8.6c3.4-7.3,8.7-12.4,16.2-15.2c1.1-0.4,2.5-0.3,3.7-0.4C1146.3,307.8,1146.5,308.3,1146.7,308.8z"
          />
          <path
            class="st0"
            d="M1162.5,228.2c-6.4-2.2-12.9-4.4-19.3-6.6c-2.7-1-5.4-2-8.1-3c-0.4,0.6-0.8,1-0.8,1.3
		c0.2,12.8,0.4,25.6,0.8,38.5c0,0.9,1.1,2.4,1.6,2.4c0.5,0,1.1-0.1,1.6-0.3c0.7-0.2,1.3-0.6,1.8-1.1c0.2-0.2,0.3-0.4,0.5-0.5
		c0,0,0,0,0,0c7.4-8,14.8-16.1,22.1-24.3C1162.7,232.5,1162.6,230.3,1162.5,228.2z M1140.1,253.3c-0.1-8.8-0.3-19.1-0.5-29.4
		c6.3,2.2,13.6,4.6,21.8,7.4C1153.4,239.6,1146.2,247,1140.1,253.3z"
          />
          <path
            class="st0"
            d="M1161.5,218.9c-7.1-2.2-13.9-4.4-21.4-6.7c3.7-3.8,7.2-7.4,10.7-11c0-1.8,0.4-3.7,0.9-5.5
		c-4.5,4.6-9,9.2-13.5,13.9c-2.9,3.1-2.3,4.9,1.8,6.3c7.3,2.6,14.7,5.1,22,7.7C1162,221.9,1161.8,220.4,1161.5,218.9z"
          />
          <path
            class="st0"
            d="M1171.9,294c-2.7,2.7-5.5,5.5-8.2,8.1c-1,0.9-1.9,1.9-2.9,2.8c-1.3,1.2-3.1,2-4.7,2.9
		c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2-0.4-0.3-0.7-0.3-1.1c-0.4-2.4,2.2-3.2,3.5-4.6c1.3-1.3,2.5-2.6,3.8-4c2.1-2.2,4.2-4.4,6.3-6.6
		C1169.8,292.7,1170.9,293.3,1171.9,294z"
          />
          <path
            class="st0"
            d="M1020.6,322.3c0.5-1.3,0.6-2.4,1.1-2.8c1-0.8,2.2-1.5,3.5-1.9c11.2-3.5,22.5-7,33.7-10.3
		c1.1-0.3,2.6,0.4,3.8,0.6c-1,1-1.8,2.5-3,2.9c-12,3.9-24,7.6-36,11.3C1023,322.3,1022.1,322.2,1020.6,322.3z"
          />
          <path
            class="st0"
            d="M1005.8,265.4c0.2,1.1,0.6,2,0.5,2.9c-0.8,10.4-1.7,20.7-2.5,31.1c0,0.4,0,0.9-0.2,1.2
		c-0.9,0.9-1.8,1.6-2.7,2.4c-0.3-1-1.1-2.1-1-3.1c0.6-6.5,1.4-13,2-19.5c0.4-4,0.4-8,0.7-12c0.1-1,0.7-2,1.3-2.7
		C1004,265.3,1005,265.5,1005.8,265.4z"
          />
          <path
            class="st0"
            d="M1033,237.5c1.6-1.7,2.3-3,3.3-3.3c3.2-0.9,6.5-1.7,9.8-2.2c7.3-1.1,14.6-2.2,21.9-3c1.1-0.1,2.3,0.7,3.4,1.1
		c-1,0.9-1.8,2.3-2.9,2.5c-10.4,1.8-20.9,3.4-31.3,5C1036.3,237.8,1035.4,237.6,1033,237.5z"
          />
          <path
            class="st0"
            d="M1074.5,181.4c-2.9-1.1-4.1,0.4-4.3,2.9c-0.4,6.3-0.7,12.6-0.9,18.9c0,1,0.5,1.9,1.1,4.2
		c6-7.6,11.2-14.3,17-21.6C1082.7,184.2,1078.6,182.9,1074.5,181.4z M1071.6,201.3c0.3-5.8,0.5-11.6,0.8-18.1
		c4,1.3,7.4,2.5,11.4,3.8c-4.1,5.3-7.7,9.9-11.3,14.6C1072.1,201.4,1071.8,201.4,1071.6,201.3z"
          />
          <path
            class="st0"
            d="M1082.8,282.7c-0.1-1.2-0.4-2.4-0.2-3.5c1.5-9.3,3.1-18.7,4.8-28c0.2-1.3,1.5-2.4,2.2-3.6
		c0.5,1.3,1.5,2.7,1.3,4c-1.4,9.2-2.9,18.4-4.5,27.6c-0.2,1.4-1.3,2.6-2,3.9C1083.9,282.9,1083.4,282.8,1082.8,282.7z"
          />
          <path
            class="st0"
            d="M1144.2,195.5c-9.6,7.1-18.3,13.5-27.1,19.8c-0.7,0.5-2.1,0.2-3.2,0.3c0.3-1,0.2-2.4,0.9-2.9
		c8.5-6.2,17.1-12.4,25.6-18.5c0.3-0.2,0.7-0.6,1-0.6c1,0,2.1,0.1,3.1,0.2C1144.4,194.7,1144.2,195.6,1144.2,195.5z"
          />
          <path
            class="st0"
            d="M995.1,290.9c-2.9,2.4-7.6,2.1-9.8-0.5c-1.8-2.2-1.3-5.4,1.2-7.5c2.7-2.2,6.9-1.8,9,1
		C997.4,286.5,997,288.9,995.1,290.9z"
          />
          <path
            class="st0"
            d="M1163,258.4L1163,258.4c-0.1,1.7-0.2,3.3-0.3,5c-2-2.5-3.9-4.9-5.9-7.3c-0.6-0.7-0.4-2.1-0.5-3.1
		c1.1,0.3,2.5,0.3,3.1,1C1160.6,255.4,1161.8,256.9,1163,258.4z"
          />
          <path
            class="st0"
            d="M1034.1,324c2.3-2.3,6-2.6,7.9-0.6c2,2,1.6,5.1-0.9,7.4c-2.5,2.4-6.3,2.6-8.3,0.6
		C1030.9,329.3,1031.4,326.6,1034.1,324z"
          />
          <path
            class="st0"
            d="M1119,222.9c0.2-1.2-0.2-3.5,0.8-5c1.8-2.7,5.7-2.7,7.8-0.6c2,2.1,1.2,4.1-0.5,6
		C1124.9,225.6,1122.6,226.2,1119,222.9z"
          />
          <path
            class="st0"
            d="M1108.5,225.4c-3.3,3.4-5.6,3.8-8,1.4c-1.9-1.9-2.2-3.9-0.1-5.9c2.4-2.4,6.2-2.8,7.7-0.2
		C1109,222.1,1108.5,224.4,1108.5,225.4z"
          />
          <path
            class="st0"
            d="M1056.2,224.2c-3.3,3.3-5.8,3.7-8.2,1.6c-2.1-1.9-1.2-4.2,0.3-6.1c1.9-2.3,6.2-2.9,7.4-0.8
		C1056.7,220.7,1056.2,223.1,1056.2,224.2z"
          />
          <path
            class="st0"
            d="M1100.9,261.6c2.2,3.3,2.9,5.6,0.9,8c-2.5,2.9-5,0.9-7-0.8c-2-1.7-2.3-4.4-0.1-5.9
		C1096.4,261.8,1098.8,262,1100.9,261.6z"
          />
          <path
            class="st0"
            d="M1010.9,229.9c-2.1-0.2-4.6,0.1-6.3-0.9c-2.2-1.3-2-3.8-0.2-5.5c2-1.9,4.6-2.2,6.4,0c1,1.2,0.9,3.3,1.3,5
		C1011.7,228.9,1011.3,229.4,1010.9,229.9z"
          />
          <path
            class="st0"
            d="M984.4,250.4c-0.3,1.7-0.1,3.9-1,5.1c-1.5,1.9-3.9,2.5-6,0.6c-1.9-1.8-1.7-4.2,0.3-5.6c1.4-1,3.6-1,5.4-1.4
		C983.6,249.5,984,250,984.4,250.4z"
          />
          <path
            class="st0"
            d="M1093.1,320.2c-2.7-0.3-5.4-0.2-5.5-0.7c-0.4-1.8-0.3-3.9,0.4-5.5c0.2-0.5,3.7-0.6,4.1,0.1
		C1093,315.4,1092.8,317.4,1093.1,320.2z"
          />
          <path
            class="st0"
            d="M1035.4,203.5c-2.9-0.3-5.2,0-5.9-0.9c-0.8-1.1-1-3.9-0.2-4.8c0.8-0.9,3.6-1,4.8-0.2
		C1035,198.3,1034.9,200.7,1035.4,203.5z"
          />
          <path
            class="st0"
            d="M1066.9,346.6c-0.9-0.9-1.8-1.8-2.7-2.7c-0.7-0.7-1.4-1.3-2.3-1.6c0.7-0.8,2.8-0.5,4.3-0.8
		C1066.4,342.9,1066.6,344.4,1066.9,346.6z"
          />
          <path
            class="st0"
            d="M1118,294.6c-2.5-0.3-3.8-0.5-5.2-0.7c0.2-1.3-0.1-3,0.7-3.8c0.8-0.8,2.5-0.6,3.8-0.8
		C1117.4,290.7,1117.6,292.1,1118,294.6z"
          />
          <path
            class="st0"
            d="M1059.6,254.3c-7.4,1.8-14.9,3.3-22.3,4.9c-1.5,0.3-3.1,0.8-4.8,1.3c-0.5,1.3-1.1,2.4-1.4,3.7
		c-2,9.1-3.9,18.3-5.7,27.5c-0.9,4.5,0.6,6,4.9,4.5c9.4-3.2,18.7-6.5,27.9-10.1c1.6-0.6,3.4-2.9,3.6-4.6c0.9-8.1,1.3-16.2,1.9-24.4
		C1063.8,254.1,1062.3,253.6,1059.6,254.3z M1060.5,261.1c-0.5,6.7-0.9,13.4-1.7,20c-0.1,1.1-1.5,2.6-2.7,3
		c-8.4,3-16.9,5.8-25.4,8.7c-0.5,0.2-1,0.3-2.2,0.7c0.5-2.9,0.8-5,1.2-7.1c1.4-6.8,2.6-13.7,4.3-20.4c0.3-1.4,2.1-3.1,3.4-3.5
		c6.5-1.6,13.1-2.8,19.6-4.2C1059.5,257.8,1060.7,258.6,1060.5,261.1z"
          />
          <path
            class="st0"
            d="M1120.4,249.4c-3.4,2.4-5.7,3-8.1,0.5c-1.8-1.9-2.3-3.9-0.2-6c2.3-2.4,6.2-2.8,7.8-0.3
		C1120.8,245.1,1120.3,247.5,1120.4,249.4z"
          />
          <path
            class="st0"
            d="M1150.7,239.7c-3,1.7-5,2-6.7-0.1c-1.7-2.1-1.7-4.2,0.3-6c1.7-1.6,4.1-2.5,5.5-0.2
		C1150.9,235.1,1150.5,237.6,1150.7,239.7z"
          />
          <path
            class="st0"
            d="M1159.7,210.9c-1.8,1.8-3.9,2.2-6.1,0c-1.5-1.6-2.4-3.2-1.2-5c1.5,1.9,4,3,6.1,2
		C1159,208.8,1159.3,209.8,1159.7,210.9z"
          />
          <path
            class="st0"
            d="M1049.9,281.5c-3.7,0.7-7.4,1.6-11.2,2.1c-0.7,0.1-2.3-1.8-2.2-2.8c0-2.9,0.3-5.9,1.2-8.7
		c0.4-1.3,2.1-3,3.4-3.3c12.9-2.9,11.2-4.1,10.3,8.9C1051.3,278.8,1050.6,279.8,1049.9,281.5z"
          />
        </g>
      </svg>
    </div>
  );
}

export default AnimeBackground;
