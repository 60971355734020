import React from "react";
import { Col, Container, Row } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "transparent",
        }}
        className="section pb-2"
      >
        <Container fluid>
          <Container>
            <Row>
              <Col lg={6} className={``}>
                <h4 className=" text-700 text-white">
                  <b>Exibex</b> is committed to recognizing the exceptional achievements of
                  visionaries, pioneers, and daring trailblazers who continuously redefine the
                  boundaries of their respective domains. The Finnovex Awards serve as a steadfast
                  tribute to our relentless pursuit of acknowledging and applauding the unwavering
                  efforts of individuals, institutions, and service providers within the dynamic
                  realm of financial services.
                  <br />
                  <br />
                  Beyond mere acknowledgment, the Finnovex Awards represent a profound emblem of
                  unparalleled innovation, illuminating a path towards unwavering excellence. With
                  steadfast pride, we honour and celebrate exceptional contributions within the
                  banking and financial services arena, driven by a singular purpose: to spotlight
                  organisations and individuals who consistently deliver outstanding performance.
                  <br />
                  <br />
                  These prestigious awards not only establish an uncompromising standard of
                  excellence but also serve as an unequivocal platform for financial institutions to
                  showcase their prowess and identify the vanguards of the industry. Yet, their
                  significance transcends mere recognition; they possess the transformative power to
                  ignite a fervour for achievement within other enterprises, catalysing them to
                  surge beyond their objectives, inspired by a benchmark that celebrates the zenith
                  of accomplishment.
                </h4>
                <br />
              </Col>
              <Col lg={6} className={`mb-3 `}>
                <img
                  src={require("assets/images/awards/DSC08918.jpg")}
                  width="100%"
                  alt="awards"
                  style={{
                    borderRight: "10px solid #c0dea7",
                    borderTop: "10px solid #c0dea7",
                  }}
                />
              </Col>

              {content.map((data, index) => (
                <Col lg={3} key={index} className="stat-div border">
                  <div className="d-flex align-items-center" style={{ minHeight: "125px" }}>
                    <h5 className="text-700 mt-0 text-white">{data}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "Excellence in Mobile Banking",
  "Excellence in Digital Banking",
  "Excellence in Fintech",
  "Excellence in Payments",
  "Excellence in Cyber Security",
  "Excellence in Customer Experience Enhancement",
  "Excellence in InsurTech",
  "Excellence in Open Banking",
  "Excellence in Cloud Banking",
  "Outstanding Contribution to Financial Inclusion Initiative",
  "Outstanding Contribution to Strategic National Development Initiatives",
  "Outstanding Contribution to Fintech Initiatives",
  "Finnovator of the Year, South Africa",
  "Finfluencer of the Year,South Africa",
  "Financial Women Trailblazer of the Year",
  "Disruptive Techblazer of the Year ",
  "InsurTech Personality of the Year",
  "CEO of the Year",
  "COO of the Year",
  "CDO of the Year",
  "CIO of the Year",
  "CTO of the Year",
  "CFO of the Year",
  "CISO of the Year",
];
