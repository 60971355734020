import React from "react";
import { Container, Row, Col } from "reactstrap";

function WhyAttend() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={11}>
            <h2 className="text-700 text-white mt-5">
              Sponsors and exhibitors reaped significant benefits at the 2023 Global Series
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-white mt-5">
            <div className="mb-3">
              <h4 className="text-700 mt-0">Network Expansion</h4>
              <h5 className="text-400 mt-0">
                90% successfully formed new connections, fostering lucrative business opportunities.
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Successful Product Launch</h4>
              <h5 className="text-400 mt-0">
                76% chose the event as the prime platform for product launches, gaining substantial
                visibility and engagement.
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Market Entry Recognition</h4>
              <h5 className="text-400 mt-0">
                87% acknowledged the Finnovex Summit's importance for entering Middle East, Africa,
                and Europe markets, highlighting its strategic significance in facilitating global
                market access
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default WhyAttend;

const content = [
  { name: "UAE", image: "uae.png" },
  { name: "KSA", image: "ksa.png" },
  { name: "Qatar", image: "qatar.png" },
  { name: "Israel", image: "israel.jpg" },
  { name: "Bahrain", image: "bahrain.jpg" },
  { name: "Kuwait", image: "kuwait.png" },
  { name: "Oman", image: "oman.jpg" },
  { name: "Lebanon", image: "lebanon.jpg" },
  { name: "Jordan", image: "jordan.png" },
  { name: "Egypt", image: "egypt.jpg" },
];
