import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { size } from "./common";

function NavbarDiv({ home }) {
  const [navbarColor, setNavbarColor] = React.useState(!home ? "" : "navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(true);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setNavbarColor("");
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setNavbarColor("navbar-transparent");
        setScroll(true);
      }
      if (!home) {
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={classnames("fixed-top bg-theme", navbarColor)}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/" target="_blank" className="p-0" tag={Link}>
              {!(home && scroll) && (
                <>
                  <img
                    src={require(`assets/images/logo/sa.png`)}
                    className="pr-1"
                    style={{ maxWidth: size ? "200px" : "180px" }}
                    alt="logo"
                  />
                </>
              )}
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-400 text-decoration-none text-400"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a href={`/#${link.link}`} className="nav-link navbar-item-custom text-400">
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a href={`${link.link}`} className="nav-link navbar-item-custom text-400">
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle nav caret>
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem href={i.link} key={i.link} className="text-400">
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}

              <NavItem>
                <NavLink
                  to="/awards"
                  tag={Link}
                  className="btn btn-primary rounded-0 navbar-item-custom text-400 text-white"
                  style={{ opacity: "1" }}
                >
                  <span style={{ color: "#fff" }}>Award Nomination</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  { name: "HOME", link: "/", page: undefined, items: undefined, grid: 1 },
  {
    name: "ABOUT",
    link: "about",
    page: undefined,
    items: [
      { name: "FINNOVEX GLOBAL SERIES", link: "https://finnovex.com" },
      { name: "EXIBEX", link: "http://exibex.com/" },
    ],
    grid: 1,
  },
  {
    name: "SPEAKERS",
    link: "speakers",
    page: "/",
    items: [
      { name: "2024 SPEAKERS", link: "/speakers" },
      { name: "PAST SPEAKERS", link: "/past-speakers" },
    ],

    grid: 1,
  },

  { name: "PARTNERS", link: "partners", page: "/", items: undefined, grid: 1 },
  {
    name: "DELEGATES",
    link: "partners",
    page: "/",
    items: [
      { name: "WHO ATTENDS", link: "/delegates/2" },
      { name: "WHY ATTEND", link: "/delegates/3" },
      { name: "DOWNLOAD DELEGATE LIST", link: "/#register" },
    ],
    grid: 1,
  },
];
